import httpClient from '../../../../service/httpClient';

const state = () => {
    return {
        englishLocale: {
            header: "Explore universities, colleges and career schools.",
            description: "First select a category, then select a school that falls within that category.",
            buttonGroupLabel: "Select a Category:",
            buttons: {
                fourYearUniversity: {
                    text: "4-Year University",
                    ariaLabel: "Four year university"
                },
                twoYearCollege: {
                    text: "2-Year College",
                    ariaLabel: "Two year college"
                },
                careerSchool: {
                    text: "Career School",
                    ariaLabel: "Career school"
                },
                statewideSummary: {
                    text: "Statewide Summary",
                    ariaLabel: "Statewide Summary"
                }
            },
            dropdownLabels: {
                default: "Select a School:",
                fourYearUniversity: "Select a 4-Year School:",
                twoYearCollege: "Select a 2-Year School:",
                careerSchool: "Select a Career School:",
                statewideSummary: "Select a State Summary:"
            },
            viewDashboardButton: "View Dashboard",
        },
        spanishLocale: {
            header: "Explorar las universidades, colegios y escuelas profesionales.",
            description: "Primero seleccione una categor\u00EDa, despu\u00E9s seleccione una escuela de acuerdo a la categor\u00EDa.",
            buttonGroupLabel: "Seleccione una categor\u00EDa:",
            buttons: {
                fourYearUniversity: {
                    text: "Universidad de 4 a\u00F1os",
                    ariaLabel: "Universidad de cuatro a\u00F1os"
                },
                twoYearCollege: {
                    text: "Colegio de 2 a\u00F1os",
                    ariaLabel: "Colegio de dos a\u00F1os"
                },
                careerSchool: {
                    text: "Escuela profesional",
                    ariaLabel: "Escuela profesional"
                },
                statewideSummary: {
                    text: "Resumen del estado",
                    ariaLabel: "Resumen del estado"
                }
            },
            dropdownLabels: {
                default: "Seleccione una escuela:",
                fourYearUniversity: "Seleccione una escuela de 4 a\u00F1os:",
                twoYearCollege: "Seleccione una escuela de 2 a\u00F1os:",
                careerSchool: "Seleccione una escuela profesional:",
                statewideSummary: "Seleccione un resumen del estado:"
            },
            viewDashboardButton: "Ver Tablero",
        },
        schools: [],
        schoolNames: {
            fourYear: [],
            twoYear: [],
            career: [],
            statewide: []            
        }
    }
};

const getters = {
    selectSchoolPageLocale: (state, rootGetters) => {
        return rootGetters.useSpanishLocale
            ? state.spanishLocale
            : state.englishLocale;
    },
    schools: (state) => {
        return state.schools
    },
    schoolNamesFourYearLocale: (state, rootGetters) => {
        return rootGetters.useSpanishLocale
            ? state.schoolNames.fourYear.map(s => ({ label: s.nameSpanish, value: s.id }))
            : state.schoolNames.fourYear.map(s => ({ label: s.nameEnglish, value: s.id }));
    },
    schoolNamesTwoYearLocale: (state, rootGetters) => {
        return rootGetters.useSpanishLocale
            ? state.schoolNames.twoYear.map(s => ({ label: s.nameSpanish, value: s.id }))
            : state.schoolNames.twoYear.map(s => ({ label: s.nameEnglish, value: s.id }));
    },
    schoolNamesStatewideSummaryLocale: (state, rootGetters) => {
        return rootGetters.useSpanishLocale
            ? state.schoolNames.statewide.map(s => ({ label: s.nameSpanish, value: s.id }))
            : state.schoolNames.statewide.map(s => ({ label: s.nameEnglish, value: s.id }));
    },
    schoolNamesCareerLocale: (state, rootGetters) => {
        return rootGetters.useSpanishLocale
            ? state.schoolNames.career.map(s => ({ label: s.nameSpanish, value: s.id }))
            : state.schoolNames.career.map(s => ({ label: s.nameEnglish, value: s.id }));
    },
    selectedSchoolLocale: (state, rootGetters) => (schoolId) => {
        return rootGetters.useSpanishLocale
            ? state.schools.find(s => s.schoolProfileId === schoolId)?.instituteLegalNameSpanish
            : state.schools.find(s => s.schoolProfileId === schoolId)?.instituteLegalName;
    }
};

const actions = {
    async getSchoolsAsync({ commit }) {
        const response = await httpClient.getAsync('/SchoolProfiles');
        commit("SET_SCHOOLS", response.data);
        commit("SET_TWO_YEAR_COLLEGE_NAMES", response.data);
        commit("SET_FOUR_YEAR_UNIVERSITY_NAMES", response.data);
        commit("SET_STATEWIDE_SUMMARY_NAMES", response.data);
        commit("SET_CAREER_SCHOOL_NAMES", response.data);
    },
};

const mutations = {
    SET_SCHOOLS: (state, schools) => state.schools = schools,
    SET_TWO_YEAR_COLLEGE_NAMES: (state, schools) => {
        const twoYearColleges = schools.filter(s => s.instituteTierId === 1);

        for (const school of twoYearColleges) {
            state.schoolNames.twoYear.push({
                nameEnglish: school.instituteLegalName,
                nameSpanish: school.instituteLegalNameSpanish,
                id: school.schoolProfileId
            });
        }
    },
    SET_FOUR_YEAR_UNIVERSITY_NAMES: (state, schools) => {
        const fourYearUniversities = schools.filter(s => s.instituteTierId === 2);

        for (const school of fourYearUniversities) {
            state.schoolNames.fourYear.push({
                nameEnglish: school.instituteLegalName,
                nameSpanish: school.instituteLegalNameSpanish,
                id: school.schoolProfileId
            });
        }
    },
    SET_STATEWIDE_SUMMARY_NAMES: (state, schools) => {
        const statewideSummaries = schools.filter(s => s.instituteTierId === 3);

        for (const school of statewideSummaries) {
            state.schoolNames.statewide.push({
                nameEnglish: school.instituteLegalName,
                nameSpanish: school.instituteLegalNameSpanish,
                id: school.schoolProfileId
            });
        }
    },
    SET_CAREER_SCHOOL_NAMES: (state, schools) => {
        const careerSchools = schools.filter(s => s.instituteTierId === 4);

        for (const school of careerSchools) {
            state.schoolNames.career.push({
                nameEnglish: school.instituteLegalName,
                nameSpanish: school.instituteLegalNameSpanish,
                id: school.schoolProfileId
            });
        }
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
