import httpClient from '../../../../service/httpClient';

const state = () => {
    return {
        englishLocale: {
            header: "Frequently Asked Questions"
        },
        spanishLocale: {
            header: "Preguntas Frequentes"
        },
        faqs: []
    }
};

const getters = {
    faqPageLocale: (state, rootGetters) => {
        return rootGetters.useSpanishLocale
            ? state.spanishLocale
            : state.englishLocale;
    },
    faqs: (state, rootGetters) => {
        return rootGetters.useSpanishLocale
            ? state.faqs.map(({ questionSpanish, answerSpanish }) => ({ header: questionSpanish, content: answerSpanish }))
            : state.faqs.map(({ question, answer }) => ({ header: question, content: answer }));
    }
};

const actions = {
    async getFaqsAsync({ commit }) {
        const response = await httpClient.getAsync('/Faqs');
        commit("SET_FAQS", response.data);
    }
};

const mutations = {
    SET_FAQS: (state, response) => {
        response = response.filter(a => a.accessibilityTip === null);

        for (var i = 0; i < response.length; i++) {
            state.faqs.push(response[i]);
        }
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
