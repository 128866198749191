<template>
    <div class="chart-container" :key="componentKey">
        <span style="position: absolute; left: -10000px;">{{chartAriaLabel}}</span>
        <Chart :legend="legend"
               :series="dataset.chartData"
               :seriesDefaults="seriesDefaults"
               :seriesColors="seriesColors"
               :category-axis="dataset.categories"
               :value-axis="dataset.axis"
               :tooltip="tooltip"
               :theme="'bootstrap'"
               aria-hidden="true">
        </Chart>
    </div>
</template>

<script>
    import { Chart } from '@progress/kendo-charts-vue-wrapper';
    import '@progress/kendo-ui/js/kendo.dataviz.chart';

    export default {
        name: 'BaseChart',
        components: {
            Chart
        },
        props: {
            ariaLabel: { type: String, required: true },
            dataset: { type: Object, required: true }
        },
        computed: {
            chartAriaLabel() {
                return this.$store.getters.useSpanishLocale
                    ? `Gr\u00E1fico para ${this.ariaLabel}`
                    : `Chart for ${this.ariaLabel}`;
            }
        },
        data() {
            return {
                componentKey: 0,
                windowWidth: innerWidth,
                legend: {
                    position: 'top',
                    align: 'start',
                    orientation: 'horizontal',                    
                    margin: {
                        top: -10,
                        bottom: 14,
                        left: -4
                    },    
                    labels: {
                        font: '14px Source Sans Pro Regular, Source Sans Pro, sans-serif',
                        color: '#141717',
                        padding: {
                            bottom: 4                           
                        },
                    },
                    spacing: 30
                },
                tooltip: {
                    visible: true,
                    format: '{0:C0}',
                    template: "#: series.name #: #: kendo.format('{0:C0}',value) #",
                    background: 'white',
                    color: 'black',
                    border: {
                        width: 3
                    }
                },
                seriesDefaults: {
                    spacing: 0.1
                },
                seriesColors: [
                    '#4C6CE3',
                    '#388232',
                    '#E97021',
                    '#9151DC',
                    '#0DA2B0',
                    '#DF2561',
                    '#78366E',
                    '#020A6A'
                ]
            }
        },
        mounted() {
            this.$nextTick(() => {
                // Add resize event listener for dynamic chart rendering
                window.addEventListener('resize', () => this.windowWidth = window.innerWidth);
            });
        },
        beforeDestroy() {
            window.removeEventListener('resize', () => this.windowWidth = window.innerWidth);
        },
        watch: {
            windowWidth() {
                this.forceRerender();
            }
        },
        methods: {
            forceRerender() {
                this.componentKey += 1;
            }
        }
    }
</script>

<style>
    .chart-container {
        padding: 16px;
    }
</style>