const state = () => {
    return {
        englishLocale: {
            noOptions: "Sorry, there are no results.",
            historicalDataLabel: "To see historical data like median wages over 10 years, select a date at least 10 years ago."
        },
        spanishLocale: {
            noOptions: "Disculpe, no hay resultados.",
            historicalDataLabel: "Para ver datos hist\u00F3ricos como los salarios medios durante 10 a\u00F1os, seleccione una fecha de al menos 10 a\u00F1os atr\u00E1s."
        }
    }
}

const getters = {
    dropdownLocale: (state, rootGetters) => {
        return rootGetters.useSpanishLocale
            ? state.spanishLocale
            : state.englishLocale;
    },
};

const actions = {};

const mutations = {};

export default {
    state,
    getters,
    actions,
    mutations,
};
