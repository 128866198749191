<template>
    <div>
        <div class="tc-widget">
            <!-- Header -->
            <div class="tc-widget-header-container">
                <slot name="widget-header"></slot>
            </div>

            <!-- Content -->
            <div>
                <slot name="widget-content"></slot>                
            </div>

            <!-- Footer -->
            <div>
                <slot name="widget-footer"></slot>
            </div>
        </div>

        <!-- Additional Info -->
        <div>
            <slot name="widget-additional-info"></slot>
        </div>
    </div>    
</template>

<script>
    export default {
        name: 'WidgetTemplate'
    }
</script>

<style>
    .tc-widget {
        border: 1px solid #d7d7d7;
        border-radius: 8px;
    }
    .tc-widget-header-container {
        border-bottom: 1px solid #d7d7d7;
        background: #f7f7f8;
        border-radius: 8px 8px 0px 0px;
        padding: 16px 12px;
    }
</style>