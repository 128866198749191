import jsPDF from 'jspdf';
import 'jspdf-autotable';
import html2canvas from 'html2canvas';
import store from '../store/index';

export default {
    methods: {
        generateDashboardPdf(dashboardElement, widgetTables, fileName) {            
            window.scrollTo(0, 0);

            // Mobile/Tablets
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                const windowReference = window.open('about:blank', '_blank');
                const mobileUsersMessage = store.getters.useSpanishLocale
                    ? '<p>Para usuarios m\u00F3viles. Si esta p\u00E1gina est\u00E1 en blanco, revise sus documentos o descargue la carpeta para los documentos PDF.</p>'
                    : '<p>For mobile users. If this page is blank, check your documents or download folder for the PDF documents.</p>';

                windowReference.window.document.write(mobileUsersMessage);

                html2canvas(dashboardElement, {
                    width: dashboardElement.clientWidth + 30,
                    onclone: (element) => {
                        // Explicitly assign width and height to svg elements so
                        // they can be properly displayed in browser PDF viewer
                        const svgElements = element.body.getElementsByTagName('svg');
                        Array.from(svgElements).forEach((svgElement) => {
                            const bBox = svgElement.getBBox();
                            svgElement.setAttribute('width', bBox.width);
                            svgElement.setAttribute('height', bBox.height);
                        });
                    }
                }).then((canvas) => {
                    const pdf = new jsPDF('p', 'px', [canvas.height, canvas.width]);
                    const img = canvas.toDataURL('image/jpeg');
                    pdf.addImage(img, 'JPEG', 15, 15);

                    pdf.addPage('a3', 'l');

                    // Get table data for PDF tables
                    const tableColumns = [];
                    const tableRows = [];
                    for (var i = 0; i < widgetTables.length; i++) {
                        // Get table columns
                        const cols = [];
                        const titles = widgetTables[i].columns.map(c => c.title);
                        cols.push(titles);

                        tableColumns.push(cols);

                        // Get table data
                        const rows = [];
                        const tableData = Object.values(widgetTables[i].tableData);
                        for (var j = 0; j < tableData.length; j++) {
                            const row = Object.values(tableData[j]);
                            if (typeof row[0] === 'string') {
                                let dataAnchorTagCell = row[0].match(/>(.*)</s); // See if the first data cell has anchor tags
                                if (dataAnchorTagCell) row[0] = dataAnchorTagCell[0].replace('>', '').replace('<', ''); // Extract the data cell value
                            }

                            rows.push(row);
                        }

                        tableRows.push(rows);
                    }

                    // Create PDF tables
                    for (var l = 0; l < widgetTables.length; l++) {
                        const columns = tableColumns[l]; // [['col 1', 'col 2', ...]]
                        const rows = tableRows[l]; // [['data 1', 'data 2'], ['data 1', 'data 2'], ...]

                        pdf.autoTable({
                            theme: 'grid',
                            head: columns,
                            body: rows,
                            didParseCell: (table) => {
                                if (table.section === 'head') {
                                    table.cell.styles.fillColor = '#f7f7f8';
                                    table.cell.styles.textColor = '#333333';
                                }
                            }
                        });
                    }

                    windowReference.location = pdf.output('bloburl');
                }).catch(() => {
                    const errorMessage = store.getters.useSpanishLocale ? 'Lo sentimos, se produjo un error al descargar este panel en PDF. Int\u00E9ntalo de nuevo.' : 'Sorry, something went wrong downloading this dashboard to PDF. Please try again.';
                    alert(errorMessage);
                });
            }
            else {
                html2canvas(dashboardElement, {
                    width: dashboardElement.clientWidth + 30,
                    onclone: (element) => {
                        // Explicitly assign width and height to svg elements so
                        // they can be properly displayed in browser PDF viewer
                        const svgElements = element.body.getElementsByTagName('svg');
                        Array.from(svgElements).forEach((svgElement) => {
                            const bBox = svgElement.getBBox();
                            svgElement.setAttribute('width', bBox.width);
                            svgElement.setAttribute('height', bBox.height);
                        });
                    }
                }).then((canvas) => {
                    const pdf = new jsPDF('p', 'px', [canvas.height, canvas.width]);
                    const img = canvas.toDataURL('image/jpeg');
                    pdf.addImage(img, 'JPEG', 15, 15);

                    pdf.addPage('a3', 'l');

                    // Get table data for PDF tables
                    const tableColumns = [];
                    const tableRows = [];
                    for (var i = 0; i < widgetTables.length; i++) {
                        // Get table columns
                        const cols = [];
                        const titles = widgetTables[i].columns.map(c => c.title);
                        cols.push(titles);

                        tableColumns.push(cols);

                        // Get table data
                        const rows = [];
                        const tableData = Object.values(widgetTables[i].tableData);
                        for (var j = 0; j < tableData.length; j++) {
                            const row = Object.values(tableData[j]);
                            if (typeof row[0] === 'string') {
                                let dataAnchorTagCell = row[0].match(/>(.*)</s); // See if the first data cell has anchor tags
                                if (dataAnchorTagCell) row[0] = dataAnchorTagCell[0].replace('>', '').replace('<', ''); // Extract the data cell value
                            }

                            rows.push(row);
                        }

                        tableRows.push(rows);
                    }

                    // Create PDF tables
                    for (var l = 0; l < widgetTables.length; l++) {
                        const columns = tableColumns[l]; // [['col 1', 'col 2', ...]]
                        const rows = tableRows[l]; // [['data 1', 'data 2'], ['data 1', 'data 2'], ...]

                        pdf.autoTable({
                            theme: 'grid',
                            head: columns,
                            body: rows,
                            didParseCell: (table) => {
                                if (table.section === 'head') {
                                    table.cell.styles.fillColor = '#f7f7f8';
                                    table.cell.styles.textColor = '#333333';
                                }
                            }
                        });
                    }

                    pdf.save(store.getters.useSpanishLocale ? fileName + ' - Tablero' : fileName + ' - Dashboard' + '.pdf');
                }).catch(() => {
                    const errorMessage = store.getters.useSpanishLocale ? 'Lo sentimos, se produjo un error al descargar este panel en PDF. Int\u00E9ntalo de nuevo.' : 'Sorry, something went wrong downloading this dashboard to PDF. Please try again.';
                    alert(errorMessage);
                });
            }            
        },
        generateWidgetPdf(widgetElement, fileName) {                                               
            window.scrollTo(0, 0);

            // Mobile/Tablets
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                const windowReference = window.open('about:blank', '_blank');
                const mobileUsersMessage = store.getters.useSpanishLocale
                    ? '<p>Para usuarios m\u00F3viles. Si esta p\u00E1gina est\u00E1 en blanco, revise sus documentos o descargue la carpeta para los documentos PDF.</p>'
                    : '<p>For mobile users. If this page is blank, check your documents or download folder for the PDF documents.</p>';

                windowReference.window.document.write(mobileUsersMessage);

                html2canvas(widgetElement, {
                    width: widgetElement.clientWidth + 30,
                    onclone: (element) => {
                        // Explicitly assign width and height to svg element so
                        // it can be properly displayed in browser PDF viewer
                        const svgElements = element.body.getElementsByTagName('svg');
                        Array.from(svgElements).forEach((svgElement) => {
                            const bBox = svgElement.getBBox();
                            svgElement.setAttribute('width', bBox.width);
                            svgElement.setAttribute('height', bBox.height);
                        });
                    }
                }).then((canvas) => {
                    const pdf = new jsPDF('p', 'px', [canvas.height, canvas.width]);
                    const img = canvas.toDataURL('image/jpeg');
                    pdf.addImage(img, 'JPEG', 15, 15);

                    windowReference.location = pdf.output('bloburl');
                }).catch(() => {
                    const errorMessage = store.getters.useSpanishLocale ? 'Lo sentimos, se produjo un error al descargar este widget en PDF. Int\u00E9ntalo de nuevo.' : 'Sorry, something went wrong downloading this widget to PDF. Please try again.';
                    alert(errorMessage);
                });
            }
            else {
                html2canvas(widgetElement, {
                    width: widgetElement.clientWidth + 30,
                    onclone: (element) => {
                        // Explicitly assign width and height to svg element so
                        // it can be properly displayed in browser PDF viewer
                        const svgElements = element.body.getElementsByTagName('svg');
                        Array.from(svgElements).forEach((svgElement) => {
                            const bBox = svgElement.getBBox();
                            svgElement.setAttribute('width', bBox.width);
                            svgElement.setAttribute('height', bBox.height);
                        });
                    }
                }).then((canvas) => {
                    const pdf = new jsPDF('p', 'px', [canvas.height, canvas.width]);
                    const img = canvas.toDataURL('image/jpeg');
                    pdf.addImage(img, 'JPEG', 15, 15);

                    pdf.save(fileName + '.pdf');
                }).catch(() => {
                    const errorMessage = store.getters.useSpanishLocale ? 'Lo sentimos, se produjo un error al descargar este widget en PDF. Int\u00E9ntalo de nuevo.' : 'Sorry, something went wrong downloading this widget to PDF. Please try again.';
                    alert(errorMessage);
                });
            }
        }
    }
}