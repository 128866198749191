<template>
    <SelectPageTemplate ref="selectPage"
                        :bannerHeaderText="selectMajorPage.header"
                        :bannerDescriptionText="selectMajorPage.description"
                        :buttonText="selectMajorPage.viewDashboardButton"
                        :dashboardPage="`/major-dashboard/${selectedMajorId}`"                        
                        :isButtonDisabled="isButtonDisabled"
                        :isLoading="isLoading"
                        :loadingText="loadingText">

        <div slot="select-page-content">
            <label for="select-major-dropdown"
                   class="select-major-dropdown-label"
                   v-html="selectMajorPage.dropdownLabel" />

            <BaseDropDown id="select-major-dropdown"
                          class="select-major-dropdown"
                          :ariaLabel="selectMajorPage.dropdownLabel"
                          :options="dropdownValues"
                          :noOptionsText="dropdownNoOptions"
                          v-model="selectedMajor" 
                          @input="handleDropdownChange()" />
        </div>

    </SelectPageTemplate>
</template>

<script>
    import SelectPageTemplate from '@/components/PageTemplates/SelectPageTemplate.vue';
    import BaseDropDown from '@/components/BaseDropDown.vue';

    export default {
        name: 'SelectMajorPage',
        components: {
            SelectPageTemplate,
            BaseDropDown
        },
        computed: {
            selectMajorPage() {
                return this.$store.getters.selectMajorPageLocale;
            },
            dropdownValues() {
                return this.$store.getters.majorNamesLocale;
            },
            dropdownNoOptions() {
                return this.$store.getters.dropdownLocale.noOptions;
            },
            selectedMajorId() {
                return this.selectedMajor
                    ? this.selectedMajor.value
                    : 0;
            },
            isButtonDisabled() {
                return !this.selectedMajor
                    ? true
                    : false;
            },
            loadingText() {
                return this.$store.getters.loadingIndicatorLocale.loadingMajors;
            }
        },
        data() {
            return {
                isLoading: false,
                selectedMajor: null,
                majors: []
            }
        },
        async created() {
            try {
                const majors = this.$store.getters.majors;
                this.$store.commit('SET_PREVIOUS_SELECT_PAGE', 'select-major');

                if (majors.length > 0) {
                    this.majors = majors;
                }
                else {
                    this.isLoading = true;
                    await this.$store.dispatch('getMajorsAsync');
                    this.isLoading = false;
                    this.majors = this.$store.getters.majors;
                }
            } catch (error) {
                this.isLoading = false;
                this.$router.push('/error');
            }            
        },
        watch: {
            selectMajorPage() {
                if (this.selectedMajor) {
                    this.selectedMajor = {
                        label: this.$store.getters.selectedMajorLocale(this.selectedMajor.value),
                        value: this.selectedMajor.value
                    }
                }                
            }
        },
        methods: {
            handleDropdownChange() {
                this.$nextTick(() => this.$refs.selectPage.$refs.viewDashboardButton.$el.focus());
            }
        }
    }
</script>

<style scoped>
    .select-major-dropdown-label {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 16px;
        color: rgba(51, 51, 51, 1);
    }
    .select-major-dropdown {
        margin-top: 8px;
        width: 100%;
    }
</style>