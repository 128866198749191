<template>
    <div>
        <!-- Header -->
        <div class="table-filter-header-container">
            <div class="filter-search-container">
                <BaseTextInput class="filter-search-text-input"
                               :placeholder="filterSearchInputPlaceholder"
                               @input="handleInputChange($event)" />
            </div>

            <div class="filter-dropdown-container">
                <label for="filter-dropdown"
                       class="filter-dropdown-label"
                       v-html="filterDropdownLabel" />
                <BaseDropDown id="filter-dropdown"
                              class="filter-dropdown"
                              :ariaLabel="filterDropdownAriaLabel"
                              :options="filterDropdownValues"
                              :noOptionsText="dropdownNoOptions"
                              :clearable=false
                              v-model="selectedDropdownFilter"
                              @input="handleDropdownChange()" />
            </div>
        </div>

        <!-- Table -->
        <div v-if="hasResults">
            <BaseTable :tableId="tableId"
                       :hasHeaders="hasHeaders"
                       :isFilterable=true
                       :isPageable=true
                       :dataset="dataset" />
        </div>
        <div v-else>
            <NoResults />
        </div>
    </div>
</template>

<script>
    import BaseTextInput from '@/components/BaseTextInput.vue';
    import BaseDropDown from '@/components/BaseDropDown.vue';
    import BaseTable from '@/components/Tables/BaseTable.vue';
    import NoResults from '@/components/NoResults.vue';

    export default {
        name: 'FilterableTable',
        components: {
            BaseTextInput,
            BaseDropDown,
            BaseTable,
            NoResults
        },
        props: {
            tableId: { type: String, required: true },
            hasHeaders: { type: Boolean, required: false, default: true },
            filterSearchInputPlaceholder: { type: String, required: false },
            filterDropdownLabel: { type: String, required: false },
            filterDropdownAriaLabel: { type: String, required: true },
            filterDropdownValues: { type: Array, required: false },
            filterDropdownSelectedValue: { type: Object, required: false },
            dataset: { type: Object, required: true }
        },
        computed: {
            hasResults() {                
                return this.dataset?.tableData.length > 0 && this.dataset?.tableData[0] !== 'No results';
            },
            dropdownNoOptions() {
                return this.$store.getters.dropdownLocale.noOptions;
            }
        },
        data() {
            return {
                selectedDropdownFilter: {
                    label: null,
                    value: null
                }
            }
        },
        mounted() {            
            this.selectedDropdownFilter = this.filterDropdownValues ? this.filterDropdownValues[0] : null;
        },
        watch: {
            filterDropdownLabel() {
                this.selectedDropdownFilter = {
                    label: this.filterDropdownSelectedValue.label,
                    value: this.filterDropdownSelectedValue.value
                }
            },
            filterDropdownSelectedValue() {
                this.selectedDropdownFilter = {
                    label: this.filterDropdownSelectedValue.label,
                    value: this.filterDropdownSelectedValue.value
                }
            }
        },
        methods: {
            handleInputChange(event) {
                this.$emit('inputChange', event);
            },
            handleDropdownChange() {
                this.$emit('filterChange', this.selectedDropdownFilter);
            }
        }
    }
</script>

<style scoped>
    /* Filter Header */
    .table-filter-header-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #f7f7f8;
        color: #333333;
        font-family: 'Source Sans Pro', sans-serif;
        text-align: left;
        line-height: 20px;
        border-bottom: 1px solid rgba(215, 215, 215, 1);
        padding: 12px;
    }

    /* Filter Search */
    .filter-search-container {
        display: flex;
        align-items: center;
        width: 100%;
    }
    .filter-search-text-input {
        background: url('../../assets/images/icons/search-icon.svg') no-repeat;
        background-position: left 5% top 50%;
        background-size: 14px;
        background-color: #FFFFFF;
        margin-right: 10px;
    }

    /* Filter Dropdown */
    .filter-dropdown-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
    }
    .filter-dropdown-label {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 16px;
        color: rgba(51, 51, 51, 1);
        margin-right: 10px;
    }
    .filter-dropdown {
        width: 220px;
    }

    /* Media Queries */

    /* Tablets and Mobile */
    @media (max-width: 780px) {
        .table-filter-header-container {
            flex-direction: column-reverse;
            align-items: flex-start;            
            padding: 0px;
        }
        .filter-search-text-input {
            margin: 12px;
        }
        .filter-dropdown-container {
            border-bottom: 1px solid rgba(215, 215, 215, 1);
            justify-content: flex-start;
        }
        .filter-dropdown-label,
        .filter-dropdown {
            margin: 12px;
        }
    }

    @media (max-width: 400px) {
        .filter-search-text-input {
            width: 150px;
        }
    }
</style>