<template>
    <div v-if="!isIE" id="app" aria-live="polite">
        <TheHeader />
        <main id="wrapper">
            <router-view></router-view>
            <TheAutoScrollButton v-show="showAutoScrollButton" />
        </main>
        <TheFooter />
    </div>
    
    <BrowserNotSupported v-else id="app"/>
</template>

<script>
    import TheHeader from '@/components/TheHeader.vue';
    import TheAutoScrollButton from '@/components/TheAutoScrollButton.vue';
    import TheFooter from '@/components/TheFooter.vue';
    import BrowserNotSupported from '@/components/BrowserNotSupported.vue';

    export default {
        name: 'app',
        components: {
            TheHeader,
            TheAutoScrollButton,
            TheFooter,
            BrowserNotSupported
        },
        computed: {
            isIE() {
                return /MSIE \d|Trident.*rv:/.test(navigator.userAgent);
            }
        },
        data() {
            return {
                showAutoScrollButton: false
            }
        },
        async created() {
            try {
                if (!this.isIE) {
                    await this.$store.dispatch('getConfigAsync');
                }
            } catch (error) {
                this.$router.push('/error');
            }            
        },
        mounted() {
            this.$nextTick(() => {                
                window.addEventListener('scroll', () => {
                    window.scrollY > 100
                        ? this.showAutoScrollButton = true
                        : this.showAutoScrollButton = false;
                });
            });
        },
        beforeDestroy() {
            window.removeEventListener('scroll', () => this.windowWidth = window.innerWidth);
        },
    };
</script>

<style>
    @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro');

    * {
        margin: 0;
        padding: 0;
    }
    body, html {
        width: 100%;
        height: 100%;        
    }
    #app {

    }
    #wrapper {
        min-height: 60vh;
    }
    .tc-container {
        max-width: 1170px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 15px;
        padding-right: 15px;
    }
    .router-link {
        text-decoration: none;
    }
</style>