const state = () => {
    return {
        englishLocale: {
            header: "Something went wrong! We're looking into it.",
            description: `Either the page you're trying to find does not exist or an error has occurred on our end. 
                    If you can't access the site using one of the links below, please try again later:`,
            navButtons: {
                homePage: {
                    text: "Texas CREWS home page",
                    ariaLabel: "Texas CREWS home page",
                },
                searchSchools: {
                    text: "Explore colleges, universities and career schools",
                    ariaLabel: "Explore colleges, universities and career schools",
                },
                searchMajors: {
                    text: "Explore majors and degree programs",
                    ariaLabel: "Explore majors and degree programs",
                },
                searchCareers: {
                    text: "Explore majors that match your career interests",
                    ariaLabel: "Explore majors that match your career interests",
                },
            },
            imageAltText: "Binoculars",
        },
        spanishLocale: {
            header: "&iexcl;Algo sali\u00F3 mal! Lo estamos investigando.",
            description: `La p&atilde;gina que est&atilde; buscando no existe o se ha producido un error por nuestra parte. 
                    Si no puede accesar al sitio usando uno de los enlaces de abajo, por favor int\u00E9ntelo m&atilde;s tarde.`,
            navButtons: {
                homePage: {
                    text: "Texas CREWS p&atilde;gina de Inicio",
                    ariaLabel: "Texas CREWS p&atilde;gina de Inicio",
                },
                searchSchools: {
                    text: "Explorar colegios, universidades y escuelas profesionales",
                    ariaLabel: "Explorar colegios, universidades y escuelas profesionales",
                },
                searchMajors: {
                    text: "Explorar estudios superiores y programas de grado",
                    ariaLabel: "Explorar estudios superiores y programas de grado",
                },
                searchCareers: {
                    text: "Explorar estudios superiores que correspondan a sus intereses profesionales",
                    ariaLabel: "Explorar estudios superiores que correspondan a sus intereses profesionales",
                },
            },
            imageAltText: "Binoculares",
        }
    }
}

const getters = {
    errorPageLocale: (state, rootGetters) => {
        return rootGetters.useSpanishLocale
            ? state.spanishLocale
            : state.englishLocale;
    },
};

const actions = {};

const mutations = {};

export default {
    state,
    getters,
    actions,
    mutations,
};
