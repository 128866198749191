<template>
    <div class="tc-container">
        <BaseButton class="tc-auto-scroll-button"
                    :aria-label="autoScrollButton.ariaLabel"
                    :text="autoScrollButton.text"
                    @execute="handleScrollToTop()" />
    </div>
</template>

<script>
    import BaseButton from '@/components/BaseButton.vue';

    export default {
        name: 'TheAutoScrollButton',
        components: {
            BaseButton
        },
        computed: {
            autoScrollButton() {
                return this.$store.getters.autoScrollButtonLocale;
            }
        },
        methods: {
            handleScrollToTop() {
                document.documentElement.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }
</script>

<style scoped>
    .tc-auto-scroll-button {
        display: block;
        float: right;
        border-radius: 8px;
        border: 1px solid #dcdce2;
        background-color: #FFFFFF;
        font-family: 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif;
        font-size: 14px;
        color: #4c6ce3;
        background: url('../assets/images/icons/top-icon.svg') no-repeat;
        background-position: left 10% top 50%;
        background-size: 14px;
        padding: 6px 10px 6px 28px;
    }
    .tc-auto-scroll-button:hover {
        box-shadow: 0 0 0 2pt #4c6ce3;
        border-radius: 8px;
        cursor: pointer;
    }
</style>