<template>
    <FooterPageTemplate :header="faqPage.header"
                        :contentElements="faqs"
                        :isLoading="isLoading"
                        :loadingText="loadingText" />
</template>

<script>
    import FooterPageTemplate from '@/components/PageTemplates/FooterPageTemplate.vue';

    export default {
        name: 'FaqPage',
        components: {
            FooterPageTemplate
        },
        computed: {
            faqPage() {
                return this.$store.getters.faqPageLocale;
            },
            loadingText() {
                return this.$store.getters.loadingIndicatorLocale.loading;
            }
        },
        data() {
            return {
                isLoading: false,
                faqs: []
            }
        },
        async created() {
            try {
                const faqs = this.$store.getters.faqs;

                if (faqs.length > 0) {
                    this.faqs = faqs;
                }
                else {
                    this.isLoading = true;
                    await this.$store.dispatch('getFaqsAsync');
                    this.faqs = this.$store.getters.faqs;
                    this.isLoading = false;
                }
            } catch (error) {
                this.isLoading = false;
                this.$router.push('/error');
            }            
        },
        watch: {
            faqPage() {
                this.faqs = this.$store.getters.faqs;
            }
        }
    }
</script>