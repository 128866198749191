const state = () => {
    return {
        englishLocale: {
            downloadButtonAriaLabel: "Download",
            downloadToPdfTooltip: "Download to PDF",
            downloadToCsvTooltip: "Download to CSV",
            showChartAriaLabel: "Show Chart",
            showTableAriaLabel: "Show Table",
            showChartTooltip: "Show Chart",
            showTableTooltip: "Show Table"
        },
        spanishLocale: {
            downloadButtonAriaLabel: "Descargar",
            downloadToPdfTooltip: "Descargar a PDF",
            downloadToCsvTooltip: "Descargar a CSV",
            showChartAriaLabel: "Mostrar Gr\u00E1fico",
            showTableAriaLabel: "Mostrar Tabla",
            showChartTooltip: "Mostrar Gr\u00E1fico",
            showTableTooltip: "Mostrar Tabla"
        }
    }
};

const getters = {
    datasetWidgetLocale: (state, rootGetters) => {
        return rootGetters.useSpanishLocale
            ? state.spanishLocale
            : state.englishLocale;
    },
};

const actions = {};

const mutations = {};

export default {
    state,
    getters,
    actions,
    mutations,
};
