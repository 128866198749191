<template>
    <div>
        <div v-if="!isLoading">
            <!-- Banner -->
            <div class="tc-dashboard-banner" id="dashboard-banner">
                <div class="tc-container">
                    <div class="banner-container">
                        <slot name="dashboard-banner-dropdowns"></slot>                        

                        <div class="banner-update-report-container">
                            <BaseButton class="update-report-button"
                                        :aria-label="schoolDashboard.dashboardBanner.updateReportButton.ariaLabel"
                                        :text="schoolDashboard.dashboardBanner.updateReportButton.text"
                                        @execute="handleUpdateReportAsync()" />
                            <!-- Mobile Button -->
                            <BaseButton class="update-report-button-mobile"
                                        :aria-label="schoolDashboard.dashboardBanner.updateReportButton.ariaLabel"
                                        :imgSrc="updateReportButtonIcon"
                                        text=""
                                        @execute="handleUpdateReportAsync()" />
                        </div>
                    </div>
                </div>
            </div>
            <!-- Dashboard -->
            <div class="tc-container">
                <div class="tc-dashboard-container">
                    <!-- Header -->
                    <div class="dashboard-header-container">
                        <h1 class="dashboard-header" v-html="dashboardHeaderText" />

                        <div class="header-button-group">
                            <router-link v-if="isComparable"
                                         :class="hasComparableSchools ? 'header-button compare-back-button router-link' : 'header-button compare-button router-link'"
                                         :to="comparePage"
                                         v-html="compareButtonText" />
                            <BaseButton class="header-button download-button"
                                        :aria-label="schoolDashboard.dashboardHeader.buttons.downloadButton.ariaLabel"
                                        :text="schoolDashboard.dashboardHeader.buttons.downloadButton.text"
                                        @execute="handleDownload()" />
                        </div>
                    </div>
                    <!-- Content -->
                    <slot name="dashboard-content"></slot>
                </div>
            </div>
        </div>
        
        <LoadingIndicator v-if="isLoading" :text="loadingText.loadingDashboard" />
    </div>    
</template>

<script>
    import BaseButton from '@/components/BaseButton.vue';
    import LoadingIndicator from '@/components/LoadingIndicator.vue';

    export default {
        name: 'DashboardTemplate',
        components: {
            BaseButton,
            LoadingIndicator
        },
        props: {
            dashboardHeaderText: { type: String, required: true },
            comparePage: { type: String, required: false, default: '' },
            isComparable: { type: Boolean, required: false, default: false },
            isLoading: { type: Boolean, required: true }
        },
        computed: {
            schoolDashboard() {
                return this.$store.getters.schoolDashboardLocale;
            },
            updateReportButtonIcon() {
                return require('@/assets/images/icons/right-caret.svg');
            },
            compareButtonText() {
                return this.$store.getters.hasComparableSchools
                    ? this.schoolDashboard.dashboardHeader.buttons.compareBackButton.text
                    : this.schoolDashboard.dashboardHeader.buttons.compareButton.text;
            },
            hasComparableSchools() {
                return this.$store.getters.hasComparableSchools;
            },
            loadingText() {
                return this.$store.getters.loadingIndicatorLocale;
            }
        },
        mounted() {
            this.$nextTick(() => {
                window.addEventListener('scroll', this.handleStickyBanner);
            });
        },
        beforeDestroy() {
            window.removeEventListener('scroll', this.handleStickyBanner);
        },
        methods: {
            async handleUpdateReportAsync() {
                this.$emit('updateReport');
            },
            handleCompare() {
                this.$emit('compare');
            },
            handleDownload() {
                this.$emit('download');
            },
            handleStickyBanner() {
                const header = document.getElementById('dashboard-banner');
                const sticky = header.offsetTop;

                if (window.pageYOffset > sticky) {
                    header.classList.add('sticky');
                } else {
                    header.classList.remove('sticky');
                }
            }
        }
    }
</script>

<style scoped>
    /* Banner */
    .tc-dashboard-banner {
        width: 100%;
        background-color: #F7F7F8;
        text-align: left;
        box-shadow: 0px 4px 6px rgba(20, 20, 23, 0.15);
        position: relative;
        z-index: 100000;
    }
    .banner-container {
        display: flex;
        align-items: center;
        padding-top: 25px;
        padding-bottom: 16px;
    }
    .banner-update-report-container {
        display: flex;
    }
    .update-report-button {
        font-family: 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif;
        font-size: 16px;
        font-weight: 600;
        color: #FFFFFF;
        cursor: pointer;
        border-radius: 8px;
        border: none;
        background: url('../../assets/images/icons/right-caret.svg') no-repeat #4C6CE3;
        background-position: left 90% top 50%;
        background-size: 8px;
        margin-top: 25px;
        padding: 10px 28px 10px 16px;
        text-align: center;
    }
    .update-report-button-mobile {
        display: none;
    }
    .update-report-button:hover {
        background-color: #2D4DC4;
    }
    .sticky {
        position: fixed;
        top: 0;
        width: 100%;
    }
    /* Screen Reader Instructions */
    .screen-reader-instructions {
        position: absolute !important; 
        overflow: hidden;
        clip: rect(1px, 1px, 1px, 1px);
    }
    /* Dashboard */
    .tc-dashboard-container {
        margin-top: 40px;
        padding-bottom: 80px;
    }
    /* Dashboard - Header */
    .dashboard-header-container {
        display: flex;
        align-items: center;
        width: 100%;
    }
    .dashboard-header {
        font-family: 'Source Sans Pro SemiBold', 'Source Sans Pro', sans-serif;
        font-weight: 600;
        font-size: 32px;
        color: #333333;
        text-align: left;
        margin-right: 50px;
    }
    .header-button-group {
        display: flex;
    }
    .header-button {
        font-family: 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif;
        font-size: 16px;
        background-color: #FFFFFF;
        border: 1px solid #4c6ce3;
        border-radius: 8px;
        color: #4c6ce3;
        padding: 6px 16px 6px 32px;
    }
    .header-button:hover {
        box-shadow: 0 0 0 1pt #4c6ce3;
        cursor: pointer;
    }
    .compare-button {
        background: url('../../assets/images/icons/add-icon.svg') no-repeat;
        background-position: left 10% top 50%;
        background-size: 14px;
        margin-right: 12px;
    }
    .compare-back-button {
        background: url('../../assets/images/icons/left-caret-white.svg') no-repeat;
        background-position: right 90% top 50%;
        background-size: 8px;
        background-color: #4c6ce3;
        color: #FFFFFF;
        margin-right: 12px;
        width: 120px;
    }
    .compare-back-button:hover {
        background-color: #2D4DC4;
    }
    .download-button {
        background: url('../../assets/images/icons/download-icon.svg') no-repeat;
        background-position: left 10% top 50%;
        background-size: 14px;
    }

    /* Media Queries */

    /* Tablets and Under */
    @media (max-width: 600px) {
        .update-report-button {
            display: none;
        }
        .update-report-button-mobile {
            display: block;
            font-family: 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif;
            color: #FFFFFF;
            cursor: pointer;
            background-color: #4C6CE3;
            border-radius: 8px;
            border: none;
            margin-top: 25px;
            padding: 12px;
        }
        .dashboard-header {
            font-size: 24px;
        }
    }

    @media (max-width: 600px) {
        .dashboard-header-container {
            justify-content: space-between;
        }
        .dashboard-header {
            margin-right: 20px;
        }
        .header-button-group {
            flex-direction: column;
        }
        .compare-button,
        .compare-back-button {
            margin-right: 0px;
            margin-bottom: 10px;
        }
    }
</style>