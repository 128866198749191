<template>
    <WidgetTemplate ref="datasetWidget">
        <!-- Header -->
        <div slot="widget-header">
            <div class="header-button-group-container">
                <h2 class="header" v-html="headerText" />

                <div class="button-group">
                    <BaseButton class="button download-button"
                                :aria-label="datasetWidget.downloadButtonAriaLabel"
                                :imgSrc="downloadIcon"
                                :title="showChartWidget ? datasetWidget.downloadToPdfTooltip : datasetWidget.downloadToCsvTooltip"
                                @execute="handleDownload()" />

                    <div v-if="isToggleable">
                        <BaseButton v-if="showChartWidget"
                                    class="button show-table-button"
                                    :aria-label="datasetWidget.showTableAriaLabel"
                                    :imgSrc="showTableIcon"
                                    :title="datasetWidget.showTableTooltip"
                                    @execute="handleShowTable()" />

                        <BaseButton v-if="showTableWidget"
                                    class="button show-chart-button"
                                    :aria-label="datasetWidget.showChartAriaLabel"
                                    :imgSrc="showChartIcon"
                                    :title="datasetWidget.showChartTooltip"
                                    @execute="handleShowChart()" />
                    </div>
                </div>
            </div>
        </div>

        <!-- Content -->
        <div slot="widget-content">
            <div v-if="isToggleable">
                <!-- Filter Dropdowns -->
                <div class="widget-filter-container">
                    <slot name="widget-filter-dropdowns"></slot>
                </div>

                <!-- Toggleable Dataset -->
                <BaseChart v-if="showChartWidget && hasChartResults"
                           :ariaLabel="headerText"
                           :dataset="dataset.chart" />

                <BaseTable v-else-if="showTableWidget && hasTableResults"
                           :tableId="tableId"
                           :hasHeaders="hasTableHeaders"
                           :dataset="dataset.table" />

                <NoResults v-else />
            </div>
            <div v-else-if="isFilterable">
                <!-- Filterable Table -->
                <FilterableTable :tableId="tableId"
                                 :filterSearchInputPlaceholder="filterSearchInputPlaceholder"
                                 :filterDropdownLabel="filterDropdownLabel"
                                 :filterDropdownAriaLabel="filterDropdownAriaLabel"
                                 :filterDropdownValues="filterDropdownValues"
                                 :filterDropdownSelectedValue="filterDropdownSelectedValue"
                                 :dataset="dataset.table"
                                 @filterChange="handleFilterChange($event)"
                                 @inputChange="handleInputChange($event)" />
            </div>
            <div v-else>
                <!-- Regular Table -->
                <BaseTable v-if="hasTableResults"
                           :tableId="tableId"
                           :hasHeaders="hasTableHeaders"
                           :dataset="dataset.table" />

                <NoResults v-else />
            </div>
        </div>

        <!-- Footer -->
        <div v-if="footerLinks" slot="widget-footer" class="footer">
            <ul class="links">
                <li v-for="link in footerLinks" :key="link.id">
                    <div>
                        <a :href="link.url"
                           target="_blank"
                           rel="noopener noreferrer">
                           {{ link.name }} <span class="off-left">{{externalLinkScreenReaderText}}</span>
                        </a>
                        <img class="external-link-icon"
                             src="../../assets/images/icons/external-link-blue.svg"
                             :alt="linkLocale.linkIconAltText"
                             aria-hidden="true" />
                    </div>
                </li>
            </ul>
        </div>

        <!-- Additional Info -->
        <div v-if="footerAsterisks" slot="widget-additional-info" class="footer-asterisk">
            <p v-for="asterisk in footerAsterisks" :key="asterisk" class="asterisk" v-html="asterisk" />
        </div>
    </WidgetTemplate>    
</template>

<script>
    import WidgetTemplate from '@/components/Widgets/WidgetTemplate.vue';
    import BaseButton from '@/components/BaseButton.vue';
    import BaseDropDown from '@/components/BaseDropDown.vue';
    import BaseChart from '@/components/Charts/BaseChart.vue';
    import BaseTable from '@/components/Tables/BaseTable.vue';
    import FilterableTable from '@/components/Tables/FilterableTable.vue';
    import NoResults from '@/components/NoResults.vue';
    import pdfGeneratorMixin from '@/mixins/pdfGeneratorMixin';

    export default {
        name: 'DatasetWidget',
        mixins: [pdfGeneratorMixin],
        components: {
            WidgetTemplate,
            BaseButton,
            BaseDropDown,
            BaseChart,
            BaseTable,
            FilterableTable,
            NoResults
        },
        props: {
            selectedDashboardItem: { type: String, required: false },
            headerText: { type: String, required: true },            
            footerLinks: { type: Array, required: false },
            footerAsterisks: { type: Array, required: false },
            isToggleable: { type: Boolean, required: false, default: false },
            isFilterable: { type: Boolean, required: false, default: false },
            filterSearchInputPlaceholder: { type: String, required: false },
            filterDropdownLabel: { type: String, required: false },
            filterDropdownAriaLabel: { type: String, required: false },
            filterDropdownValues: { type: Array, required: false },
            filterDropdownSelectedValue: { type: Object, required: false },
            tableId: { type: String, required: true },
            hasTableHeaders: { type: Boolean, required: false, default: true },
            dataset: { type: Object, required: true }
        },
        computed: {
            datasetWidget() {
                return this.$store.getters.datasetWidgetLocale;
            },
            linkLocale() {
                return this.$store.getters.linkWidgetLocale;
            },
            downloadIcon() {
                return require('../../assets/images/icons/download-icon.svg');
            },
            showChartIcon() {
                return require('../../assets/images/icons/chart-icon.svg');
            },
            showTableIcon() {
                return require('../../assets/images/icons/table-icon.svg');
            },
            showChartWidget() {
                return this.showChart;
            },
            showTableWidget() {
                return this.showTable;
            },
            hasChartResults() { 
                return (this.dataset?.chart?.chartData.length > 0 && this.dataset?.chart?.chartData[0].data.length > 0);                    
            },
            hasTableResults() {
                return this.dataset?.table?.tableData.length > 0;
            },
            externalLinkScreenReaderText() {
                return this.$store.getters.useSpanishLocale ? 'enlace externo' : 'external link';
            }
        },
        data() {
            return {
                showChart: false,
                showTable: false
            }
        },
        created() {
            if (this.isToggleable) {
                // Show Chart by default if the widget is toggleable
                this.showChart = true;
            }
            else {
                // Show Table by default if the widget is not toggleable
                this.showTable = true;
            }
        },        
        methods: {
            handleDownload() {
                try {
                    const fileName = this.selectedDashboardItem + ' - ' + this.headerText;

                    if (this.showTable && this.dataset?.table?.tableData && this.dataset.table.tableData[0] !== 'No results') {
                        const csvContent = this.createCsvContent();

                        const universalBOM = '\uFEFF';
                        const link = window.document.createElement('a');
                        link.setAttribute('href', 'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBOM + csvContent));
                        link.setAttribute('download', fileName + '.csv');
                        link.click();
                    }
                    else if (this.showChart) {
                        const chartElement = this.$refs.datasetWidget.$el;

                        this.generateWidgetPdf(chartElement, fileName);
                    }
                } catch (error) {                    
                    const errorMessage = this.$store.useSpanishLocale ? 'Lo sentimos, hubo un problema al descargar este widget. Int\u00E9ntalo de nuevo.' : 'Sorry, there was a problem downloading this widget. Please try again.';
                    alert(errorMessage);                    
                }                
            },
            createCsvContent() {
                const csv = [];

                const cols = this.dataset.table.columns;
                const rows = this.dataset.table.tableData;

                if (this.hasTableHeaders) {
                    const csvHeaders = [];

                    // Loop through each column in the table dataset
                    for (var i = 0; i < cols.length; i++) {
                        csvHeaders.push("\"" + cols[i].title + "\"");
                    }

                    csv.push(csvHeaders.join(','));
                }                

                // Loop through each data row in the table dataset
                for (var j = 0; j < rows.length; j++) {
                    const csvRow = [];
                    const row = Object.values(rows[j]);

                    // Loop through the number of columns in the current row
                    // and assign each data cell value
                    for (var k = 0; k < cols.length; k++) {
                        if (k === 0) {
                            if (typeof row[0] === 'string') {
                                let dataAnchorTagCell = row[0].match(/>(.*)</s); // See if the first data cell has anchor tags
                                if (dataAnchorTagCell) row[0] = dataAnchorTagCell[0].replace('>', '').replace('<', ''); // Extract the data cell value
                            }                            
                        }
                        
                        csvRow.push("\"" + row[k] + "\"");
                    }

                    csv.push(csvRow.join(','));
                }

                return csv.join('\n');
            },
            handleFilterChange(event) {
                this.$emit('filterChange', event);
            },
            handleInputChange(event) {
                this.$emit('inputChange', event);
            },
            handleShowTable() {
                this.showChart = false;
                this.showTable = true;
            },
            handleShowChart() {
                this.showChart = true;
                this.showTable = false;
            }
        }
    }
</script>

<style scoped>    
    .header-button-group-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .header {
        font-family: 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif;
        font-weight: 700;
        font-size: 18px;
        color: #0C154A;
        text-align: left;
        line-height: normal;
        max-width: 70%;
    }   
    .button-group {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        min-width: 80px;
    }
    .button {
        border-radius: 8px;
        border: 1px solid #dcdce2;
        color: #4c6ce3;
        padding: 8px;
        margin-left: 10px;
        background-color: #FFFFFF;
        width: 40px;
        height: 40px;
    }
    .button:hover {
        box-shadow: 0 0 0 1pt #4c6ce3;
        cursor: pointer;
    }
    .widget-filter-container {
        border-bottom: 1px solid #d7d7d7;
        background: #f7f7f8;
        display: flex;
        flex-wrap: wrap;
        padding: 0px 16px;
    }
    .footer {        
        border-bottom: 1px solid #d7d7d7;
        background: #f7f7f8;
        border-radius: 0px 0px 8px 8px;
        padding: 0px 12px 12px 12px;
    }
    .links {
        font-family: 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif;
        font-size: 14px;
        text-align: left;
        line-height: normal;
        list-style: none;
    }
    .links li {
        display: flex;
        align-items: center;
        padding-top: 12px;
    }
    .links a {
        text-decoration: none;
        color: #4c6ce3;
    }
    .links a:hover {
        text-decoration: underline;
    }
    .off-left {
        position: absolute;
        left: -1000px;
    }
    .external-link-icon {
        width: 14px;
        height: 14px;
    }
    .footer-asterisk {
        margin-top: 12px;
    }
    .asterisk {
        font-family: 'Source Sans Pro Italic', 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif;
        font-style: italic;
        font-size: 12px;
        color: #333333;
        line-height: 18px;
    }
</style>