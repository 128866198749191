<template>
    <p class="no-results" v-html="noResultsText" />
</template>

<script>
    export default {
        name: 'NoResults',
        computed: {
            noResultsText() {
                return this.$store.getters.widgetsNoResultsLocale.noResultsText;
            }
        }
    }
</script>

<style scoped>
    .no-results {
        font-family: 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif;
        font-size: 16px;
        color: #333333;
        text-align: left;
        line-height: normal;
        margin: 16px;
    }
</style>