<template>
    <FooterPageTemplate :header="glossaryPage.header"
                        :contentElements="glossaries"
                        :isLoading="isLoading"
                        :loadingText="loadingText">
        <p slot="footer-page-description" 
           class="glossary-description" 
           v-html="glossaryPage.description" />
    </FooterPageTemplate>
</template>

<script>
    import FooterPageTemplate from '@/components/PageTemplates/FooterPageTemplate.vue';

    export default {
        name: 'GlossaryPage',
        components: {
            FooterPageTemplate
        },
        computed: {
            glossaryPage() {
                return this.$store.getters.glossaryPageLocale;
            },
            loadingText() {
                return this.$store.getters.loadingIndicatorLocale.loading;
            }
        },
        data() {
            return {
                isLoading: false,
                glossaries: []
            }
        },
        async created() {
            try {
                const glossaries = this.$store.getters.glossaries;

                if (glossaries.length > 0) {
                    this.glossaries = glossaries;
                }
                else {
                    this.isLoading = true;
                    await this.$store.dispatch('getGlossariesAsync');
                    this.glossaries = this.$store.getters.glossaries;
                    this.isLoading = false;
                }
            } catch (error) {
                this.isLoading = false;
                this.$router.push('/error');
            }
        },
        watch: {
            glossaryPage() {
                this.glossaries = this.$store.getters.glossaries;
            }
        }
    }
</script>

<style scoped>
    .glossary-description {
        font-family: 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif;
        font-size: 16px;
        color: #141417;
        text-align: left;
        line-height: normal;
        margin-bottom: 40px;
    }
</style>