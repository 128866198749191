const state = () => {
    return {
        englishLocale: {
            loading: "Loading...",
            loadingSchools: "Loading Schools...",
            loadingMajors: "Loading Majors...",
            loadingCareers: "Loading Careers...",
            loadingDashboard: "Loading Dashboard..."
        },
        spanishLocale: {
            loading: "Descargando...",
            loadingSchools: "Descargando Escuelas...",
            loadingMajors: "Descargando Estudios Superiores...",
            loadingCareers: "Descargando Carreras...",
            loadingDashboard: "Descargando Tablero..."
        }
    }
}

const getters = {
    loadingIndicatorLocale: (state, rootGetters) => {
        return rootGetters.useSpanishLocale
            ? state.spanishLocale
            : state.englishLocale;
    },
};

const actions = {};

const mutations = {};

export default {
    state,
    getters,
    actions,
    mutations,
};
