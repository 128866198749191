const state = () => {
    return {
        englishLocale: {
            header: "We are sorry.",
            description: "Texas CREWS does not support Internet Explorer. For the best experience using Texas CREWS please use Chrome, Safari, Firefox or Edge.",
            edgeButtonAriaLabel: "Go to Edge",
            edgeButtonText: "Go to Edge >",
            imageAltText: "Binoculars",
            alertText: "Texas CREWS could not be automatically opened in Edge. If this problem persists, please open Texas CREWS in Chrome, Safari, Firefox or Edge."
        },
        spanishLocale: {
            header: "Lo sentimos.",
            description: "Texas CREWS no es compatible con Internet Explorer. Para obtener la mejor experiencia con Texas CREWS, utilice Chrome, Safari, Firefox o Edge.",
            edgeButtonAriaLabel: "Ir a Edge.",
            edgeButtonText: "Ir a Edge >",
            imageAltText: "Binoculares",
            alertText: "Texas CREWS no se pudo abrir autom\u00E1ticamente en Edge. Si este problema persiste, abra Texas CREWS en Chrome, Safari, Firefox o Edge."
        }
    }
};

const getters = {
    browserNotSupportedLocale: (state, rootGetters) => {
        return rootGetters.useSpanishLocale
            ? state.spanishLocale
            : state.englishLocale;
    },
};

const actions = {};

const mutations = {};

export default {
    state,
    getters,
    actions,
    mutations,
};
