const state = () => {
    return {
        englishLocale: {
            appLogo: {
                altText: "Texas Consumer Resource for Education and Workforce Statistics Logo",
            },
            navButton: {
                backToStart: "Back to Start",
                backToSelect: "Back to Select",
                backToDashboard: "Back to Dashboard",
                backToPreviousPage: "Back to Previous Page"
            },
            helpIcon: {
                altText: "Help Icon",
            },
            languageSwitchButton: {
                ariaLabel: "Espa\u00F1ol",
                text: "Espa\u00F1ol",
            },
            accessibilityIcon: {
                altText: "Accessibility Tips Icon"
            }
        },
        spanishLocale: {
            appLogo: {
                altText: "Logo del Recurso Educativo para el Consumidor de Texas y Estad\u00EDsticas de la Fuerza Laboral",
            },
            navButton: {
                backToStart: "Regresar al Inicio",
                backToSelect: "Regresar a Seleccionar",
                backToDashboard: "Regrese al Tablero",
                backToPreviousPage: "Regrese a la P\u00E1gina Anterior"
            },
            helpIcon: {
                altText: "Icono de Ayuda",
            },
            languageSwitchButton: {
                ariaLabel: "English",
                text: "English",
            },
            accessibilityIcon: {
                altText: "Icono de consejos de accesibilidad"
            }
        }
    }
}

const getters = {
    headerLocale: (state, rootGetters) => {
        return rootGetters.useSpanishLocale
            ? state.spanishLocale
            : state.englishLocale;
    },
};

const actions = {};

const mutations = {};

export default {
    state,
    getters,
    actions,
    mutations,
};
