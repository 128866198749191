<template>
    <header>
        <!-- Mobile Buttons Header -->
        <div id="header-mobile" :class="applySecondaryHeaderStyle ? 'tc-header-mobile-secondary' : 'tc-header-mobile'">
            <div class="header-buttons-container">
                <div class="tc-container">
                    <!-- Secondary Header Nav -->
                    <BaseButton v-if="showHeaderNav"
                                class="router-link nav-button nav-button-light"
                                :aria-label="headerNavText"
                                :text="headerNavText"
                                @execute="handleBackNav()" />
                    <BaseButton class="language-switch-button"
                                :aria-label="header.languageSwitchButton.ariaLabel"
                                :text="header.languageSwitchButton.text"
                                @execute="handleLanguageSwitch()" />
                    <router-link :class="applySecondaryHeaderStyle ? 'router-link help-icon-light' : 'router-link help-icon-dark'"
                                 to="/faqs">
                        <span class="visually-hidden">{{ header.helpIcon.altText }}</span>
                    </router-link>
                    <router-link :class="applySecondaryHeaderStyle ? 'router-link accessibility-icon-light' : 'router-link accessibility-icon-dark'" 
                                 to="/accessibility-tips">
                        <span class="visually-hidden">{{ header.accessibilityIcon.altText }}</span>
                    </router-link>
                </div>                
            </div>
        </div>
        <div class="tc-header-container">
            <div class="tc-container">
                <div class="tc-header">                    
                    <router-link ref="headerLogo" style="outline: none;" to="/">                        
                        <img class="header-logo"
                             :src="headerLogo"
                             :alt="header.appLogo.altText" />
                    </router-link>
                    <div class="header-buttons-container">
                        <!-- Primary Header Nav -->
                        <BaseButton v-if="showHeaderNav"
                                    class="router-link nav-button nav-button-dark"
                                    :aria-label="headerNavText"
                                    :text="headerNavText"
                                    @execute="handleBackNav()" />
                        <BaseButton class="language-switch-button"
                                    :aria-label="header.languageSwitchButton.ariaLabel"
                                    :text="header.languageSwitchButton.text"
                                    @execute="handleLanguageSwitch()" />
                        <router-link class="router-link help-icon-dark" to="/faqs">
                            <span class="visually-hidden">{{ header.helpIcon.altText }}</span>
                        </router-link>
                        <router-link class="router-link accessibility-icon-dark" to="/accessibility-tips">
                            <span class="visually-hidden">{{ header.accessibilityIcon.altText }}</span>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>              
    </header>
</template>

<script>
    import BaseButton from '@/components/BaseButton.vue';
    import logoFullName from '@/assets/images/logos/logo-full-name.svg';
    import logo from '@/assets/images/logos/logo.svg';

    export default {
        name: 'TheHeader',
        components: {
            BaseButton,
        },
        data() {
            return {
                logoFullName,
                logo
            }
        },
        computed: {
            header() {
                return this.$store.getters.headerLocale;
            },
            applySecondaryHeaderStyle() {
                return this.$route.name !== 'HomePage' && this.$route.name !== 'ErrorPage';
            },
            headerLogo() {
                return this.$route.name !== 'HomePage' && this.$route.name !== 'ErrorPage'
                    ? logo
                    : logoFullName;
            },
            headerNavText() {
                const routeName = this.$route.name;

                if (routeName.includes('Home') || routeName.includes('Error')) {
                    return '';
                }

                if (routeName.includes('Select')) {
                    return this.header.navButton.backToStart;
                }

                if (routeName.includes('Dashboard')) {
                    return this.header.navButton.backToSelect;
                }

                if (routeName.includes('Compare')) {
                    return this.header.navButton.backToDashboard;
                }                

                return this.header.navButton.backToPreviousPage;
            },
            showHeaderNav() {
                return this.headerNavText.length !== '';
            }
        },
        watch: {
            $route() {
                this.$refs.headerLogo.$el.focus();
            }
        },
        methods: {
            handleBackNav() {
                const routeName = this.$route.name;

                if (routeName.includes('Select')) {
                    this.$router.push('/');
                    this.$store.commit('SET_HAS_COMPARABLE_SCHOOLS', false);
                    this.$store.commit('RESET_COMPARABLE_SCHOOLS');
                    return;
                }

                if (routeName === 'SchoolDashboard') {
                    this.$router.push('/select-school');
                    this.$store.commit('SET_HAS_COMPARABLE_SCHOOLS', false);
                    this.$store.commit('RESET_COMPARABLE_SCHOOLS');
                    return;
                }

                if (routeName === 'MajorDashboard') {
                    this.$router.push(`/${this.$store.getters.previousSelectPage}`);
                    return;
                }

                this.$router.go(-1);
            },
            handleLanguageSwitch() {
                const useSpanishLocale = this.$store.getters.useSpanishLocale;
                this.$store.commit('SET_USE_SPANISH_LOCALE', !useSpanishLocale);
            }
        }        
    }
</script>

<style scoped>
    .tc-header-container {
        box-shadow: 0px 4px 6px rgba(20, 20, 23, 0.15);
        position: relative;
        z-index: 1;
    }
    .tc-header {
        display: flex;
        justify-content: space-between;
    }
    .tc-header-mobile,
    .tc-header-mobile-secondary {
        display: none;
    }
    /* Logo */
    .header-logo {
        margin-top: 10px;
    }
    /* Nav Buttons, Lang Switch Button, Help Icon and Accessibility Icon */
    .header-buttons-container {
        margin-top: 80px;        
    }
    .nav-button {
        font-family: 'Source Sans Pro SemiBold', 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif;
        font-weight: 600;
        font-size: 16px;
        color: #0C154A;
        border: none;
        margin-right: 20px;
        padding-left: 16px;
        vertical-align: middle;        
        cursor: pointer;
    }
    .nav-button-dark {
        background: url('../assets/images/icons/left-caret-blue.svg') no-repeat;
        background-position: left;
        background-size: 8px;
    }    
    .language-switch-button {
        background-color: rgba(255, 255, 255, 0);
        font-family: 'Source Sans Pro SemiBold', 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif;
        font-weight: 600;
        font-size: 16px;
        color: #0C154A;
        cursor: pointer;
        border: hidden;
        margin-right: 20px;
        vertical-align: middle;
    }
    .nav-button:hover,
    .language-switch-button:hover {
        text-decoration: underline;
    }
    .help-icon-dark {
        background: url('../assets/images/icons/help-icon-blue-outline.svg') no-repeat;
        background-position: right;
        background-size: 16px;
        cursor: pointer;
        vertical-align: middle;
        margin-right: 20px;
    }
    .help-icon-dark .visually-hidden{
        font-size: 4px;
    }
    .help-icon-dark:hover {
        background: url('../assets/images/icons/help-icon-blue-solid.svg') no-repeat;
        background-size: 16px;
    }
    .accessibility-icon-dark {
        background: url('../assets/images/icons/universal-access-regular.svg') no-repeat;
        background-position: right;
        background-size: 16px;
        cursor: pointer;
        vertical-align: middle;
    }
    .accessibility-icon-dark:hover {
        background: url('../assets/images/icons/universal-access-solid.svg') no-repeat;
        background-size: 16px;
    }
    .visually-hidden {
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        height: 1px;
        overflow: hidden;
        white-space: nowrap;
        width: 2px;
        font-size: 2px;
    }

    /* Media Queries */

    /* Mobile */
    @media (max-width: 700px) {
        .tc-header .header-buttons-container {
            display: none;
        }
        /* Primary Mobile Header */
        .tc-header-mobile {
            display: block;
            background-color: #F7F7F8;
        }
        /* Secondary Mobile Header */
        .tc-header-mobile-secondary {
            display: block;
            background-color: #0c154a;
        }
        .tc-header-mobile-secondary .nav-button {
            font-family: 'Source Sans Pro SemiBold', 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            color: #FFFFFF;
            float: left;
        }
        .tc-header-mobile-secondary .language-switch-button {
            color: #FFFFFF;
        }
        .header-logo{
            max-width: 300px;
            height: 66px;
        }
        .header-buttons-container {
            margin-top: 0;
            padding: 8px 0;
            text-align: right;
        }
        .nav-button-light {
            background: url('../assets/images/icons/left-caret-white.svg') no-repeat;
            background-position: left;
            background-size: 8px;
        }
        .language-switch-button {
            width: 56px;
            font-size: 14px;
            margin-right: 12px;
        }
        .help-icon-dark {
            margin-right: 12px;
        }
        .help-icon-light {
            background: url('../assets/images/icons/help-icon-white-outline.svg') no-repeat;
            background-position: right;
            background-size: 16px;
            cursor: pointer;
            vertical-align: middle;
            margin-right: 12px;
        }
        .help-icon-light .visually-hidden {
            font-size: 4px;
        }
        .help-icon-light:hover {
            background: url('../assets/images/icons/help-icon-white-solid.svg') no-repeat;
            background-size: 16px;
        }
        .accessibility-icon-light {
            background: url('../assets/images/icons/universal-access-regular-white.svg') no-repeat;
            background-position: right;
            background-size: 16px;
            cursor: pointer;
            vertical-align: middle;
        }
        .accessibility-icon-light:hover {
            background: url('../assets/images/icons/universal-access-solid-white.svg') no-repeat;
            background-size: 16px;
        }
    }
</style>