<template>
    <div class="tc-loading-indicator-container">
        <img class="loading-indicator" 
             src="../assets/images/loading-animation.svg" 
             alt="Loading spinner"
             aria-hidden="true" />
        <span role="alert" class="loading-indicator-text" v-html="text" />
    </div>
</template>

<script>
    export default {
        name: 'LoadingIndicator',
        props: {
            text: { type: String, required: true },
        }
    }
</script>

<style scoped>
    .tc-loading-indicator-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .loading-indicator {        
        width: 160px;
        height: 160px;
        z-index: 10;        
    }
    .loading-indicator-text {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 20px;
        font-weight: 600;
        color: #141417;
    }

    /* Media Queries */
    @media (max-width: 600px) {
        .loading-indicator {
            width: 120px;
            height: 120px;
        }
        .loading-indicator-text {
            font-size: 16px;
        }
    }
</style>