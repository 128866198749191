<template>
    <DashboardTemplate :dashboardHeaderText="careerSchool"
                       :isLoading="isLoading"
                       @updateReport="handleUpdateReport()"
                       @download="handleDownload()">
        <!-- Banner Dropdowns -->
        <div slot="dashboard-banner-dropdowns" class="banner-dropdown-container">
            <div class="dashboard-dropdown-group">
                <label for="career-school-dropdown"
                       class="dashboard-dropdown-label"
                       v-html="careerSchoolDashboard.dashboardBanner.careerSchoolDropdownLabel" />

                <BaseDropDown id="career-school-dropdown"
                              class="dashboard-dropdown"
                              :ariaLabel="careerSchoolDashboard.dashboardBanner.careerSchoolDropdownLabel"
                              :options="careerSchoolDropdownValues"
                              :noOptionsText="dropdownNoOptions"
                              :clearable=false
                              v-model="selectedCareerSchool" />
            </div>
        </div>
        <!-- Widgets -->
        <div ref="dashboardContent" slot="dashboard-content" class="dashboard-widgets-container">
            <DatasetWidget ref="widget1"
                           :selectedDashboardItem="careerSchool"
                           :headerText="widgetOneHeader"
                           tableId="cs-dashboard-widget-1"
                           :hasTableHeaders=false
                           :footerLinks="footerLinks"
                           :footerAsterisks="[careerSchoolDashboard.widgetAsterisks.careerSchool, careerSchoolDashboard.widgetAsterisks.employmentRateTwo, careerSchoolDashboard.widgetAsterisks.eligibleTrainingProvider]"
                           :dataset=dashboardData.schoolProfile />            

            <DatasetWidget :selectedDashboardItem="careerSchool"
                           :headerText="widgetTwoHeader"
                           :isToggleable=true
                           tableId="cs-dashboard-widget-2"
                           :hasTableHeaders=false
                           :dataset=dashboardData.enrollmentAndCompletion />

            <DatasetWidget :selectedDashboardItem="careerSchool"
                           :headerText="widgetThreeHeader"
                           :isToggleable=true
                           tableId="cs-dashboard-widget-3"
                           :hasTableHeaders=false
                           :dataset=dashboardData.completionRateAndEmploymentRate />

            <DatasetWidget class="last-five-years-table"
                           :selectedDashboardItem="careerSchool"
                           :headerText="careerSchoolDashboard.widgetHeaders.completionAndEmploymentOutcomes"
                           :isToggleable=true
                           tableId="cs-dashboard-widget-4"
                           :dataset=dashboardData.completionAndEmploymentOutcomes />

            <DatasetWidget class="career-school-data-table"
                           :selectedDashboardItem="careerSchool"
                           :headerText="widgetFiveHeader"
                           tableId="cs-dashboard-widget-5"
                           :footerAsterisks="[careerSchoolDashboard.widgetAsterisks.employmentRateOne]"
                           :dataset=dashboardData.careerSchoolData />
        </div>
    </DashboardTemplate>
</template>

<script>
    /* eslint-disable */
    import DashboardTemplate from '@/components/PageTemplates/DashboardTemplate.vue';
    import BaseDropDown from '@/components/BaseDropDown.vue';
    import DatasetWidget from '@/components/Widgets/DatasetWidget.vue';
    import datasetMapperMixin from '@/mixins/datasetMapperMixin';    
    import pdfGeneratorMixin from '@/mixins/pdfGeneratorMixin';

    export default {
        name: 'CareerSchoolDashboard',
        props: ['id'],
        mixins: [datasetMapperMixin, pdfGeneratorMixin],
        components: {
            DashboardTemplate,
            BaseDropDown,
            DatasetWidget
        },
        computed: {
            careerSchoolDashboard() {
                return this.$store.getters.careerSchoolDashboardLocale;
            },
            careerSchoolDropdownValues() {
                return this.$store.getters.careerSchooolDashboardCareerSchools;
            },
            dropdownNoOptions() {
                return this.$store.getters.dropdownLocale.noOptions;
            },
            widgetOneHeader() {
                return this.careerSchoolDashboard.widgetHeaders.schoolProfile + ' ' + this.currentYear;
            },
            widgetTwoHeader() {
                return this.careerSchoolDashboard.widgetHeaders.enrollmentAndCompletion + ' ' + this.currentYear;
            },
            widgetThreeHeader() {
                return this.careerSchoolDashboard.widgetHeaders.completionRateAndEmploymentRate + ' ' + this.currentYear;
            },
            widgetFiveHeader() {
                return this.careerSchool + ' ' + this.careerSchoolDashboard.widgetHeaders.careerSchoolData;
            },
            footerLinks() {
                return [
                    { id: 'footer-link-1', name: this.careerSchoolDashboard.widgetAsterisks.eligibleTrainingProviderMoreInfo, url: this.$store.getters.moreInfoEtpUrl },
                    { id: 'footer-link-2', name: this.careerSchoolDashboard.widgetAsterisks.careerSchoolsMoreInfo, url: this.$store.getters.moreInfoCareerSchoolUrl }
                ]
            }
        },
        data() {
            return {
                isLoading: false,
                selectedCareerSchool: {
                    label: null,
                    value: null
                },
                careerSchoolId: 0,
                careerSchool: '',
                currentYear: 0,
                dashboardData: {
                    schoolProfile: {},
                    enrollmentAndCompletion: {},
                    completionRateAndEmploymentRate: {},
                    completionAndEmploymentOutcomes: {},
                    careerSchoolData: {}
                },
                windowWidth: window.innerWidth
            }
        },
        async created() {
            try {
                this.careerSchoolId = parseInt(this.id);
                const localCareerSchool = this.$store.getters.careerSchooolDashboardDataBySchoolId(this.careerSchoolId);

                // Check if the selected career school exists already in state; if not,
                // get the dashboard data and build the widgets
                if (localCareerSchool) {
                    this.setDashboardSelectedCareerSchool(this.careerSchoolId);
                    this.buildDashboardWidgets();
                }
                else {
                    this.isLoading = true;

                    const schools = this.$store.getters.schools;
                    if (schools.length < 1) {
                        await this.$store.dispatch('getSchoolsAsync');
                    }
                    await this.getDashboardDataAsync(this.careerSchoolId);
                    this.setDashboardSelectedCareerSchool(this.careerSchoolId);
                    this.buildDashboardWidgets();

                    this.isLoading = false;
                }
            } catch (error) {
                this.isLoading = false;
                this.$router.push('/error');
            }
        },
        mounted() {
            this.$nextTick(() => {
                // Add resize event listener for dynamic table styling
                window.addEventListener('resize', () => this.windowWidth = window.innerWidth);
            });
        },
        beforeDestroy() {
            window.removeEventListener('resize', () => this.windowWidth = window.innerWidth);
        },
        watch: {
            careerSchoolDashboard() {
                this.selectedCareerSchool = {
                    label: this.$store.getters.careerSchooolDashboardCareerSchoolNameById(this.selectedCareerSchool.value),
                    value: this.selectedCareerSchool.value
                };
                this.careerSchool = this.$store.getters.careerSchooolDashboardCareerSchoolNameById(this.careerSchoolId);
                this.buildDashboardWidgets();
            },
            async id() {
                try {
                    this.careerSchoolId = parseInt(this.selectedCareerSchool.value);
                    const localCareerSchool = this.$store.getters.careerSchooolDashboardDataBySchoolId(this.careerSchoolId);

                    // Check if the selected career school exists already in state; if not,
                    // get the dashboard data and build the widgets
                    if (localCareerSchool) {
                        this.setDashboardSelectedCareerSchool(this.careerSchoolId);
                        this.buildDashboardWidgets();
                    }
                    else {
                        this.isLoading = true;

                        await this.getDashboardDataAsync(this.careerSchoolId);
                        this.setDashboardSelectedCareerSchool(this.careerSchoolId);
                        this.buildDashboardWidgets();

                        this.isLoading = false;
                    }
                } catch (error) {
                    this.isLoading = false;
                    this.$router.push('/error');
                }
            },
            windowWidth() {
                this.buildCareerSchoolDataWidget();
            }
        },
        methods: {
            async getDashboardDataAsync(careerSchoolId) {
                await this.$store.dispatch('getCareerSchoolDashboardDataAsync', careerSchoolId);
            },
            handleUpdateReport() {
                if (this.selectedCareerSchool.label !== this.careerSchool) {
                    this.$router.push({ path: `/career-school-dashboard/${parseInt(this.selectedCareerSchool.value)}` });
                    return;
                }
            },
            handleDownload() {
                try {
                    const fileName = this.careerSchool;                    
                    const chartElement = this.$refs.dashboardContent;
                    const widgetTables = [];
                    widgetTables.push(this.dashboardData.schoolProfile.table);
                    widgetTables.push(this.dashboardData.enrollmentAndCompletion.table);
                    widgetTables.push(this.dashboardData.completionRateAndEmploymentRate.table);
                    widgetTables.push(this.dashboardData.completionAndEmploymentOutcomes.table);
                    widgetTables.push(this.dashboardData.careerSchoolData.table);

                    this.generateDashboardPdf(chartElement, widgetTables, fileName);
                } catch (error) {
                    const errorMessage = this.$store.getters.useSpanishLocale ? 'Lo sentimos, se produjo un error al descargar este panel en PDF. Int\u00E9ntalo de nuevo.' : 'Sorry, something went wrong downloading this dashboard to PDF. Please try again.';
                    alert(errorMessage);
                }
            },
            buildDashboardWidgets() {
                this.buildSchoolProfileWidget();
                this.buildEnrollmentAndCompletionWidget();
                this.buildCompletionRateAndEmploymentRateWidget();
                this.buildCompletionAndEmploymentOutcomesWidget();
                this.buildCareerSchoolDataWidget();
            },
            buildSchoolProfileWidget() {
                const schoolProfile = this.$store.getters.careerSchoolDashboardSchoolProfile(this.careerSchoolId);
                this.currentYear = schoolProfile.year;

                // Build table dataset for recent year
                const columns = [
                    { field: 'ProfileHeader', title: '' },
                    { field: 'ProfileInfo', title: '' }
                ];
                const tableHeaders = [
                    this.careerSchoolDashboard.widgetTableHeaders.typeOfInstitution,
                    this.careerSchoolDashboard.widgetTableHeaders.multipleLocations,
                    this.careerSchoolDashboard.widgetTableHeaders.numberOfEnrollees,
                    this.careerSchoolDashboard.widgetTableHeaders.numberOfCompleters,
                    this.careerSchoolDashboard.widgetTableHeaders.completionRate,
                    this.careerSchoolDashboard.widgetTableHeaders.employmentRateTwoAst,
                    this.careerSchoolDashboard.widgetTableHeaders.eligibleTrainingProvider
                ];

                const profileInfo = [];
                const institutionType = this.$store.getters.careerSchoolDashboardSchoolProfileInstitutionType(this.careerSchoolId);
                let multipleLocations = this.$store.getters.careerSchoolDashboardSchoolProfileMultipleLocations(this.careerSchoolId);
                let eligibleTrainingProvider = this.$store.getters.careerSchoolDashboardSchoolProfileEligibleTrainingProvider(this.careerSchoolId);

                multipleLocations ? multipleLocations = this.careerSchoolDashboard.schoolProfile.yesOption : multipleLocations = this.careerSchoolDashboard.schoolProfile.noOption;
                eligibleTrainingProvider ? eligibleTrainingProvider = this.careerSchoolDashboard.schoolProfile.yesOption : eligibleTrainingProvider = this.careerSchoolDashboard.schoolProfile.noOption;

                // Map dataset values
                profileInfo.push(institutionType + '*'); // institution type
                profileInfo.push(multipleLocations); // multiple locations
                profileInfo.push(this.mapDatasetValue(schoolProfile.numberOfEnrollees)); // num enrollees
                profileInfo.push(this.mapDatasetValue(schoolProfile.numberOfCompleters)); // num completers
                profileInfo.push(this.mapDatasetValueToPercent(schoolProfile.completionRate)); // completion rate
                profileInfo.push(this.mapDatasetValueToPercent(schoolProfile.employmentRate)); // enrollment rate
                profileInfo.push(eligibleTrainingProvider); // eligible training provider

                const tableData = new Array(7).fill({}).map((_, i) => ({
                    ProfileHeader: tableHeaders[i],
                    ProfileInfo: profileInfo[i],
                }));

                const dataset = {
                    table: {
                        columns,
                        tableData
                    }
                };

                this.dashboardData.schoolProfile = dataset;
            },
            buildEnrollmentAndCompletionWidget() {
                const enrollmentAndCompletion = this.$store.getters.careerSchoolDashboardEnrollmentAndCompletion(this.careerSchoolId);

                const enrollmentAndCompletionInfo = Object.values(enrollmentAndCompletion).slice(1, 3);
                const headers = [this.careerSchoolDashboard.widgetTableHeaders.numOfEnrollees, this.careerSchoolDashboard.widgetTableHeaders.numOfCompleters];

                // Build chart dataset for recent year
                const categories = {
                    categories: [
                        enrollmentAndCompletion.year
                    ],
                    labels: {
                        font: '14px Source Sans Pro, sans-serif'
                    }
                };
                const axis = [
                    {
                        name: 'enrollment and completion numbers',
                        labels: {
                            format: '{0:N0}',
                            font: '14px Source Sans Pro, sans-serif'
                        },
                        min: 0
                    }
                ];
                let chartData = new Array(2).fill({}).map((_, i) => ({
                    name: headers[i],
                    data: new Array(1).fill(enrollmentAndCompletionInfo[i] !== null ? enrollmentAndCompletionInfo[i] : null),
                    axis: axis[0].name,
                    tooltip: {
                        visible: true,
                        format: "{0:N0}",
                        template: "#: series.name #: #: kendo.format('{0:N0}',value) #"
                    }
                }));

                if (chartData[0].data[0] === null && chartData[1].data[0] === null) chartData = [];

                // Build table dataset for recent year
                const columns = [
                    { field: 'EnrollmentCompletionHeader', title: '' },
                    { field: 'EnrollmentCompletionData', title: '' }
                ];
                let tableData = new Array(2).fill({}).map((_, i) => ({
                    EnrollmentCompletionHeader: headers[i],
                    EnrollmentCompletionData: this.mapDatasetValue(enrollmentAndCompletionInfo[i]) 
                }));

                const dataset = {
                    chart: {
                        categories,
                        axis,
                        chartData
                    },
                    table: {
                        columns,
                        tableData
                    }
                };

                this.dashboardData.enrollmentAndCompletion = dataset;
            },
            buildCompletionRateAndEmploymentRateWidget() {
                const completionAndEmploymentRates = this.$store.getters.careerSchoolDashboardCompletionRateAndEmploymentRate(this.careerSchoolId);

                const completionAndEmploymentRatesInfo = Object.values(completionAndEmploymentRates).slice(3, 5);
                const headers = [this.careerSchoolDashboard.widgetTableHeaders.completionRate, this.careerSchoolDashboard.widgetTableHeaders.employmentRate];

                // Build chart dataset for recent year
                const categories = {
                    categories: [
                        completionAndEmploymentRates.year
                    ],
                    labels: {
                        font: '14px Source Sans Pro, sans-serif'
                    }
                };
                const axis = [
                    {
                        name: 'enrollment and completion numbers',
                        labels: {
                            format: '{0}%',
                            font: '14px Source Sans Pro, sans-serif'
                        },
                        min: 0,
                        max: 100
                    }
                ];
                let chartData = new Array(2).fill({}).map((_, i) => ({
                    name: headers[i],
                    data: new Array(1).fill(completionAndEmploymentRatesInfo[i]),
                    axis: axis[0].name,
                    tooltip: {
                        visible: true,
                        format: "{0}%",
                        template: "#: series.name #: #: kendo.format('{0}%',value) #"
                    }
                }));

                if (chartData[0].data[0] === null && chartData[1].data[0] === null) chartData = [];

                // Build table dataset for recent year
                const columns = [
                    { field: 'RatesHeader', title: '' },
                    { field: 'RatesData', title: '' }
                ];
                let tableData = new Array(2).fill({}).map((_, i) => ({
                    RatesHeader: headers[i],
                    RatesData: this.mapDatasetValueToPercent(completionAndEmploymentRatesInfo[i])
                }));

                const dataset = {
                    chart: {
                        categories,
                        axis,
                        chartData
                    },
                    table: {
                        columns,
                        tableData
                    }
                };

                this.dashboardData.completionRateAndEmploymentRate = dataset;
            },
            buildCompletionAndEmploymentOutcomesWidget() {
                const completionEmploymentOutcomes = this.$store.getters.careerSchoolDashboardCompletionAndEmploymentOutcomes(this.careerSchoolId);

                let years = [];
                let numberOfEnrollees = [];
                let completionRates = [];
                let employmentRates = [];

                for (var i = completionEmploymentOutcomes.length - 1; i >= 0; i--) {
                    years.push(completionEmploymentOutcomes[i].year);
                    numberOfEnrollees.push(completionEmploymentOutcomes[i].numberOfEnrollees);
                    completionRates.push(completionEmploymentOutcomes[i].completionRate);
                    employmentRates.push(completionEmploymentOutcomes[i].employmentRate);
                }                

                // Build chart dataset
                const categories = {
                    categories: years,
                    // Align cost to the left and percentage to the right
                    axisCrossingValues: [0, 10, 10, 10]
                };
                const axis = [
                    {
                        name: 'numberOfEnrollees',
                        labels: {
                            format: '{0:N0}'
                        },
                        min: 0
                    },
                    {
                        name: "rate",
                        labels: {
                            position: "end",
                            format: "{0}%"
                        },
                        min: 0,
                        max: 105
                    }
                ];
                let chartData = [];
                const firstDataset = {
                    type: "column",
                    data: numberOfEnrollees,
                    name: this.careerSchoolDashboard.widgetTableHeaders.numOfEnrollees,
                    tooltip: {
                        visible: true,
                        format: "{0:N0}",
                        template: "#: series.name #: #: kendo.format('{0:N0}',value) #"
                    },
                    color: '#020A6A'
                };
                const secondDataset = {
                    type: "line",
                    data: completionRates,
                    name: this.careerSchoolDashboard.widgetTableHeaders.completionRate,
                    axis: "rate",
                    tooltip: {
                        visible: true,
                        format: "{0}%",
                        template: "#: series.name #: #: kendo.format('{0}%',value) #"
                    }
                };
                const thirdDataset = {
                    type: "line",
                    data: employmentRates,
                    name: this.careerSchoolDashboard.widgetTableHeaders.employmentRate,
                    axis: "rate",
                    tooltip: {
                        visible: true,
                        format: "{0}%",
                        template: "#: series.name #: #: kendo.format('{0}%',value) #"
                    }
                };
                chartData.push(firstDataset);
                chartData.push(secondDataset);
                chartData.push(thirdDataset);                

                const nullNumberOfEnrollees = numberOfEnrollees.filter(e => e !== null);
                const nullCompletionRates = completionRates.filter(r => r !== null);
                const nullEmploymentRates = employmentRates.filter(r => r !== null);

                if (nullNumberOfEnrollees.length === 0 && nullCompletionRates.length === 0 && nullEmploymentRates.length === 0) {
                    chartData = [];
                }

                // Build table dataset
                const columns = [
                    { field: 'Year', title: this.careerSchoolDashboard.widgetTableHeaders.year },
                    { field: 'NumberEnrollees', title: this.careerSchoolDashboard.widgetTableHeaders.numOfEnrollees },
                    { field: 'CompletionRate', title: this.careerSchoolDashboard.widgetTableHeaders.completionRate },
                    { field: 'EmploymentRate', title: this.careerSchoolDashboard.widgetTableHeaders.employmentRate }
                ];
                let tableData = new Array(5).fill({}).map((_, j) => ({
                    Year: completionEmploymentOutcomes[0].year - j,
                    NumberEnrollees: this.mapDatasetValue(completionEmploymentOutcomes[j].numberOfEnrollees),
                    CompletionRate: this.mapDatasetValueToPercent(completionEmploymentOutcomes[j].completionRate),
                    EmploymentRate: this.mapDatasetValueToPercent(completionEmploymentOutcomes[j].employmentRate)
                }));

                const dataset = {
                    chart: {
                        categories,
                        axis,
                        chartData
                    },
                    table: {
                        columns,
                        tableData
                    }
                };

                this.dashboardData.completionAndEmploymentOutcomes = dataset;
            },
            buildCareerSchoolDataWidget() {
                const careerSchoolData = this.$store.getters.careerSchoolDashboardCareerSchoolData(this.careerSchoolId);

                // Build table dataset
                const columns = [
                    { field: 'Year', title: this.careerSchoolDashboard.widgetTableHeaders.year },
                    { field: 'NumberEnrollees', title: this.careerSchoolDashboard.widgetTableHeaders.numOfEnrollees },
                    { field: 'NumberCompleters', title: this.careerSchoolDashboard.widgetTableHeaders.numOfCompleters },
                    { field: 'CompletionRate', title: this.careerSchoolDashboard.widgetTableHeaders.completionRate },
                    { field: 'EmploymentRate', title: this.careerSchoolDashboard.widgetTableHeaders.employmentRateAst }
                ];
                const tableData = new Array(10).fill({}).map((_, i) => ({
                    Year: careerSchoolData[0].year - i,
                    NumberEnrollees: this.mapDatasetValue(careerSchoolData[i]?.numberOfEnrollees),
                    NumberCompleters: this.mapDatasetValue(careerSchoolData[i]?.numberOfCompleters),
                    CompletionRate: this.mapDatasetValueToPercent(careerSchoolData[i]?.completionRate),
                    EmploymentRate: this.mapDatasetValueToPercent(careerSchoolData[i]?.employmentRate)
                }));

                const dataset = {
                    table: {
                        columns,
                        tableData
                    }
                };

                this.dashboardData.careerSchoolData = dataset;
            },
            setDashboardSelectedCareerSchool(careerSchoolId) {
                const careerSchool = this.$store.getters.careerSchooolDashboardCareerSchoolNameById(careerSchoolId);

                // Career school dropdown selected value
                this.selectedCareerSchool = {
                    label: careerSchool,
                    value: careerSchoolId
                };

                // Career school name used across dashboard and widget headers
                this.careerSchool = careerSchool;
            }
        }
    }
</script>

<style scoped>
    /* Banner */
    .banner-dropdown-container {
        display: flex;
        max-width: 420px;
    }
    .dashboard-dropdown-group {
        display: flex;
        flex-direction: column;
        padding-right: 12px;
    }
    .dashboard-dropdown-label {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 16px;
        color: rgba(51, 51, 51, 1);
    }
    .dashboard-dropdown {
        margin-top: 4px;
        width: 240px;
    }

    /* Dashboard - Widgets */
    .dashboard-widgets-container {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-column-gap: 30px;
        grid-row-gap: 46px;
        margin-top: 42px;
    }
    .dashboard-widgets-container > :nth-child(1) {
        grid-column: 1 / span 3;
    }
    .dashboard-widgets-container > :nth-child(2) {
        grid-column: 4 / span 3;
    }
    .dashboard-widgets-container > :nth-child(3) {
        grid-column: 1 / span 3;
    }
    .dashboard-widgets-container > :nth-child(4) {
        grid-column: 4 / span 3;
    }
    .dashboard-widgets-container > :nth-child(5) {
        grid-column: 1 / span 6;        
    }

    .asterisk {
        font-family: 'Source Sans Pro Italic', 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif;
        font-style: italic;
        font-size: 12px;
        color: #333333;
        line-height: 16px;
        margin-top: -30px;
        width: 355px;
    }

    /* Media Queries */

    /* Tablets and Under */
    @media (max-width: 768px) {
        .dashboard-widgets-container * {
            grid-column: 1 / span 6 !important;
        }
    }
</style>

<style>
    .last-five-years-table .table .k-header:first-child,
    .last-five-years-table .table th:first-child {
        width: 55px;
    }
    .career-school-data-table .table .k-header:first-child,
    .career-school-data-table .table th:first-child {
        width: 55px;
    }
    .career-school-data-table .table .k-header:last-child,
    .career-school-data-table .table th:last-child {
        width: 40%;
    }
</style>