<template>
    <FooterPageTemplate :header="accessibilityTipsPage.header"
                        :contentElements="accessibilityTips"
                        :isLoading="isLoading"
                        :loadingText="loadingText" />
</template>

<script>
    import FooterPageTemplate from '@/components/PageTemplates/FooterPageTemplate.vue';

    export default {
        name: 'AccessibilityTipsPage',
        components: {
            FooterPageTemplate
        },
        computed: {
            accessibilityTipsPage() {
                return this.$store.getters.accessibilityTipsPageLocale;
            },
            loadingText() {
                return this.$store.getters.loadingIndicatorLocale.loading;
            }
        },
        data() {
            return {
                isLoading: false,
                accessibilityTips: []
            }
        },
        async created() {
            try {
                const accessibilityTips = this.$store.getters.accessibilityTips;

                if (accessibilityTips.length > 0) {
                    this.accessibilityTips = accessibilityTips;
                }
                else {
                    this.isLoading = true;
                    await this.$store.dispatch('getAccessibilityTipsAsync');
                    this.accessibilityTips = this.$store.getters.accessibilityTips;
                    this.isLoading = false;
                }
            } catch (error) {
                this.isLoading = false;
                this.$router.push('/error');
            }
        },
        watch: {
            accessibilityTipsPage() {
                this.accessibilityTips = this.$store.getters.accessibilityTips;
            }
        }
    }
</script>