import httpClient from "../../../../service/httpClient";

const state = () => {
    return {
        englishLocale: {
            dashboardBanner: {
                schoolDropdownLabel: "School:",
                schoolYearDropdownLabel: "Year:",
                updateReportButton: {
                    text: "Update Report",
                    ariaLabel: "Update Report"
                }
            },
            dashboardHeader: {
                buttons: {
                    compareButton: {
                        text: "Compare",
                        ariaLabel: "Compare"
                    },
                    compareBackButton: {
                        text: "Back to Compare",
                        ariaLabel: "Back to Compare"
                    },
                    downloadButton: {
                        text: "Download",
                        ariaLabel: "Download Dashboard"
                    }
                }
            },
            filterSearchInput: {
                placeholder: "Search Majors"
            },
            degreeLevelFilterDropdown: {
                label: "View by Degree Level:",
                otherLabel: "Degree Level:",
                allDegreeLevels: "All Degree Levels"
            },
            majorFilterDropdown: {
                label: "Major:",
                allMajors: "All Majors"
            },
            widgetHeaders: {
                schoolProfile: "School Profile - General Info",
                medianFirstYearWagesDegreeLevel: "Median First Year Wages by Degree Level",
                medianHistoricalWagesDegreeLevel: "Historical Median Wages by Degree Level",
                threeProgramsHighestFirstYearWages: "Three Programs with Highest Median First Year Wages",
                compareStatewideWages: "Compare Median Wages with Statewide Median Wages",
                graduates: "Graduates"
            },
            widgetTableHeaders: {
                typeOfInstitution: "Type of Institution",
                notApplicable: "Not applicable",
                location: "Location",
                averageTuitionUndergrad: "Average Tuition (Undergrad)",
                averageTimeToDegreeUndergrad: "Average Time to Degree (Undergrad)",
                satPercentileScores: "SAT 25th - 75th Percentile Scores",
                totalEnrollment: "Total Fall Enrollment",
                graduationRate: "Graduation Rate*",
                percentGradsWithLoans: "Percent of Graduates with Loans",
                avgLoanAmountAtGraduation: "Average Loan Amount at Graduation",
                medianFirstYearWages: "Median First Year Wages**",
                loanAsPercentFirstYearWages: "Loan as % of First Year Wages",
                texasUnemploymentRate: "Texas Unemployment Rate",
                degreeLevel: "Degree Level",
                yearWages: "Wages",
                majors: "Majors",
                institutions: "Institutions",
                firstYearWages: "First Year Wages",
                numberGrads: "# of Grads",
                percentageGradsWithLoans: "% of Grads with Loans",
                averageLoanAmountAtGraduation: "Avg Loan Amount at Graduation",
                percentFoundWithWages: "% Found with Wages",
                medianWages: "Median Wages",
                loanAsPercentOfYearOneWages: "Loan as % of Year 1 Wages"
            },
            widgetAsterisks: {
                graduationRate: "* Graduating within 6 years at 4-year school or 4 years at 2-year school.",
                medianFirstYearWages: "** Graduates may or may not work within their field of study.",
                graduateWork: "* Graduates may or may not work within their field of study."
            },
            schoolProfile: {
                year: "year",
                years: "years"
            }
        },
        spanishLocale: {
            dashboardBanner: {
                schoolDropdownLabel: "Escuela:",
                schoolYearDropdownLabel: "A\u00F1o:",
                updateReportButton: {
                    text: "Actualizar Informe",
                    ariaLabel: "Actualizar Informe"
                }
            },
            dashboardHeader: {
                buttons: {
                    compareButton: {
                        text: "Comparar"
                    },
                    compareBackButton: {
                        text: "Volver a Comparar"
                    },
                    downloadButton: {
                        text: "Descargar",
                        ariaLabel: "Descargar Tablero"
                    }
                }
            },
            filterSearchInput: {
                placeholder: "Buscar \u00C1reas Principales de Estudios"
            },
            degreeLevelFilterDropdown: {
                label: "Ver por Nivel de Grado:",
                otherLabel: "Nivel de Grado:",
                allDegreeLevels: "Todos los Niveles de Grado"
            },
            majorFilterDropdown: {
                label: "\u00C1rea Principal de Estudios:",
                allMajors: "Todas las \u00C1reas Principales de Estudios"
            },
            widgetHeaders: {
                schoolProfile: "Perfil Escolar - Informaci\u00F3n General",
                medianFirstYearWagesDegreeLevel: "Salario Mediano en el Primer A\u00F1o de acuerdo con Nivel de T\u00EDtulo",
                medianHistoricalWagesDegreeLevel: "Salarios Medios Hist\u00F3ricos por Nivel de Grado para",
                threeProgramsHighestFirstYearWages: "Tres Programas con los Salarios Medianos m\u00E1s Altos en el Primer A\u00F1o",
                compareStatewideWages: "Comparar Salario Mediano con Salarios Medianos Estatales",
                graduates: "Graduados"
            },
            widgetTableHeaders: {
                typeOfInstitution: "Tipo de Instituci\u00F3n",
                notApplicable: "No aplica",
                location: "Ubicaci\u00F3n",
                averageTuitionUndergrad: "Costo Promedio de Matr\u00EDcula (Pregrado)",
                averageTimeToDegreeUndergrad: "Tiempo Promedio para Obtener T\u00EDtulo (Pregrado)",
                satPercentileScores: "Resultados de la Prueba SAT - Percentil 25 a 75",
                totalEnrollment: "Inscripci\u00F3n Total del Oto\u00F1o",
                graduationRate: "Tasa de Graduaci\u00F3n",
                percentGradsWithLoans: "Porcentaje de Graduados con Pr\u00E9stamos",
                avgLoanAmountAtGraduation: "Monto Promedio de Pr\u00E9stamo al Graduarse",
                medianFirstYearWages: "Salario Mediano en el Primer A\u00F1o**",
                loanAsPercentFirstYearWages: "Pr\u00E9stamo como % del Salario en el Primer A\u00F1o",
                texasUnemploymentRate: "Tasa de Desempleo de Texas",
                degreeLevel: "Nivel de T\u00EDtulo",
                yearWages: "Salario",
                majors: "\u00C1reas Principales de Estudios",
                institutions: "Instituciones",
                firstYearWages: "Salario en el Primer A\u00F1o",
                numberGrads: "# de Graduados",
                percentageGradsWithLoans: "% de Graduados con Pr\u00E9stamos",
                averageLoanAmountAtGraduation: "Monto Promedio de Pr\u00E9stamo al Graduarse",
                percentFoundWithWages: "% Encontrado con Salario",
                medianWages: "Salario Mediano",
                loanAsPercentOfYearOneWages: "Pr\u00E9stamo como % de Salario en A\u00F1o 1"
            },
            widgetAsterisks: {
                graduationRate: "* Gradu\u00E1ndose dentro de 6 a\u00F1os cuando es una escuela de 4 a\u00F1os \u00F3 dentro de 4 a\u00F1os cuando es una escuela de 2 a\u00F1os.",
                medianFirstYearWages: "** Los graduados pueden o no pueden estar trabajando en su \u00E1rea principal de estudios.",
                graduateWork: "* Los graduados pueden o no pueden estar trabajando en su \u00E1rea principal de estudios."
            },
            schoolProfile: {
                year: "a\u00F1o",
                years: "a\u00F1os"
            }
        },
        schoolYears: [],
        dashboardData: []
    }
};

const getters = {
    schoolDashboardLocale: (state, rootGetters) => {
        return rootGetters.useSpanishLocale
            ? state.spanishLocale
            : state.englishLocale;
    },
    // School dropdown
    schoolDashboardSchools: (state, rootGetters) => (schoolId) => {
        const schoolInstituteTier = rootGetters.schools.find(s => s.schoolProfileId === schoolId)?.instituteTierId;
        return rootGetters.useSpanishLocale
            ? rootGetters.schools.filter(s => s.instituteTierId === schoolInstituteTier)
                                 .map(s => ({ label: s.instituteLegalNameSpanish, value: s.schoolProfileId }))
            : rootGetters.schools.filter(s => s.instituteTierId === schoolInstituteTier)
                                 .map(s => ({ label: s.instituteLegalName, value: s.schoolProfileId }));        
    },
    // School year dropdown
    schoolDashboardSchoolYears: (state) => {
        return state.schoolYears.map(String);
    },
    // Dashboard school name by school id
    schoolDashboardSchoolNameById: (state, rootGetters) => (schoolId) => {
        return rootGetters.useSpanishLocale
            ? rootGetters.schools.find(s => s.schoolProfileId === schoolId)?.instituteLegalNameSpanish
            : rootGetters.schools.find(s => s.schoolProfileId === schoolId)?.instituteLegalName;
    },
    // Dashboard data by school id
    schoolDashboardDataBySchoolId: (state) => (schoolId) => {
        return state.dashboardData.find(s => s.schoolId === schoolId);
    },
    // School profile
    schoolDashboardSchoolProfile: (state) => (schoolId, year) => {
        return state.dashboardData.find(s => s.schoolId === schoolId)?.schoolProfileData.find(y => y.year === year);
    },
    // School profile - institution type id
    schoolDashboardSchoolProfileInstitutionTypeId: (state, rootGetters) => (schoolId) => {
        return rootGetters.schools.find(s => s.schoolProfileId === schoolId)?.instituteTier.instituteTierId;
    },
    // School profile - institution type
    schoolDashboardSchoolProfileInstitutionType: (state, rootGetters) => (schoolId, year) => {        
        return rootGetters.useSpanishLocale
            ? state.dashboardData.find(s => s.schoolId === schoolId)?.schoolProfileData.find(y => y.year === year).institutionTypeDescriptionSpanish
            : state.dashboardData.find(s => s.schoolId === schoolId)?.schoolProfileData.find(y => y.year === year).institutionTypeDescription;
    },
    // Degree level wages - major filters
    schoolDashboardDegreeLevelWagesFilters: (state, rootGetters) => (schoolId, year) => {
        return rootGetters.useSpanishLocale
            ? state.dashboardData.find(s => s.schoolId === schoolId)?.degreeLevelData.filter(d => d.year === year)
                                                                                     .map(d => ({ label: d.programLongNameSpanish, value: d.programId }))
            : state.dashboardData.find(s => s.schoolId === schoolId)?.degreeLevelData.filter(d => d.year === year)
                                                                                     .map(d => ({ label: d.programLongName, value: d.programId }));
        
    },
    // Degree level wages
    schoolDashboardDegreeLevelWages: (state, rootGetters) => (schoolId, year, majorId) => {
        return rootGetters.useSpanishLocale
            ? state.dashboardData.find(s => s.schoolId === schoolId)?.degreeLevelData.filter(d => d.year === year && d.programId === majorId && d.degreeLevelId !== 9)
                                                                                     .map(({ year, degreeLevelNameSpanish, wagesYear1, wagesYear3, wagesYear5, wagesYear8, wagesYear10 }) => ({ year, degreeLevelNameSpanish, wagesYear1, wagesYear3, wagesYear5, wagesYear8, wagesYear10 }))
            : state.dashboardData.find(s => s.schoolId === schoolId)?.degreeLevelData.filter(d => d.year === year && d.programId === majorId && d.degreeLevelId !== 9)
                                                                                     .map(({ year, degreeLevelName, wagesYear1, wagesYear3, wagesYear5, wagesYear8, wagesYear10 }) => ({ year, degreeLevelName, wagesYear1, wagesYear3, wagesYear5, wagesYear8, wagesYear10 }));
    },
    // Three programs with highest median first year wages - degree level filters
    schoolDashboardThreeHighestProgramMedianFirstYearWagesFilters: (state, rootGetters) => (schoolId, year) => {
        return rootGetters.useSpanishLocale
            ? state.dashboardData.find(s => s.schoolId === schoolId)?.topThreeMajorSchoolAndStatewideWages.filter(w => w.year === year)
                                                                                                          .map(w => ({ label: w.degreeLevelNameSpanish, value: w.degreeLevelId }))
                                                                                                          .sort((a, b) => b.value - a.value)
            : state.dashboardData.find(s => s.schoolId === schoolId)?.topThreeMajorSchoolAndStatewideWages.filter(w => w.year === year)
                                                                                                          .map(w => ({ label: w.degreeLevelName, value: w.degreeLevelId }))
                                                                                                          .sort((a, b) => b.value - a.value);
    },
    // Three programs with highest median first year wages
    schoolDashboardThreeHighestProgramMedianFirstYearWages: (state, rootGetters) => (schoolId, year, degreeLevelId) => {
        return rootGetters.useSpanishLocale
            ? state.dashboardData.find(s => s.schoolId === schoolId)?.topThreeMajorSchoolAndStatewideWages.filter(w => w.year === year && w.degreeLevelId === degreeLevelId)
                                                                                                          .map(({ instituteLegalName, programId, programLongNameSpanish, degreeLevelId, degreeLevelNameSpanish, wagesYear1 }) => ({ instituteLegalName, programId, programLongNameSpanish, degreeLevelId, degreeLevelNameSpanish, wagesYear1 }))
            : state.dashboardData.find(s => s.schoolId === schoolId)?.topThreeMajorSchoolAndStatewideWages.filter(w => w.year === year && w.degreeLevelId === degreeLevelId)
                                                                                                          .map(({ instituteLegalName, programId, programLongName, degreeLevelId, degreeLevelName, wagesYear1 }) => ({ instituteLegalName, programId, programLongName, degreeLevelId, degreeLevelName, wagesYear1 }));
    },
    // Compare median wages with statewide wages - degree level filters
    schoolDashboardCompareMedianWagesStatewideDegreeLevelFilters: (state, rootGetters) => (schoolId, year) => {
        return rootGetters.useSpanishLocale
            ? state.dashboardData.find(s => s.schoolId === schoolId)?.allMajorSchoolAndStatewideWages.filter(w => w.year === year)
                                                                                                     .map(w => ({ label: w.degreeLevelNameSpanish, value: w.degreeLevelId }))
                                                                                                     .sort((a, b) => b.value - a.value)
            : state.dashboardData.find(s => s.schoolId === schoolId)?.allMajorSchoolAndStatewideWages.filter(w => w.year === year)
                                                                                                     .map(w => ({ label: w.degreeLevelName, value: w.degreeLevelId }))
                                                                                                     .sort((a, b) => b.value - a.value);
    },
    // Compare median wages with statewide wages - major filters
    schoolDashboardCompareMedianWagesStatewideMajorFilters: (state, rootGetters) => (schoolId, year) => {
        return rootGetters.useSpanishLocale
            ? state.dashboardData.find(s => s.schoolId === schoolId)?.allMajorSchoolAndStatewideWages.filter(w => w.year === year)
                                                                                                     .map(w => ({ label: w.programLongNameSpanish, value: w.programId }))
            : state.dashboardData.find(s => s.schoolId === schoolId)?.allMajorSchoolAndStatewideWages.filter(w => w.year === year)
                                                                                                     .map(w => ({ label: w.programLongName, value: w.programId }))
                                                                                                     .sort((a, b) => (a.label.toLowerCase() < b.label.toLowerCase()) ? -1 : (a.label.toLowerCase() > b.label.toLowerCase()) ? 1 : 0);    
    },
    // Compare median wages with statewide wages
    schoolDashboardCompareMedianWagesStatewide: (state) => (schoolId, year, degreeLevelId, majorId) => {
        return state.dashboardData.find(s => s.schoolId === schoolId)?.allMajorSchoolAndStatewideWages.filter(w => w.year === year && w.degreeLevelId === degreeLevelId && w.programId === majorId);
    },
    // Major and degree level data - degree level filters
    schoolDashboardMajorAndDegreeLevelDataFilters: (state, rootGetters) => (schoolId, year) => {
        return rootGetters.useSpanishLocale
            ? state.dashboardData.find(s => s.schoolId === schoolId)?.degreeLevelData.filter(d => d.year === year && d.programId !== 390)
                                                                                     .map(d => ({ label: d.degreeLevelNameSpanish, value: d.degreeLevelId }))
                                                                                     .sort((a, b) => b.value - a.value)
            : state.dashboardData.find(s => s.schoolId === schoolId)?.degreeLevelData.filter(d => d.year === year && d.programId !== 390)
                                                                                     .map(d => ({ label: d.degreeLevelName, value: d.degreeLevelId }))
                                                                                     .sort((a, b) => b.value - a.value);
    },
    // Major and degree level data
    schoolDashboardMajorAndDegreeLevelData: (state, rootGetters) => (schoolId, year, degreeLevelId) => {
        return rootGetters.useSpanishLocale
            ? state.dashboardData.find(s => s.schoolId === schoolId)?.degreeLevelData.filter(d => d.year === year && d.degreeLevelId === degreeLevelId && d.programId !== 390)
                                                                                     .map(({ programId, programLongNameSpanish, numberOfGraduates, loanPercent, loanAmount, wagesPercent, wagesYear1, wagesYear3, wagesYear5, wagesYear8, wagesYear10, loanToFirstWageRatio }) => ({ programId, programLongNameSpanish, numberOfGraduates, loanPercent, loanAmount, wagesPercent, wagesYear1, wagesYear3, wagesYear5, wagesYear8, wagesYear10, loanToFirstWageRatio }))
            : state.dashboardData.find(s => s.schoolId === schoolId)?.degreeLevelData.filter(d => d.year === year && d.degreeLevelId === degreeLevelId && d.programId !== 390)
                                                                                     .map(({ programId, programLongName, numberOfGraduates, loanPercent, loanAmount, wagesPercent, wagesYear1, wagesYear3, wagesYear5, wagesYear8, wagesYear10, loanToFirstWageRatio }) => ({ programId, programLongName, numberOfGraduates, loanPercent, loanAmount, wagesPercent, wagesYear1, wagesYear3, wagesYear5, wagesYear8, wagesYear10, loanToFirstWageRatio }));
        
    }
};

const actions = {
    async getSchoolDashboardDataAsync({ commit }, schoolId) {
        // Get dashboard data
        const response = await httpClient.getAsync(`/SchoolTrans/${schoolId}`);

        const commitData = { schoolId, response: response.data };

        commit("SET_SCHOOL_DASHBOARD_DATA", commitData)
    }
};

const mutations = {
    SET_SCHOOL_DASHBOARD_DATA: (state, { schoolId, response }) => {
        // School years
        state.schoolYears = response.years.length > 0 ? response.years.map(y => y.year) : [];

        // Dashboard data
        const schoolProfileData = response.schoolProfileData.length > 0 ? response.schoolProfileData : [];
        const degreeLevelData = response.degreeLevelData.length > 0 ? response.degreeLevelData : [];
        const topThreeMajorSchoolAndStatewideWages = response.top3MajorSchoolAndStatewideWages.length > 0 ? response.top3MajorSchoolAndStatewideWages : [];
        const allMajorSchoolAndStatewideWages = response.allMajorSchoolAndStatewideWages.length > 0 ? response.allMajorSchoolAndStatewideWages : [];

        state.dashboardData.push({
            schoolId,
            schoolProfileData,
            degreeLevelData,
            topThreeMajorSchoolAndStatewideWages,
            allMajorSchoolAndStatewideWages
        });
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
