import httpClient from '../../../../service/httpClient';

const state = () => {
    return {
        englishLocale: {
            header: "Explore majors and degree programs.",
            description: "Select a major from the list below.",
            dropdownLabel: "Select a Major:",
            viewDashboardButton: "View Dashboard",

        },
        spanishLocale: {
            header: "Explorar campos principales de estudio y programas de grado.",
            description: "Seleccione un campo principal de estudio de la lista siguiente.",
            dropdownLabel: "Seleccione un campo principal de estudio:",
            viewDashboardButton: "Ver Tablero",
        },
        majors: [],
        majorNames: []
    }
};

const getters = {
    selectMajorPageLocale: (state, rootGetters) => {
        return rootGetters.useSpanishLocale
            ? state.spanishLocale
            : state.englishLocale;
    },
    majors: (state) => {
        return state.majors
    },
    majorNamesLocale: (state, rootGetters) => {
        return rootGetters.useSpanishLocale
            ? state.majorNames.map(m => ({ label: m.nameSpanish, value: m.id }))
            : state.majorNames.map(m => ({ label: m.nameEnglish, value: m.id }));
    },
    selectedMajorLocale: (state, rootGetters) => (majorId) => {
        return rootGetters.useSpanishLocale
            ? state.majorNames.find(m => m.id === majorId)?.nameSpanish
            : state.majorNames.find(m => m.id === majorId)?.nameEnglish;
    }
};

const actions = {
    async getMajorsAsync({ commit }) {
        const response = await httpClient.getAsync('/Majors');
        commit("SET_MAJORS", response.data);
        commit("SET_MAJOR_NAMES", response.data);
    },
};

const mutations = {
    SET_MAJORS: (state, majors) => state.majors = majors,
    SET_MAJOR_NAMES: (state, majors) => {
        for (const major of majors) {
            state.majorNames.push({
                nameEnglish: major.programLongName,
                nameSpanish: major.programLongNameSpanish,
                id: major.programId
            });
        }

        state.majorNames.sort((a, b) => (a.nameEnglish > b.nameEnglish) ? 1 : -1);
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
};
