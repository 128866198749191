<template>
    <div>
        <div v-if="!isLoading">
            <!-- Banner -->
            <SelectPageBanner :headerText="bannerHeaderText"
                              :descriptionText="bannerDescriptionText" />

            <!-- Content -->
            <div v-if="!isLoading" class="tc-container">
                <div class="select-page-container">
                    <slot name="select-page-content"></slot>

                    <router-link ref="viewDashboardButton"
                                 :class="isButtonDisabled ? 'router-link view-dashboard-button view-dashboard-button-disabled' : 'router-link view-dashboard-button view-dashboard-button-enabled'"
                                 :to="dashboardPage"
                                 :disabled="isButtonDisabled"
                                 :event="isButtonDisabled ? '' : 'click'"
                                 v-html="buttonText">
                    </router-link>
                </div>
            </div>
        </div>
    
        <LoadingIndicator v-if="isLoading" :text="loadingText" />
    </div>
</template>

<script>
    import SelectPageBanner from '@/components/PageTemplates/SelectPageBanner.vue';
    import LoadingIndicator from '@/components/LoadingIndicator.vue';

    export default {
        name: 'SelectPageTemplate',
        components: {
            SelectPageBanner,
            LoadingIndicator
        },
        props: {
            bannerHeaderText: { type: String, required: true },
            bannerDescriptionText: { type: String, required: true },
            buttonText: { type: String, required: true },
            dashboardPage: { type: String, required: true },
            isButtonDisabled: { type: Boolean, required: true, default: true },
            isLoading: { type: Boolean, required: true },
            loadingText: { type: String, required: true }
        }
    }
</script>

<style scoped>
    .select-page-container {
        margin-top: 25px;
        max-width: 380px;
    }
    .view-dashboard-button {
        font-family: 'Source Sans Pro SemiBold', 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif;
        font-weight: 600;
        font-size: 16px;
        display: inline-block;
        background-color: #4C6CE3;
        color: #FFFFFF;        
        border-radius: 8px;
        border: none;
        width: 100%;
        margin-top: 25px;
        padding: 12px 0;
        text-align: center;
    }
    .disabled,
    .view-dashboard-button-disabled {
        opacity: 0.5;
    }
    .view-dashboard-button-disabled:hover {
        cursor: not-allowed;        
    }
    .enabled,
    .view-dashboard-button-enabled {
        opacity: 1;
    }
    .view-dashboard-button-enabled:hover {
        background-color: #2D4DC4;
        cursor: pointer;
    }

    /* Media Queries */

    /* Tablets and Mobile */
    @media (max-width: 600px) {
        .select-page-container {
            padding-bottom: 94px;
        }
    }
</style>