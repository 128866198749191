<template>
    <!-- Button with text/background image -->
    <button v-if="imgSrc.length === 0" :aria-label="ariaLabel" v-html="text" @click="$emit('execute')" />
    <!-- Button with image and no text -->
    <button v-else-if="imgSrc.length > 0" :aria-label="ariaLabel" @click="$emit('execute')">
        <img :src="imgSrc" :alt="ariaLabel" />
    </button>
</template>

<script>
    export default {
        name: 'BaseButton',
        props: {
            ariaLabel: { type: String, required: true },
            text: { type: String, required: false, default: '' },
            imgSrc: { type: String, required: false, default: '' }
        }
    }
</script>