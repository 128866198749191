const state = () => {
    return {
        englishLocale: {
            noData: "No data",
        },
        spanishLocale: {
            noData: "No hay datos",
        }
    }
}

const getters = {
    datasetMapperLocale: (state, rootGetters) => {
        return rootGetters.useSpanishLocale
            ? state.spanishLocale
            : state.englishLocale;
    },
};

const actions = {};

const mutations = {};

export default {
    state,
    getters,
    actions,
    mutations,
};
