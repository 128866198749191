import httpClient from '../../../../service/httpClient';

const state = () => {
    return {
        englishLocale: {
            header: "Compare up to four schools.",
            bannerDropdownLabel: "Select a School to Compare:",
            maximumComparableSchoolsLabel: "You have selected the maximum number of schools to compare. Remove a school below to add another.",
            comparing: "Comparing",
            of: "of",
            schools: "Schools",
            addSchoolButtonText: "Add a School",
            addSchoolButtonAriaLabel: "Add a School",
            viewDashboardButton: "View Dashboard",
            removeButtonText: "Remove",
            removeButtonAriaLabel: "Remove",
            tableHeaders: [
                "Type of Institution",
                "Location",
                "Average Tuition (Undergrad)",
                "Average Time to Degree (Undergrad)",
                "SAT 25th - 75th Percentile Scores",
                "Total Fall Enrollment",
                "Graduation Rate*",
                "Percent of Graduates with Loans",
                "Average Loan Amount at Graduation",
                "Median First Year Wages**",
                "Loan as % of First Year Wages"                
            ],
            notApplicable: "Not applicable",
            time: {
                year: "year",
                years: "years"
            },
            footerAsterisks: {
                graduationRate: "* Graduating within 6 years at 4-year school or 4 years at 2-year school.",
                medianFirstYearWages: "** Graduates may or may not work within their field of study."
            }
        },
        spanishLocale: {
            header: "Comparar hasta cuatro escuelas.",
            bannerDropdownLabel: "Seleccione una escuela para comparar:",
            maximumComparableSchoolsLabel: "Ha seleccionado el n\u00FAmero m\u00E1ximo de escuelas para comparar. Quite una de las escuelas de abajo para agregar otra.",
            comparing: "Comparando",
            of: "de",
            schools: "Escuelas",
            addSchoolButtonText: "Agregue una Escuela",
            addSchoolButtonAriaLabel: "Agregue una Escuela",
            viewDashboardButton: "Ver Tablero",
            removeButtonText: "Quite",
            removeButtonAriaLabel: "Quite",
            tableHeaders: [
                "Tipo de Instituci\u00F3n",                
                "Ubicaci\u00F3n",
                "Costo Promedio de Matr\u00EDcula (Pregrado)",
                "Tiempo Promedio para Obtener T\u00EDtulo (Pregrado)",
                "Resultados de la Prueba SAT - Percentil 25 a 75",
                "Inscripci\u00F3n Total del Oto\u00F1o",
                "Tasa de Graduaci\u00F3n",
                "Porcentaje de Graduados con Pr\u00E9stamos",
                "Monto Promedio de Pr\u00E9stamo al Graduarse",
                "Salario Mediano en el Primer A\u00F1o**",
                "Pr\u00E9stamo como % del Salario en el Primer A\u00F1o"
            ],
            notApplicable: "No aplica",
            time: {
                year: "a\u00F1o",
                years: "a\u00F1os"
            },
            footerAsterisks: {                
                graduationRate: "* Gradu\u00E1ndose dentro de 6 a\u00F1os cuando es una escuela de 4 a\u00F1os \u00F3 dentro de 4 a\u00F1os cuando es una escuela de 2 a\u00F1os.",
                medianFirstYearWages: "** Los graduados pueden o no pueden estar trabajando en su \u00E1rea principal de estudios."
            }
        },
        schools: [],
        schoolNames: [],
        comparableSchools: [],        
        hasComparableSchools: false
    }
};

const getters = {
    compareSchoolPageLocale: (state, rootGetters) => {
        return rootGetters.useSpanishLocale
            ? state.spanishLocale
            : state.englishLocale;
    },
    compareSchools: (state) => {
        return state.schools;
    },    
    compareSchoolNamesLocale: (state, rootGetters) => {
        return rootGetters.useSpanishLocale
            ? state.schoolNames.map(s => ({ label: s.nameSpanish, value: s.id }))
            : state.schoolNames.map(s => ({ label: s.nameEnglish, value: s.id }));
    },
    compareSchoolDataById: (state, rootGetters) => (schoolId) => {
        return rootGetters.useSpanishLocale
            ? state.schools.filter(s => s.schoolProfileId === schoolId)
                           .map(({ schoolProfileId, year, instituteLegalNameSpanish, institutionTypeDescriptionSpanish, city, state, tuitionFee, degreeTimeFinish, satScore, enrollment, graduationRate, loanPercent, loanAmount, wagesYear1, loanToFirstWageRatio }) => ({ schoolProfileId, year, instituteLegalNameSpanish, institutionTypeDescriptionSpanish, city, state, tuitionFee, degreeTimeFinish, satScore, enrollment, graduationRate, loanPercent, loanAmount, wagesYear1, loanToFirstWageRatio }))
                           .shift()
            : state.schools.filter(s => s.schoolProfileId === schoolId)
                           .map(({ schoolProfileId, year, instituteLegalName, institutionTypeDescription, city, state, tuitionFee, degreeTimeFinish, satScore, enrollment, graduationRate, loanPercent, loanAmount, wagesYear1, loanToFirstWageRatio }) => ({ schoolProfileId, year, instituteLegalName, institutionTypeDescription, city, state, tuitionFee, degreeTimeFinish, satScore, enrollment, graduationRate, loanPercent, loanAmount, wagesYear1, loanToFirstWageRatio }))
                           .shift();
    },
    compareSchoolInstitutionTypeId: (state, rootGetters) => (schoolId) => {
        return rootGetters.schools.find(s => s.schoolProfileId === schoolId)?.instituteTier.instituteTierId;
    },
    compareSchoolInstitutionType: (state, rootGetters) => (schoolId) => {
        return rootGetters.useSpanishLocale
            ? rootGetters.schools.find(s => s.schoolProfileId === schoolId)?.institutionTypeDescriptionSpanish
            : rootGetters.schools.find(s => s.schoolProfileId === schoolId)?.institutionTypeDescription;
    },
    comparableSchools: (state) => {
        return state.comparableSchools;
    },
    hasComparableSchools: (state) => {
        return state.hasComparableSchools;
    },
    selectedCompareSchoolLocale: (state, rootGetters) => (schoolId) => {
        return rootGetters.useSpanishLocale
            ? state.schoolNames.find(s => s.id === schoolId)?.nameSpanish
            : state.schoolNames.find(s => s.id === schoolId)?.nameEnglish;
    }
};

const actions = {
    async getCompareSchoolsAsync({ commit }) {
        const response = await httpClient.getAsync('/CompareSchools');
        commit("SET_COMPARE_SCHOOLS", response.data);
        commit("SET_COMPARE_SCHOOL_NAMES", response.data);
    },
};

const mutations = {
    SET_COMPARE_SCHOOLS: (state, schools) => state.schools = schools,
    SET_COMPARE_SCHOOL_NAMES: (state, schools) => {
        for (const school of schools) {
            state.schoolNames.push({
                nameEnglish: school.instituteLegalName,
                nameSpanish: school.instituteLegalNameSpanish,
                id: school.schoolProfileId
            });
        }
    },
    SET_COMPARABLE_SCHOOLS: (state, comparableSchool) => {
        state.comparableSchools.push(comparableSchool);
    },
    REMOVE_COMPARABLE_SCHOOL: (state, index) => {
        state.comparableSchools.splice(index, 1);
    },
    RESET_COMPARABLE_SCHOOLS: (state) => {
        state.comparableSchools = [];  
    },
    SET_HAS_COMPARABLE_SCHOOLS: (state, hasComparableSchools) => {
        state.hasComparableSchools = hasComparableSchools;
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
};
