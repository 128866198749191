<template>
    <div>
        <div class="tc-homepage-banner">
            <div class="tc-container">
                <div class="banner-container">
                    <h1 class="banner-header" v-html="homePage.heroImage.text" />
                    <p class="banner-description" v-html="homePage.heroImage.textDescription" />
                </div>
            </div>
        </div>
        <div class="tc-container">
            <div class="tc-homepage-card-container">
                <router-link class="router-link tc-homepage-card" to="/select-school">
                    <BaseCard :imageSrc="schoolsCardImg"
                              :imageAltText="homePage.cards.searchSchoolsCard.imgAltText"
                              :cardText="homePage.cards.searchSchoolsCard.cardText"
                              :buttonText="homePage.cards.searchSchoolsCard.buttonText" />
                </router-link>               
                <router-link class="router-link tc-homepage-card" to="/select-major">
                    <BaseCard :imageSrc="majorsCardImg"
                              :imageAltText="homePage.cards.searchMajorsCard.imgAltText"
                              :cardText="homePage.cards.searchMajorsCard.cardText"
                              :buttonText="homePage.cards.searchMajorsCard.buttonText" />
                </router-link>
                <router-link class="router-link tc-homepage-card" to="/select-career">
                    <BaseCard :imageSrc="careersCardImg"
                              :imageAltText="homePage.cards.searchCareersCard.imgAltText"
                              :cardText="homePage.cards.searchCareersCard.cardText"
                              :buttonText="homePage.cards.searchCareersCard.buttonText" />
                </router-link>
            </div>
        </div>
    </div>    
</template>

<script>
    import BaseCard from '@/components/BaseCard.vue';

    import heroImg from '@/assets/images/hero-image.png';
    import schoolsCardImg from '@/assets/images/home-svgs/college-svg.svg';
    import majorsCardImg from '@/assets/images/home-svgs/major-svg.svg';
    import careersCardImg from '@/assets/images/home-svgs/career-svg.svg';

    export default {
        name: 'HomePage',
        components: {
            BaseCard,
        },
        data() {
            return {
                heroImg,
                schoolsCardImg,
                majorsCardImg,
                careersCardImg,
            }
        },
        computed: {
            homePage() {
                return this.$store.getters.homePageLocale;
            }
        },
    }
</script>

<style scoped>
    /* Banner */
    .tc-homepage-banner {
        background: url(../assets/images/hero-image.png) center no-repeat;
        background-size: cover;
        margin-bottom: 50px;
    }
    .banner-container {
        max-width: 600px;
        padding-top: 40px;
        padding-bottom: 40px;
    }
    .banner-header {
        font-family: 'Source Sans Pro SemiBold', 'Source Sans Pro', sans-serif;
        font-weight: 700;
        font-size: 48px;
        color: rgba(255, 255, 255, 0.99);
        text-align: left;
        line-height: 48px;
    }
    .banner-description {
        font-family: 'Source Sans Pro SemiBold', 'Source Sans Pro', sans-serif;
        font-size: 20px;
        color: rgba(255, 255, 255, 0.99);
        text-align: left;
        line-height: 26px;
        margin-top: 20px;
    }
    /* Cards */
    .tc-homepage-card-container {
        display: flex;
        justify-content: space-between;
    }
    .tc-homepage-card:hover {
        box-shadow: 0 0 0 2pt #4c6ce3;
        border-radius: 8px;
        cursor: pointer;
    }

    /* Media Queries */

    /* Laptops, Tablets and Mobile */
    @media (max-width: 1110px) {
        .tc-homepage-card-container {            
            flex-wrap: wrap;
            justify-content: space-evenly;            
            align-items: center;
        }
        .tc-homepage-card {
            margin-bottom: 40px;
        }
    }
    /* Tablets and Mobile */
    @media (max-width: 700px) {
        .tc-homepage-banner {
            margin-bottom: 24px;
        }
        .banner-container {
            padding-top: 25px;
            padding-bottom: 20px;
        }
        .banner-header {
            font-weight: 700;
            font-size: 28px;
            line-height: 32px;
        }
        .banner-description {
            font-size: 16px;
            line-height: 22px;
        }
    }
</style>