import Vue from 'vue';
import Vuex from 'vuex';

// Global
import global from './modules/global/global';
// App
import header from './modules/app/header';
import autoScrollButton from './modules/app/autoScrollButton';
import footer from './modules/app/footer';
import nav from './modules/app/nav';
// Config
import config from './modules/config/config';
// Components
import baseDropDown from './modules/components/baseDropDown';
import browserNotSupported from './modules/components/browserNotSupported';
import datasetWidget from './modules/components/datasetWidget';
import linksWidget from './modules/components/linksWidget';
import loadingIndicator from './modules/components/loadingIndicator';
import widgets from './modules/components/widgets';
// Mixins
import datasetMapper from './modules/mixins/datasetMapper';
// Views
import homePage from './modules/views/homePage';
import selectSchoolPage from './modules/views/SelectPages/selectSchoolPage';
import selectMajorPage from './modules/views/SelectPages/selectMajorPage';
import selectCareerPage from './modules/views/SelectPages/selectCareerPage';
import schoolDashboard from './modules/views/Dashboards/schoolDashboard';
import careerSchoolDashboard from './modules/views/Dashboards/careerSchoolDashboard';
import majorDashboard from './modules/views/Dashboards/majorDashboard';
import compareSchoolPage from './modules/views/ComparePages/compareSchoolPage';
import accessibilityTipsPage from './modules/views/FooterPages/accessibilityTipsPage';
import faqPage from './modules/views/FooterPages/faqPage';
import glossaryPage from './modules/views/FooterPages/glossaryPage';
import errorPage from './modules/views/errorPage';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        global,
        header,
        autoScrollButton,
        footer,
        nav,
        config,
        baseDropDown,
        browserNotSupported,
        datasetWidget,
        linksWidget,
        loadingIndicator,
        widgets,
        datasetMapper,
        homePage,
        selectSchoolPage,
        selectMajorPage,
        selectCareerPage,
        schoolDashboard,
        careerSchoolDashboard,
        majorDashboard,
        compareSchoolPage,
        accessibilityTipsPage,
        faqPage,
        glossaryPage,
        errorPage
    },
});