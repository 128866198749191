<template>
    <footer>
        <div class="tc-container">
            <div class="footer-links-container">
                <router-link class="router-link footer-link" to="/faqs" v-html="footer.links.faq" />
                <router-link class="router-link footer-link" to="/glossary" v-html="footer.links.glossary" />
                <a class="router-link footer-link" :href="`mailto:${contactUsEmail}`" v-html="footer.links.contactUs" />
            </div>
        </div>

        <hr class="footer-separator">

        <div class="tc-container">
            <div class="footer-logos-container">
                <img class="twc-logo"
                     src="../assets/images/logos/TWC-logo.png"
                     :alt="footer.twcLogo.altText">
                <img class="thecb-logo"
                     src="../assets/images/logos/THECB-logo.png"
                     :alt="footer.thecbLogo.altText">
            </div>
            <div class="footer-content-container">
                <label v-html="footer.copyright.statement" />
                <p class="footer-content-copyright" v-html="footer.copyright.symbol" />
            </div>                
        </div>
    </footer>
</template>

<script>
    export default {
        name: 'TheFooter',
        computed: {
            footer() {
                return this.$store.getters.footerLocale;
            },
            contactUsEmail() {
                return this.$store.getters.contactUsEmail || '';
            }
        }
    }
</script>

<style scoped>
    footer {
        background-color: #0B204F;
        color: #FFFFFF;
        margin-top: 50px;
    }
    /* Links */
    .footer-links-container {
        display: flex;
        justify-content: space-between;
        font-family: 'Source Sans Pro SemiBold', 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        max-width: 425px;
        padding: 10px;
        margin: auto;
    }
    .footer-link {
        color: #FFFFFF;
    }
    .footer-link:hover {
        text-decoration: underline;
    }
    /* Separator */
    .footer-separator {
        color: #FFFFFF;
        opacity: 0.20;
    }
    /* Logos */
    .footer-logos-container {
        display: flex;
        max-width: 250px;
        justify-content: space-between;
        padding-top: 20px;
        padding-bottom: 20px;
        margin: auto;
    }
    .twc-logo {
        width: 86px;
        height: 82px;
        opacity: 0.98;
    }
    .thecb-logo {
        width: 140px;
        height: 72px;
    }
    /* Content */
    .footer-content-container {
        max-width: 404px;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 14px;
        text-align: center;
        margin: auto;
    }
    .footer-content-copyright {
        padding-top: 20px;
        padding-bottom: 10px;
    }

    /* Media Queries */

    /* Mobile */
    @media(max-width: 475px) {
        .footer-link {
            font-size: 14px;
        }
        .footer-logos-container {
            max-width: 225px;
        }
        .twc-logo {
            width: 72px;
            height: 70px;
        }
        .thecb-logo {
            width: 130px;
            height: 60px;
        }
    }
</style>