import Vue from 'vue';
import VueRouter from 'vue-router';

// Views
import HomePage from '@/views/HomePage';
import SelectSchoolPage from '@/views/SelectPages/SelectSchoolPage';
import SelectMajorPage from '@/views/SelectPages/SelectMajorPage';
import SelectCareerPage from '@/views/SelectPages/SelectCareerPage';
import SchoolDashboard from '@/views/Dashboards/SchoolDashboard';
import CareerSchoolDashboard from '@/views/Dashboards/CareerSchoolDashboard';
import MajorDashboard from '@/views/Dashboards/MajorDashboard';
import CompareSchoolPage from '@/views/ComparePages/CompareSchoolPage';
import AccessibilityTipsPage from '@/views/FooterPages/AccessibilityTipsPage';
import FaqPage from '@/views/FooterPages/FaqPage';
import GlossaryPage from '@/views/FooterPages/GlossaryPage';
import ErrorPage from '@/views/ErrorPage';

Vue.use(VueRouter);

const baseTitle = 'Texas CREWS | ';

const routes = [
    {
        path: '/',
        name: 'HomePage',
        component: HomePage,
        alias: '/home',
        meta: {
            title: baseTitle + 'Home'
        }
    },
    {
        path: '/select-school',
        name: 'SelectSchoolPage',
        component: SelectSchoolPage,
        meta: {
            title: baseTitle + 'Select School'
        }
    },
    {
        path: '/select-major',
        name: 'SelectMajorPage',
        component: SelectMajorPage,
        meta: {
            title: baseTitle + 'Select Major'
        }
    },
    {
        path: '/select-career',
        name: 'SelectCareerPage',
        component: SelectCareerPage,
        meta: {
            title: baseTitle + 'Select Career'
        }
    },
    {
        path: '/school-dashboard/:id',
        name: 'SchoolDashboard',
        component: SchoolDashboard,
        meta: {
            title: baseTitle + 'School Dashboard'
        },
        props: true
    },
    {
        path: '/career-school-dashboard/:id',
        name: 'CareerSchoolDashboard',
        component: CareerSchoolDashboard,
        meta: {
            title: baseTitle + 'Career School Dashboard'
        },
        props: true
    },
    {
        path: '/major-dashboard/:id',
        name: 'MajorDashboard',
        component: MajorDashboard,
        meta: {
            title: baseTitle + 'Major Dashboard'
        },
        props: true
    },
    {
        path: '/compare-school/:id',
        name: 'CompareSchoolPage',
        component: CompareSchoolPage,
        meta: {
            title: baseTitle + 'Compare School'
        },
        props: true
    },
    {
        path: '/faqs',
        name: 'FaqPage',
        component: FaqPage,
        meta: {
            title: baseTitle + 'FAQs'
        }
    },
    {
        path: '/accessibility-tips',
        name: 'AccessibilityTipsPage',
        component: AccessibilityTipsPage,
        meta: {
            title: baseTitle + 'Accessibility Tips'
        }
    },
    {
        path: '/glossary',
        name: 'GlossaryPage',
        component: GlossaryPage,
        meta: {
            title: baseTitle + 'Glossary'
        }
    },
    // Error Page (has to be last registered route)
    {
        path: '*',
        name: 'ErrorPage',
        component: ErrorPage,
        meta: {
            title: baseTitle + 'Error'
        }
    }
];

const router = new VueRouter({
    base: process.env.BASE_URL,
    mode: 'history',
    routes,
    scrollBehavior() {
        // Auto scrolls to top of each page
        return { x: 0, y: 0 };
    },
});

router.beforeEach((to, from, next) => {
    document.title = to.meta.title || 'Texas CREWS';
    next();
});

export default router;
