const state = () => {
    const currentYear = new Date().getFullYear();

    return {
        englishLocale: {
            links: {
                faq: "FAQs",
                glossary: "Glossary",
                contactUs: "Contact Us",
            },
            twcLogo: {
                altText: "Texas WorkForce Commission Logo",
            },
            thecbLogo: {
                altText: "Texas Higher Education Coordinating Board Logo",
            },
            copyright: {
                statement: "Texas CREWS is a joint project of the Texas Workforce Commission &amp; the Texas Higher Education Coordinating Board",
                symbol: `&#169; ${currentYear} Texas CREWS`,
            },
        },
        spanishLocale: {
            links: {
                faq: "Preguntas Frequentes",
                glossary: "Glosario",
                contactUs: "Cont\u00E1ctenos",
            },
            twcLogo: {
                altText: "Logo de la Comisi\u00F3n de la Fuerza Laboral de Texas",
            },
            thecbLogo: {
                altText: "Logo de la Junta Coordinadora de Educaci\u00F3n Superior de Texas",
            },
            copyright: {
                statement: "Texas CREWS es un proyecto conjunto de la Comisi\u00F3n de la Fuerza Laboral de Texas y la Junta Coordinadora de Educaci\u00F3n Superior de Texas.",
                symbol: `&#169; ${currentYear} Texas CREWS`,
            },
        }
    }
}

const getters = {
    footerLocale: (state, rootGetters) => {
        return rootGetters.useSpanishLocale
            ? state.spanishLocale
            : state.englishLocale;
    },
};

const actions = {};

const mutations = {};

export default {
    state,
    getters,
    actions,
    mutations,
};
