import httpClient from "../../../../service/httpClient";

const state = () => {
    return {
        englishLocale: {
            dashboardBanner: {
                majorDropdownLabel: "Major:",
                majorYearDropdownLabel: "Year:",
                updateReportButton: {
                    text: "Update Report",
                    ariaLabel: "Update Report"
                }
            },
            dashboardHeader: {
                buttons: {
                    downloadButton: {
                        text: "Download",
                        ariaLabel: "Download Dashboard"
                    },                    
                }
            },
            filterSearchInput: {
                placeholder: "Search Schools"
            },
            degreeLevelFilterDropdown: {
                label: "View by Degree Level:",
                allDegreeLevels: "All Degree Levels"
            },
            widgetHeaders: {
                majorDescription: "Description",
                relatedMajors: "Related Majors",
                relatedCareers: "Related Careers",
                medianFirstYearWages: "Median First Year Wages by Degree Level",
                medianHistoricalWages: "Historical Median Wages by Degree Level",
                compareMedianFirstYearWages: "Compare Median First Year Wages with Other Majors - All Degree Levels",
                graduates: "Graduates"
            },
            widgetTableHeaders: {
                degreeLevel: "Degree Level",
                yearWages: "Wages",
                majors: "Majors",
                schools: "Schools",
                numberOfGrads: "# of Grads",
                averageTuition: "Avg Tuition (Undergrad)",
                averageTimeToDegree: "Avg Time to Degree (Years)",
                percentGradsWithLoans: "% of Grads with Loans",
                averageLoanAtGraduation: "Avg Loan at Graduation",
                medianWages: "Median Wages",
                loanAsPercentOfYearOneWages: "Loan as % of Year 1 Wages"
            }
        },
        spanishLocale: {
            dashboardBanner: {
                majorDropdownLabel: "\u00C1rea Principal de Estudio:",
                majorYearDropdownLabel: "A\u00F1o:",
                updateReportButton: {
                    text: "Actualizar Informe",
                    ariaLabel: "Actualizar Informe"
                }
            },
            dashboardHeader: {
                buttons: {
                    compareButton: {
                        text: "Comparar",
                        ariaLabel: "Comparar"
                    },
                    downloadButton: {
                        text: "Descargar",
                        ariaLabel: "Descargar Tablero"
                    }
                }
            },
            filterSearchInput: {
                placeholder: "Buscar Escuelas"
            },
            degreeLevelFilterDropdown: {
                label: "Ver por Nivel de Grado:",
                allDegreeLevels: "Todos los Niveles de Grado"
            },
            widgetHeaders: {
                majorDescription: "Descripci\u00F3n",
                relatedMajors: "\u00C1reas de Estudio Principal Relacionadas",
                relatedCareers: "Carreras Relacionadas",
                medianFirstYearWages: "Salario Medio del Primer A\u00F1o por Nivel de Grado",
                medianHistoricalWages: "Salarios Medios Hist\u00F3ricos por Nivel de Grado",
                compareMedianFirstYearWages: "Comparar Salarios Medios del Primer A\u00F1o con Salarios Estatales - Todos los Niveles de Grado",
                graduates: "Graduados"
            },
            widgetTableHeaders: {
                degreeLevel: "Nivel de Grado",
                yearWages: "Salarios",
                majors: "\u00C1reas Principales de Estudio",
                schools: "Escuelas",
                numberOfGrads: "N\u00FAmero de Graduados",
                averageTuition: "Costo de Matr\u00EDcula Promedio",
                averageTimeToDegree: "Tiempo Promedio de Graduaci\u00F3n (A\u00F1os)",
                percentGradsWithLoans: "Porcentaje de Graduados con Pr\u00E9stamos",
                averageLoanAtGraduation: "Pr\u00E9stamo Promedio al Graduarse",
                medianWages: "Salarios Medios",
                loanAsPercentOfYearOneWages: "Pr\u00E9stamo como Porcentaje del Salario del Primer A\u00F1o"
            }
        },
        majorYears: [],
        dashboardData: []
    }
};

const getters = {
    majorDashboardLocale: (state, rootGetters) => {
        return rootGetters.useSpanishLocale
            ? state.spanishLocale
            : state.englishLocale;
    },
    // Major dropdown
    majorDashboardMajors: (state, rootGetters) => {
        return rootGetters.majors;
    },
    // Major year dropdown
    majorDashboardMajorYears: (state) => {
        return state.majorYears.map(String);
    },
    // Dashboard major name by major id
    majorDashboardMajorNameById: (state, rootGetters) => (majorId) => {
        return rootGetters.useSpanishLocale
            ? rootGetters.majors.find(m => m.programId === majorId)?.programLongNameSpanish
            : rootGetters.majors.find(m => m.programId === majorId)?.programLongName;
    },
    // Dashboard data by major id
    majorDashboardDataByMajorId: (state) => (majorId) => {
        return state.dashboardData.find(m => m.majorId === majorId);
    },
    // Description
    majorDashboardMajorDescription: (state, rootGetters) => (majorId) => {
        return rootGetters.useSpanishLocale
            ? rootGetters.majors.find(m => m.programId === majorId)?.programDescriptionSpanish
            : rootGetters.majors.find(m => m.programId === majorId)?.programDescription;
    },
    // Related majors
    majorDashboardRelatedMajors: (state, rootGetters) => (majorId) => {
        return rootGetters.useSpanishLocale
            ? state.dashboardData.find(m => m.majorId === majorId)?.relatedMajors?.map(n => ({ name: n.nameSpanish, id: n.id }))
            : state.dashboardData.find(m => m.majorId === majorId)?.relatedMajors?.map(n => ({ name: n.nameEnglish, id: n.id }));
    },
    // Related careers
    majorDashboardRelatedCareers: (state, rootGetters) => (majorId) => {
        const relatedCareers = rootGetters.majors.filter(m => m.programId === majorId)
                                                 .map(m => m.careersPrograms)
                                                 .flat()
                                                 .map(({ career }) => ({ nameEnglish: career.careerName, nameSpanish: career.careerNameSpanish, id: career.careerCode }));
        relatedCareers.sort((a, b) => (a.nameEnglish > b.nameEnglish) ? 1 : -1);
        return rootGetters.useSpanishLocale
            ? relatedCareers.map(c => ({ name: c.nameSpanish, id: c.id }))
            : relatedCareers.map(c => ({ name: c.nameEnglish, id: c.id }))
    },
    // Degree level wages
    majorDashboardDegreeLevelWages: (state, rootGetters) => (majorId) => {
        return rootGetters.useSpanishLocale
            ? state.dashboardData.find(m => m.majorId === majorId).degreeLevelWages
                                 .map(({ year, degreeLevelId, degreeLevelNameSpanish, wagesYear1, wagesYear3, wagesYear5, wagesYear8, wagesYear10 }) => ({ year, degreeLevelId, degreeLevelNameSpanish, wagesYear1, wagesYear3, wagesYear5, wagesYear8, wagesYear10 }))
            : state.dashboardData.find(m => m.majorId === majorId).degreeLevelWages
                                 .map(({ year, degreeLevelId, degreeLevelName, wagesYear1, wagesYear3, wagesYear5, wagesYear8, wagesYear10 }) => ({ year, degreeLevelId, degreeLevelName, wagesYear1, wagesYear3, wagesYear5, wagesYear8, wagesYear10 }));
    },
    // Related major wages
    majorDashboardRelatedMajorWages: (state, rootGetters) => (majorId) => {
        return rootGetters.useSpanishLocale
            ? state.dashboardData.find(m => m.majorId === majorId).relatedMajorWages
                                                                  .map(({ year, programLongNameSpanish, wagesYear1, wagesYear3, wagesYear5, wagesYear8, wagesYear10 }) => ({ year, programLongNameSpanish, wagesYear1, wagesYear3, wagesYear5, wagesYear8, wagesYear10 }))
                                                                  .sort(w => w.wagesYear1)
            : state.dashboardData.find(m => m.majorId === majorId).relatedMajorWages
                                                                  .map(({ year, programLongName, wagesYear1, wagesYear3, wagesYear5, wagesYear8, wagesYear10 }) => ({ year, programLongName, wagesYear1, wagesYear3, wagesYear5, wagesYear8, wagesYear10 }))
                                                                  .sort(w => w.wagesYear1);
    },
    // Degree level data - degree level filters
    majorDashboardDegreeLevelDataFilters: (state, rootGetters) => (majorId, year) => {
        return rootGetters.useSpanishLocale
            ? state.dashboardData.find(m => m.majorId === majorId)?.degreeLevelData.filter(d => d.year === year)
                                                                                   .map(d => ({ label: d.degreeLevelNameSpanish, value: d.degreeLevelId }))
                                                                                   .sort((a, b) => b.value - a.value)
            : state.dashboardData.find(m => m.majorId === majorId)?.degreeLevelData.filter(d => d.year === year)
                                                                                   .map(d => ({ label: d.degreeLevelName, value: d.degreeLevelId }))
                                                                                   .sort((a, b) => b.value - a.value);                 
    },
    // Degree level data
    majorDashboardDegreeLevelData: (state) => (majorId) => {
        return state.dashboardData.find(m => m.majorId === majorId)?.degreeLevelData;
    }
};

const actions = {
    async getMajorDashboardDataAsync({ commit }, majorId) {
        // Get dashboard data
        const response = await httpClient.getAsync(`/MajorTrans/${majorId}`);

        const commitData = { majorId, response: response.data };

        commit("SET_MAJOR_DASHBOARD_DATA", commitData);      
    }
};

const mutations = {
    SET_MAJOR_DASHBOARD_DATA: (state, { majorId, response }) => {        
        // Major years
        state.majorYears = response.years.length > 0 ? response.years.map(y => y.year) : [];

        // Dashboard data
        const relatedMajors = [];
        for (const relatedMajor of response.relatedMajors) {
            relatedMajors.push({
                nameEnglish: relatedMajor.programLongName,
                nameSpanish: relatedMajor.programLongNameSpanish,
                id: relatedMajor.programId
            })        
        }
        relatedMajors.sort((a, b) => (a.nameEnglish > b.nameEnglish) ? 1 : -1);
        const degreeLevelWages = response.degreeLevelWages.length > 0 ? response.degreeLevelWages : [];
        const relatedMajorWages = response.relatedMajorWages.length > 0 ? response.relatedMajorWages : [];
        const degreeLevelData = response.degreeLevelData.length > 0 ? response.degreeLevelData : [];

        state.dashboardData.push({
            majorId,
            relatedMajors,
            degreeLevelWages,
            relatedMajorWages,
            degreeLevelData
        });        
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
};
