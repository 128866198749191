<template>
    <SelectPageTemplate ref="selectPage"
                        :bannerHeaderText="selectCareerPage.header"
                        :bannerDescriptionText="selectCareerPage.description"
                        :buttonText="selectCareerPage.viewDashboardButton"
                        :dashboardPage="`/major-dashboard/${selectedMajorId}`"                        
                        :isButtonDisabled="isButtonDisabled"
                        :isLoading="isLoading"
                        :loadingText="loadingText">

        <div slot="select-page-content">
            <label for="select-career-area-dropdown"
                   class="dropdown-label"
                   v-html="selectCareerPage.dropdownLabels.careerArea" />
            <BaseDropDown id="select-career-area-dropdown"
                          class="dropdown"
                          :ariaLabel="selectCareerPage.dropdownLabels.careerArea"
                          :options="selectCareerAreaDropdownValues"
                          :noOptionsText="dropdownNoOptions"
                          v-model="selectedCareerArea" />

            <label for="select-career-dropdown"
                   :class="isCareersDropDownDisabled ? 'dropdown-label disabled' : 'dropdown-label enabled'"
                   v-html="selectCareerPage.dropdownLabels.career" />
            <BaseDropDown ref="selectCareerDropdown"
                          id="select-career-dropdown"
                          class="dropdown"
                          :ariaLabel="selectCareerPage.dropdownLabels.career"
                          :options="selectCareerDropdownValues"
                          :noOptionsText="dropdownNoOptions"
                          :disabled="isCareersDropDownDisabled"
                          v-model="selectedCareer" />

            <label for="select-major-dropdown"
                   :class="isCareerMajorsDropDownDisabled ? 'dropdown-label disabled' : 'dropdown-label enabled'"
                   v-html="selectCareerPage.dropdownLabels.major" />
            <BaseDropDown ref="selectMajorDropdown"
                          id="select-major-dropdown"
                          class="dropdown"
                          :ariaLabel="selectCareerPage.dropdownLabels.major"
                          :options="selectCareerMajorDropdownValues"
                          :noOptionsText="dropdownNoOptions"
                          :disabled="isCareerMajorsDropDownDisabled"
                          v-model="selectedMajor"
                          @input="handleDropdownChange()" />
        </div>

    </SelectPageTemplate>
</template>

<script>
    import SelectPageTemplate from '@/components/PageTemplates/SelectPageTemplate.vue';
    import BaseDropDown from '@/components/BaseDropDown.vue';

    export default {
        name: 'SelectCareerPage',
        components: {
            SelectPageTemplate,
            BaseDropDown
        },
        computed: {
            selectCareerPage() {
                return this.$store.getters.selectCareerPageLocale;
            },
            selectCareerAreaDropdownValues() {
                return this.$store.getters.careerAreaNamesLocale;
            },
            selectCareerDropdownValues() {
                if (this.selectedCareerArea) {
                    return this.$store.getters.careerNamesLocale(this.selectedCareerArea.value);
                }
            },
            selectCareerMajorDropdownValues() {
                if (this.selectedCareer) {
                    return this.$store.getters.careerProgramNamesLocale(this.selectedCareer.value);
                }
            },
            dropdownNoOptions() {
                return this.$store.getters.dropdownLocale.noOptions;
            },
            selectedMajorId() {
                return this.selectedMajor
                    ? this.selectedMajor.value
                    : 0;
            },
            isCareersDropDownDisabled() {
                return !this.selectedCareerArea
                    ? true
                    : false;
            },
            isCareerMajorsDropDownDisabled() {
                return !this.selectedCareer
                    ? true
                    : false;
            },
            isButtonDisabled() {
                return !this.selectedMajor
                    ? true
                    : false;
            },
            loadingText() {
                return this.$store.getters.loadingIndicatorLocale.loadingCareers;
            }
        },
        data() {
            return {
                isLoading: false,
                hasLocaleChanged: false,
                selectedCareerArea: null,
                selectedCareer: null,
                selectedMajor: null,
                careers: []
            }
        },
        async created() {
            try {
                const careers = this.$store.getters.careers;
                const majors = this.$store.getters.majors;
                this.$store.commit('SET_PREVIOUS_SELECT_PAGE', 'select-career');

                if (careers.length > 0) {
                    this.careers = careers;
                    return;
                }

                if (careers.length === 0 && majors.length === 0) {
                    this.isLoading = true;
                    await this.$store.dispatch('getCareersAsync');
                    await this.$store.dispatch('getMajorsAsync');
                    this.careers = this.$store.getters.careers;
                    this.isLoading = false;
                    return;
                }

                this.isLoading = true;
                await this.$store.dispatch('getCareersAsync');
                this.careers = this.$store.getters.careers;
                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;
                this.$router.push('/error');
            }
        },
        watch: {
            selectCareerPage() {
                this.hasLocaleChanged = true;
                if (this.selectedCareerArea) {
                    this.selectedCareerArea = {
                        label: this.$store.getters.selectedCareerAreaLocale(this.selectedCareerArea.value),
                        value: this.selectedCareerArea.value
                    };
                }

                if (this.selectedCareer) {
                    this.selectedCareer = {
                        label: this.$store.getters.selectedCareerLocale(this.selectedCareer.value),
                        value: this.selectedCareer.value
                    };
                }

                if (this.selectedMajor) {
                    this.selectedMajor = {
                        label: this.$store.getters.selectedCareerProgramLocale(this.selectedMajor.value),
                        value: this.selectedMajor.value
                    };
                }                               
            },
            selectedCareerArea(newValue, oldValue) {
                // Dropdown cleared
                if (newValue === null) {
                    this.selectedCareer = null;
                    this.hasLocaleChanged = false;
                    return;
                }

                // Dropdown value changed when not a locale switch
                if (!this.hasLocaleChanged && newValue !== oldValue) {
                    this.selectedCareer = null;
                    this.$nextTick(() => this.$refs.selectCareerDropdown.$refs.dropdown.$refs.search.focus());
                    return;
                }
            },
            selectedCareer(newValue, oldValue) {
                // Dropdown cleared
                if (newValue === null) {
                    this.selectedMajor = null;
                    this.hasLocaleChanged = false;
                    return;
                }

                // Dropdown value changed when not a locale switch
                if (!this.hasLocaleChanged && newValue !== oldValue) {
                    this.selectedMajor = null;
                    this.hasLocaleChanged = false;
                    this.$nextTick(() => this.$refs.selectMajorDropdown.$refs.dropdown.$refs.search.focus());
                    return;
                }

                this.hasLocaleChanged = false;
            }
        },
        methods: {
            handleDropdownChange() {
                this.$nextTick(() => this.$refs.selectPage.$refs.viewDashboardButton.$el.focus());
            }
        }
    }
</script>

<style scoped>
    .dropdown-label {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 16px;
        color: rgba(51, 51, 51, 1);
    }
    .dropdown {
        margin-top: 8px;
        margin-bottom: 20px;
        width: 100%
    }
</style>