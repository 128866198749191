<template>
    <div class="tc-container">
        <div class="tc-errorpage-container">
            <div class="errorpage-content-nav-container">
                <h1 class="errorpage-header" v-html="errorPage.header" />
                <p class="errorpage-description" v-html="errorPage.description" />
                <router-link class="router-link errorpage-nav-button" to="/">
                    <div class="errorpage-nav-button-text">
                        <span v-html="errorPage.navButtons.homePage.text" />
                    </div>
                </router-link>
                <router-link class="router-link errorpage-nav-button" to="/select-school">
                    <div class="errorpage-nav-button-text">
                        <span v-html="errorPage.navButtons.searchSchools.text" />
                    </div>
                </router-link>
                <router-link class="router-link errorpage-nav-button" to="/select-major">
                    <div class="errorpage-nav-button-text">
                        <span v-html="errorPage.navButtons.searchMajors.text" />
                    </div>
                </router-link>
                <router-link class="router-link errorpage-nav-button" to="/select-career">
                    <div class="errorpage-nav-button-text">
                        <span v-html="errorPage.navButtons.searchCareers.text" />
                    </div>
                </router-link>                
            </div>
            <div class="errorpage-content-image-container">
                <img class="errorpage-image" 
                     src="../assets/images/error-page-binoculars.png" 
                     :alt="errorPage.imageAltText" />
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ErrorPage',
        computed: {
            errorPage() {
                return this.$store.getters.errorPageLocale;
            }
        },
    }
</script>

<style scoped>
    .tc-errorpage-container {
        display: flex;
        margin-top: 50px;
        margin-bottom: 120px;
    }
    .errorpage-content-nav-container {
        display: flex;
        flex-direction: column;
        max-width: 636px;
    }
    .errorpage-header {
        font-family: 'Source Sans Pro Bold', sans-serif;
        font-weight: 700;
        font-size: 48px;
        line-height: 52px;
        color: #0C154A;
        margin-bottom: 20px;
    }
    .errorpage-description {
        font-family: 'Source Sans Pro Regular', sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #141417;
    }
    .errorpage-nav-button {
        font-family: 'Source Sans Pro SemiBold', 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif;
        font-size: 16px;
        background-color: #4C6CE3;
        color: #FFFFFF;
        cursor: pointer;
        border-radius: 8px;
        border: none;
        width: fit-content;
        margin-top: 25px;
        padding: 12px 16px;
        text-align: left;
    }
    .errorpage-nav-button-text {
        padding-right: 20px;
        background: url('../assets/images/icons/right-caret.svg') no-repeat;
        background-position: right;
        background-size: 8px;
    }
    .errorpage-nav-button:hover {
        background-color: #2D4DC4;
    }
    .errorpage-content-image-container {
        max-width: 572px;
    }
    .errorpage-image {
        height: 380px;
        justify-self: right;
    }

    /* Media Queries */

    /* Tablets */
    @media (max-width: 985px) {
        .errorpage-image {
            height: 220px;
        }
    }

    /* Mobile */
    @media (max-width: 740px) {
        .tc-errorpage-container {
            flex-direction: column-reverse;
            margin-top: 10px;
            margin-bottom: 16px;
        }
        .errorpage-header {
            font-family: 'Source Sans Pro Bold', sans-serif;
            font-weight: 700;
            font-size: 26px;
            line-height: 30px;
            color: #0C154A;
            margin-bottom: 20px;
        }
        .errorpage-description {
            font-family: 'Source Sans Pro Regular', sans-serif;
            font-size: 14px;
            line-height: 20px;
            color: #141417;
            margin-bottom: 14px;
        }
        .errorpage-content-image-container {
            margin: auto;
        }
        .errorpage-image {
            height: 160px;
        }
        .errorpage-nav-button {
            margin-top: 10px;
            font-size: 14px;
        }
    }
</style>