const state = () => {
    return {
        englishLocale: {
            ariaLabel: "Top",
            text: "Top",
        },
        spanishLocale: {
            ariaLabel: "Subir al Inicio",
            text: "Subir al Inicio",
        }
    }
}

const getters = {
    autoScrollButtonLocale: (state, rootGetters) => {
        return rootGetters.useSpanishLocale
            ? state.spanishLocale
            : state.englishLocale;
    },
};

const actions = {};

const mutations = {};

export default {
    state,
    getters,
    actions,
    mutations,
};
