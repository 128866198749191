import axios from 'axios';

class HttpClient {
    constructor() {
        let httpClient = axios.create({
            baseURL: process.env.VUE_APP_BASE_SERVICE_URL,
            headers: { 'Accept': 'application/json' }
        });
        httpClient.interceptors.request.use(this.handleRequestConfig, this.handleRequestError);
        httpClient.interceptors.response.use(this.handleResponseSuccess, this.handleResponseError);
        this.httpClient = httpClient;
    }

    handleRequestConfig(config) {
        config.timeout = 30000; // ms
        return config;
    }

    handleRequestError(error) {
        throw error;
    }

    handleResponseSuccess(response) {
        return response;
    }

    handleResponseError(error) {
        if (error.response.status !== 200 || error.response.status !== 201) {
            throw error;
        }
    }

    async getAsync(path) {
        return await this.httpClient.get(path);
    }
}

export default new HttpClient;