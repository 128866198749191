<template>
    <DashboardTemplate :dashboardHeaderText="major"
                       :isLoading="isLoading"
                       @updateReport="handleUpdateReportAsync()"
                       @download="handleDownload()">
        <!-- Banner Dropdowns -->
        <div slot="dashboard-banner-dropdowns" class="banner-dropdown-container">
            <div class="dashboard-dropdown-group">
                <label for="major-dropdown"
                       class="dashboard-dropdown-label"
                       v-html="majorDashboard.dashboardBanner.majorDropdownLabel" />

                <BaseDropDown id="major-dropdown"
                              class="dashboard-dropdown"
                              :ariaLabel="majorDashboard.dashboardBanner.majorDropdownLabel"
                              :options="majorsDropdownValues"
                              :noOptionsText="dropdownNoOptions"
                              :clearable=false
                              v-model="selectedMajor" />
            </div>
            <div class="dashboard-dropdown-group">
                <label for="major-year-dropdown"
                       class="dashboard-dropdown-label"
                       v-html="majorDashboard.dashboardBanner.majorYearDropdownLabel" />

                <BaseDropDown id="major-year-dropdown"
                              class="dashboard-dropdown major-years"
                              :ariaLabel="majorDashboard.dashboardBanner.majorYearDropdownLabel"
                              :options="majorYearsDropdownValues"
                              :noOptionsText="dropdownNoOptions"
                              :optionsHeader="dropdownOptionsHeader"
                              :clearable=false
                              v-model="selectedMajorYear" />
            </div>
        </div>
        <!-- Widgets -->
        <div ref="dashboardContent" slot="dashboard-content" class="dashboard-widgets-container">
            <DescriptionWidget :headerText="majorDashboard.widgetHeaders.majorDescription"
                               :content="dashboardData.description" />

            <LinksWidget :headerText="majorDashboard.widgetHeaders.relatedMajors"
                         :links="dashboardData.relatedMajors"
                         :externalLinks=false />

            <LinksWidget :headerText="majorDashboard.widgetHeaders.relatedCareers"
                         :links="dashboardData.relatedCareers"
                         :externalLinks=true />

            <DatasetWidget :selectedDashboardItem="major"
                           :headerText="widgetFourHeader"
                           :isToggleable=true
                           tableId="major-dashboard-widget-4"
                           :dataset=dashboardData.degreeLevelWages />

            <DatasetWidget :selectedDashboardItem="major"
                           :headerText="widgetFiveHeader"
                           :isToggleable=true
                           tableId="major-dashboard-widget-5"
                           :dataset=dashboardData.relatedMajorWages />

            <DatasetWidget :selectedDashboardItem="major"
                           :headerText="widgetSixHeader"
                           :isFilterable=true
                           tableId="major-dashboard-widget-6"
                           :filterSearchInputPlaceholder="filterSearchInputPlaceholder"
                           :filterDropdownLabel="widgetSixFilterLabel"
                           :filterDropdownAriaLabel="widgetSixFilterLabel"
                           :filterDropdownValues="widgetSixFilterDropdown.options"
                           :filterDropdownSelectedValue="widgetSixSelectedDegreeLevelFilter"
                           :dataset="degreeLevelFilteredData ? degreeLevelFilteredData : dashboardData.degreeLevelData"
                           @filterChange="handleFilterChange($event)"
                           @inputChange="handleInputChange($event)" />
        </div>
    </DashboardTemplate>
</template>

<script>
    /* eslint-disable */
    import DashboardTemplate from '@/components/PageTemplates/DashboardTemplate.vue';
    import BaseDropDown from '@/components/BaseDropDown.vue';
    import DescriptionWidget from '@/components/Widgets/DescriptionWidget.vue';
    import LinksWidget from '@/components/Widgets/LinksWidget.vue';
    import DatasetWidget from '@/components/Widgets/DatasetWidget.vue';
    import datasetMapperMixin from '@/mixins/datasetMapperMixin';
    import pdfGeneratorMixin from '@/mixins/pdfGeneratorMixin';

    export default {
        name: 'MajorDashboard',
        props: ['id'],
        mixins: [datasetMapperMixin, pdfGeneratorMixin],
        components: {
            DashboardTemplate,
            BaseDropDown,
            DescriptionWidget,
            LinksWidget,
            DatasetWidget
        },
        computed: {
            majorDashboard() {
                return this.$store.getters.majorDashboardLocale;
            },
            majorsDropdownValues() {
                return this.$store.getters.majorNamesLocale;
            },
            majorYearsDropdownValues() {
                return this.years;
            },
            dropdownNoOptions() {
                return this.$store.getters.dropdownLocale.noOptions;
            },
            dropdownOptionsHeader() {
                return this.$store.getters.dropdownLocale.historicalDataLabel
            },
            widgetFourHeader() {
                return this.majorYear < this.years[0]
                    ? this.majorDashboard.widgetHeaders.medianHistoricalWages + ' - ' + this.majorYear + ' ' + this.majorDashboard.widgetHeaders.graduates + ' - ' + this.major
                    : this.majorDashboard.widgetHeaders.medianFirstYearWages + ' - ' + this.years[0] + ' ' + this.majorDashboard.widgetHeaders.graduates + ' - ' + this.major;
            },
            widgetFiveHeader() {
                return this.majorDashboard.widgetHeaders.compareMedianFirstYearWages + ' - ' + this.years[0] + ' ' + this.majorDashboard.widgetHeaders.graduates + ' - ' + this.major;
            },
            widgetSixHeader() {
                return this.major + ', ' + this.majorYear + ' ' + this.majorDashboard.widgetHeaders.graduates + ' - ' + this.widgetSixSelectedDegreeLevelFilter.label;
            },
            filterSearchInputPlaceholder() {
                return this.$store.getters.majorDashboardLocale.filterSearchInput.placeholder;
            },
            widgetSixFilterDropdown() {
                return {
                    label: this.$store.getters.majorDashboardLocale.degreeLevelFilterDropdown.allDegreeLevels,
                    options: this.widgetSixFilterDropdownValues
                }
            },
            widgetSixFilterLabel() {
                return this.$store.getters.majorDashboardLocale.degreeLevelFilterDropdown.label
            }
        },
        data() {
            return {
                isLoading: false,
                selectedMajor: {
                    label: null,
                    value: null
                },
                selectedMajorYear: null,
                majorId: 0,
                major: '',
                majorYear: null,
                years: [],
                widgetSixFilterDropdownValues: [],
                widgetSixSelectedDegreeLevelFilter: {
                    label: this.$store.getters.majorDashboardLocale.degreeLevelFilterDropdown.allDegreeLevels,
                    value: 9 // all degree levels
                },
                dashboardData: {
                    description: '',
                    relatedMajors: [],
                    relatedCareers: [],
                    degreeLevelWages: {},
                    relatedMajorWages: {},
                    degreeLevelData: {}
                },
                degreeLevelFilteredData: null,
                windowWidth: window.innerWidth
            }
        },
        async created() {
            try {
                this.majorId = parseInt(this.id);
                const localMajor = this.$store.getters.majorDashboardDataByMajorId(this.majorId);               

                // Check if the selected major exists already in state; if not, 
                // get the dashboard data and build the widgets
                if (localMajor) {
                    this.years = this.$store.getters.majorDashboardMajorYears;
                    this.setDashboardSelectedMajorAndSelectedYear(this.majorId, this.years[0]);
                    this.buildDashboardWidgets();
                }
                else {
                    this.isLoading = true;

                    const majors = this.$store.getters.majors;
                    if (majors.length < 1) {
                        await this.$store.dispatch('getMajorsAsync');
                    }
                    await this.getDashboardDataAsync(this.majorId);
                    this.years = this.$store.getters.majorDashboardMajorYears;
                    this.setDashboardSelectedMajorAndSelectedYear(this.majorId, this.years[0]);
                    this.buildDashboardWidgets();

                    this.isLoading = false;
                }                
            } catch (error) {
                this.isLoading = false;
                this.$router.push('/error');
            }
        },
        mounted() {
            this.$nextTick(() => {
                // Add resize event listener for dynamic table styling
                window.addEventListener('resize', () => this.windowWidth = window.innerWidth);
            });
        },
        beforeDestroy() {
            window.removeEventListener('resize', () => this.windowWidth = window.innerWidth);
        },
        watch: {
            majorDashboard() {
                this.selectedMajor = {
                    label: this.$store.getters.majorDashboardMajorNameById(this.selectedMajor.value),
                    value: this.selectedMajor.value
                };
                this.major = this.$store.getters.majorDashboardMajorNameById(this.majorId);
                const selectedDegreeLevelFilter = this.$store.getters.majorDashboardDegreeLevelDataFilters(this.majorId, parseInt(this.selectedMajorYear)).find(d => d.value === this.widgetSixSelectedDegreeLevelFilter.value);
                this.widgetSixSelectedDegreeLevelFilter = {
                    label: selectedDegreeLevelFilter.value === 9 ? this.majorDashboard.degreeLevelFilterDropdown.allDegreeLevels : selectedDegreeLevelFilter.label,
                    value: selectedDegreeLevelFilter.value
                };
                this.buildDashboardWidgets();
            },
            async id(newValue, oldValue) {
                // When a user clicks on a related major link
                if (oldValue && newValue !== oldValue) {
                    try {
                        this.majorId = parseInt(newValue);
                        const localMajor = this.$store.getters.majorDashboardDataByMajorId(this.majorId);

                        // Check if the selected major exists already in state; if not, 
                        // get the dashboard data and build the widgets
                        if (localMajor) {
                            this.years = this.$store.getters.majorDashboardMajorYears;
                            this.setDashboardSelectedMajorAndSelectedYear(this.majorId, this.years[0]);
                            this.buildDashboardWidgets();
                        }
                        else {
                            this.isLoading = true;

                            await this.getDashboardDataAsync(this.majorId);
                            this.years = this.$store.getters.majorDashboardMajorYears;
                            this.setDashboardSelectedMajorAndSelectedYear(this.majorId, this.years[0]);
                            this.buildDashboardWidgets();

                            this.isLoading = false;
                        }

                        this.resetWidgetFilterDropdown();
                    } catch (error) {
                        this.isLoading = false;
                        this.$router.push('/error');
                    } finally {
                        this.degreeLevelFilteredData = null;
                    }
                }
            },
            windowWidth() {
                this.buildDegreeLevelWagesWidget();
                this.buildRelatedMajorsWidget();
                this.buildRelatedMajorWagesWidget();
                this.buildDegreeLevelDataWidget();
            }
        },
        methods: {
            async getDashboardDataAsync(majorId) {               
                await this.$store.dispatch('getMajorDashboardDataAsync', majorId);             
            },
            async handleUpdateReportAsync() {
                if (this.selectedMajor.label !== this.major) {
                    this.$router.push({ path: `/major-dashboard/${parseInt(this.selectedMajor.value)}` });
                    return;
                }

                try {
                    this.resetWidgetFilterDropdown();
                    this.majorId = parseInt(this.selectedMajor.value);
                    const localMajor = this.$store.getters.majorDashboardDataByMajorId(this.majorId);

                    this.isLoading = true;

                    // Check if the selected major exists already in state; if not,
                    // get the dashboard data and build the widgets
                    if (localMajor) {
                        this.setDashboardSelectedMajorAndSelectedYear(this.majorId, this.selectedMajorYear);
                        this.buildDashboardWidgets();
                    }
                    else {
                        await this.getDashboardDataAsync(this.majorId);
                        this.setDashboardSelectedMajorAndSelectedYear(this.majorId, this.selectedMajorYear);
                        this.buildDashboardWidgets();
                    }

                    this.isLoading = false;
                } catch (error) {
                    this.isLoading = false;
                    this.$router.push('/error');
                } finally {
                    this.degreeLevelFilteredData = null;
                }
            },
            handleDownload() {
                try {
                    const fileName = this.major;
                    const chartElement = this.$refs.dashboardContent;
                    const widgetTables = [];
                    widgetTables.push(this.dashboardData.degreeLevelWages.table);
                    widgetTables.push(this.dashboardData.relatedMajorWages.table);
                    widgetTables.push(this.dashboardData.degreeLevelData.table);

                    this.generateDashboardPdf(chartElement, widgetTables, fileName);
                } catch (error) {
                    const errorMessage = this.$store.getters.getters.useSpanishLocale ? 'Lo sentimos, se produjo un error al descargar este panel en PDF. Int\u00E9ntalo de nuevo.' : 'Sorry, something went wrong downloading this dashboard to PDF. Please try again.';
                    alert(errorMessage);
                }
            },
            handleFilterChange(event) {
                this.widgetSixSelectedDegreeLevelFilter = { label: event.label, value: event.value };
                this.buildDegreeLevelDataWidget();
            },
            handleInputChange(event) {
                const searchText = event.target.value;
                if (searchText) {
                    const filteredData = this.dashboardData.degreeLevelData.table.tableData.filter(d => d.School.toLowerCase().includes(searchText.toLowerCase()));
                    if (filteredData.length > 0) {
                        this.degreeLevelFilteredData = {
                            table: {
                                columns: this.dashboardData.degreeLevelData.table.columns,
                                tableData: filteredData
                            }
                        };
                    }
                    else {
                        this.degreeLevelFilteredData = {
                            table: {
                                columns: null,
                                tableData: ['No results']
                            }
                        }
                    }                    

                    return;
                }

                this.degreeLevelFilteredData = null;
            },
            buildDashboardWidgets() {
                this.buildDescriptionWidget();
                this.buildRelatedMajorsWidget();
                this.buildRelatedCareersWidget();
                this.buildDegreeLevelWagesWidget();
                this.buildRelatedMajorWagesWidget();
                this.buildDegreeLevelDataWidget();
            },
            buildDescriptionWidget() {
                this.dashboardData.description = this.$store.getters.majorDashboardMajorDescription(this.majorId);                
            },
            buildRelatedMajorsWidget() {
                let relatedMajorsLinks = [];
                const relatedMajors = this.$store.getters.majorDashboardRelatedMajors(this.majorId);
                for (var i = 0; i < relatedMajors.length; i++) {
                    const url = `/major-dashboard/${relatedMajors[i].id}`;
                    const relatedMajor = {
                        name: relatedMajors[i].name,
                        id: relatedMajors[i].id,
                        url
                    };
                    relatedMajorsLinks.push(relatedMajor);
                }

                this.dashboardData.relatedMajors = relatedMajorsLinks;
            },
            buildRelatedCareersWidget() {
                let relatedCareersLinks = [];
                const relatedCareers = this.$store.getters.majorDashboardRelatedCareers(this.majorId);
                for (var i = 0; i < relatedCareers.length; i++) {
                    const url = `${this.$store.getters.careerLinkBaseUrl}${relatedCareers[i].id}`;
                    const relatedCareer = {
                        name: relatedCareers[i].name,
                        id: relatedCareers[i].id,
                        url
                    };
                    relatedCareersLinks.push(relatedCareer);
                }

                this.dashboardData.relatedCareers = relatedCareersLinks;
            },
            buildDegreeLevelWagesWidget() {
                const degreeLevelWages = this.$store.getters.majorDashboardDegreeLevelWages(this.majorId).filter(w => w.year === parseInt(this.selectedMajorYear));
                const filteredDegreeLevelWages = degreeLevelWages.filter(d => d.degreeLevelId !== null);                

                const columnTitles = this.$store.getters.majorDashboardLocale.widgetTableHeaders;

                let dataset = null;

                if (parseInt(this.selectedMajorYear) < parseInt(this.years[1])) {
                    let historicalWages = [];

                    for (var i = 0; i < degreeLevelWages.length; i++) {
                        const tempArray = new Array();
                        tempArray.push(degreeLevelWages[i].wagesYear1);
                        tempArray.push(degreeLevelWages[i].wagesYear3);
                        tempArray.push(degreeLevelWages[i].wagesYear5);
                        tempArray.push(degreeLevelWages[i].wagesYear8);
                        tempArray.push(degreeLevelWages[i].wagesYear10);
                        historicalWages.push(tempArray);
                    }

                    // Build historical chart dataset
                    const categories = {
                        categories: [
                            (parseInt(this.majorYear) + 1).toString(),
                            (parseInt(this.majorYear) + 3).toString(),
                            (parseInt(this.majorYear) + 5).toString(),
                            (parseInt(this.majorYear) + 8).toString(),
                            (parseInt(this.majorYear) + 10).toString(),
                        ],
                        labels: {
                            font: '14px Source Sans Pro, sans-serif'
                        }
                    };
                    const axis = [
                        {
                            name: 'historical degree level wages',
                            labels: {
                                format: '{0:C0}',
                                font: '14px Source Sans Pro, sans-serif'
                            },
                            min: 0
                        }
                    ];
                    let chartData = new Array(degreeLevelWages.length).fill({}).map((_, i) => ({
                        type: 'line',
                        name: degreeLevelWages[i]?.degreeLevelNameSpanish || degreeLevelWages[i]?.degreeLevelName,
                        data: new Array(1).fill().map((_, j) => (
                            historicalWages[i] ? historicalWages[i] : null
                        )).flat(),
                        axis: axis[0].name
                    }));

                    let allNull = true;
                    for (var j = 0; j < chartData.length; j++) {
                        const dataRow = chartData[j].data;
                        for (var k = 0; k < dataRow.length; k++) {
                            if (dataRow[k] !== null) {
                                allNull = false;
                            }
                        }
                    }

                    if (allNull) {
                        chartData = [];
                    }

                    // Build historical table dataset                    
                    const columns = [
                        { field: 'DegreeLevel', title: columnTitles.degreeLevel },
                        { field: 'FirstYearWages', title: (parseInt(this.majorYear) + 1) + ' ' + columnTitles.yearWages },
                        { field: 'ThirdYearWages', title: (parseInt(this.majorYear) + 3) + ' ' + columnTitles.yearWages },
                        { field: 'FifthYearWages', title: (parseInt(this.majorYear) + 5) + ' ' + columnTitles.yearWages },
                        { field: 'EigthYearWages', title: (parseInt(this.majorYear) + 8) + ' ' + columnTitles.yearWages },
                        { field: 'TenthYearWages', title: (parseInt(this.majorYear) + 10) + ' ' + columnTitles.yearWages }
                    ];
                    const tableData = new Array(degreeLevelWages.length).fill({}).map((_, i) => ({
                        DegreeLevel: degreeLevelWages[i]?.degreeLevelNameSpanish || degreeLevelWages[i]?.degreeLevelName,
                        FirstYearWages: this.mapDatasetValueToCurrency(degreeLevelWages[i]?.wagesYear1),
                        ThirdYearWages: this.mapDatasetValueToCurrency(degreeLevelWages[i]?.wagesYear3),
                        FifthYearWages: this.mapDatasetValueToCurrency(degreeLevelWages[i]?.wagesYear5),
                        EigthYearWages: this.mapDatasetValueToCurrency(degreeLevelWages[i]?.wagesYear8),
                        TenthYearWages: this.mapDatasetValueToCurrency(degreeLevelWages[i]?.wagesYear10)
                    }));

                    dataset = {
                        chart: {
                            categories,
                            axis,
                            chartData
                        },
                        table: {
                            columns,
                            tableData
                        }
                    };
                }
                else {
                    // Build chart dataset for recent year
                    const categories = {
                        categories: [
                            parseInt(this.majorYear) + 1
                        ],
                        labels: {
                            font: '14px Source Sans Pro, sans-serif'
                        }
                    };
                    const axis = [
                        {
                            name: 'recent degree level wages',
                            labels: {
                                format: '{0:C0}',
                                font: '14px Source Sans Pro, sans-serif'
                            },
                            min: 0
                        }
                    ];
                    let chartData = new Array(degreeLevelWages.length).fill({}).map((_, i) => ({
                        name: degreeLevelWages[i]?.degreeLevelNameSpanish || degreeLevelWages[i]?.degreeLevelName,
                        data: new Array(1).fill(degreeLevelWages[i]?.wagesYear1 ? degreeLevelWages[i].wagesYear1 : null),
                        axis: axis[0].name
                    }));

                    if (chartData[0].data[0] === null) {
                        chartData = [];
                    }

                    // Build table dataset for recent year
                    const columns = [
                        { field: 'DegreeLevel', title: columnTitles.degreeLevel },
                        { field: 'FirstYearWages', title: (parseInt(this.majorYear) + 1) + ' ' + columnTitles.yearWages }
                    ];
                    const tableData = new Array(degreeLevelWages.length).fill({}).map((_, i) => ({
                        DegreeLevel: degreeLevelWages[i]?.degreeLevelNameSpanish || degreeLevelWages[i]?.degreeLevelName,
                        FirstYearWages: this.mapDatasetValueToCurrency(degreeLevelWages[i]?.wagesYear1)
                    }));

                    dataset = {
                        chart: {
                            categories,
                            axis,
                            chartData
                        },
                        table: {
                            columns,
                            tableData
                        }
                    };
                }

                if (filteredDegreeLevelWages.length === 0) {
                    dataset.chart.chartData = [];
                }

                this.dashboardData.degreeLevelWages = dataset;
            },
            buildRelatedMajorWagesWidget() {
                const recentMajorWages = this.$store.getters.majorDashboardRelatedMajorWages(this.majorId).filter(w => w.year === parseInt(this.years[0]) && w.wagesYear1 !== null);                
                const columnTitles = this.$store.getters.majorDashboardLocale.widgetTableHeaders;

                // Build chart dataset for recent year
                const categories = {
                    categories: new Array(recentMajorWages.length).fill().map((_, i) => (
                        this.windowWidth <= 1080
                            ? recentMajorWages[i]?.programLongNameSpanish?.replace(/\//g, '\/ ').split(' ').join('\n') || recentMajorWages[i]?.programLongName?.replace(/\//g, '\/ ').split(' ').join('\n')
                            : recentMajorWages[i]?.programLongNameSpanish?.split(/([a-zA-Z\u00C0-\u00FF]+\s[a-zA-Z\u00C0-\u00FF]+\/)|([a-zA-Z\u00C0-\u00FF]+[\s*|\/*][a-zA-Z\u00C0-\u00FF]+)/g).join('\n') || recentMajorWages[i]?.programLongName?.split(/(\w+\s\w+\/)|(\w+[\s*|\/*]\w+)/g).join('\n')
                    )),
                    labels: {
                        font: '14px Source Sans Pro, sans-serif'
                    }
                };
                const axis = [
                    {
                        name: 'related major wages',
                        labels: {
                            format: '{0:C0}',
                            font: '14px Source Sans Pro, sans-serif'
                        },
                        min: 0
                    }
                ];
                const chartData = new Array(1).fill({}).map((_, i) => ({
                    name: (parseInt(this.years[0]) + 1) + ' ' + columnTitles.yearWages,
                    data: new Array(recentMajorWages.length).fill().map((_, j) => (recentMajorWages[j]?.wagesYear1)),
                    axis: axis[0].name
                }));

                // Build table dataset for recent year
                const columns = [
                    { field: 'Majors', title: columnTitles.majors },
                    { field: 'FirstYearWages', title: (parseInt(this.years[0]) + 1) + ' ' + columnTitles.yearWages }
                ];
                const tableData = new Array(recentMajorWages.length).fill({}).map((_, i) => ({
                    Majors: recentMajorWages[i]?.programLongNameSpanish || recentMajorWages[i]?.programLongName,
                    FirstYearWages: this.mapDatasetValueToCurrency(recentMajorWages[i]?.wagesYear1)
                }));

                let dataset = {
                    chart: {
                        categories,
                        axis,
                        chartData
                    },
                    table: {
                        columns,
                        tableData
                    }
                };

                this.dashboardData.relatedMajorWages = dataset;
            },
            buildDegreeLevelDataWidget() {
                const recentDegreeLevelData = this.$store.getters.majorDashboardDegreeLevelData(this.majorId).filter(d => d.year === parseInt(this.years[0]) && d.degreeLevelId === this.widgetSixSelectedDegreeLevelFilter.value);
                const columnTitles = this.$store.getters.majorDashboardLocale.widgetTableHeaders;

                // Get filters
                const filters = this.$store.getters.majorDashboardDegreeLevelDataFilters(this.majorId, parseInt(this.selectedMajorYear));
                const degreeLevels = [];
                const degreeLevelsMap = new Map();

                // Get distinct degree level names
                for (const filter of filters) {
                    if (!degreeLevelsMap.has(filter.label)) {
                        degreeLevelsMap.set(filter.label, true);
                        degreeLevels.push(filter);
                    }
                }

                this.widgetSixFilterDropdownValues = degreeLevels;
                this.widgetSixFilterDropdownValues[0].label = this.majorDashboard.degreeLevelFilterDropdown.allDegreeLevels;

                let dataset = null;

                if (parseInt(this.selectedMajorYear) < parseInt(this.years[0])) {
                    const historicalMajorWages = this.$store.getters.majorDashboardDegreeLevelData(this.majorId).filter(d => d.year === parseInt(this.selectedMajorYear) && d.degreeLevelId === this.widgetSixSelectedDegreeLevelFilter.value);

                    // Build historical table dataset
                    const columns = [
                        { field: 'School', title: columnTitles.schools },
                        { field: 'NumberOfGrads', title: columnTitles.numberOfGrads },
                        { field: 'AverageTuition', title: columnTitles.averageTuition },
                        { field: 'AverageTimeToDegree', title: columnTitles.averageTimeToDegree },
                        { field: 'PercentGradsWithLoans', title: columnTitles.percentGradsWithLoans },
                        { field: 'AverageLoanAtGraduation', title: columnTitles.averageLoanAtGraduation },
                        { field: 'MedianYearOneWages', title: (parseInt(this.majorYear) + 1) + ' ' + columnTitles.medianWages },                        
                        { field: 'MedianYearThreeWages', title: (parseInt(this.majorYear) + 3) + ' ' + columnTitles.medianWages },                        
                        { field: 'MedianYearFiveWages', title: (parseInt(this.majorYear) + 5) + ' ' + columnTitles.medianWages },                        
                        { field: 'MedianYearEightWages', title: (parseInt(this.majorYear) + 8) + ' ' + columnTitles.medianWages },                        
                        { field: 'MedianYearTenWages', title: (parseInt(this.majorYear) + 10) + ' ' + columnTitles.medianWages },                        
                        { field: 'LoanAsPercentOfYearOneWages', title: columnTitles.loanAsPercentOfYearOneWages }
                    ];
                    const tableData = new Array(historicalMajorWages.length).fill({}).map((_, i) => ({
                        School: `<a style='text-decoration: underline; color: #4c6ce3;' href='/school-dashboard/${historicalMajorWages[i]?.schoolProfileId}'>${historicalMajorWages[i]?.instituteLegalName}</a>`,
                        NumberOfGrads: this.mapDatasetValue(historicalMajorWages[i]?.numberOfGraduates),
                        AverageTuition: this.mapDatasetValueToCurrency(historicalMajorWages[i].tuitionFee),
                        AverageTimeToDegree: this.mapDatasetValue(historicalMajorWages[i].degreeTimeFinish),
                        PercentGradsWithLoans: this.mapDatasetValueToPercent(historicalMajorWages[i]?.loanPercent),
                        AverageLoanAtGraduation: this.mapDatasetValueToCurrency(historicalMajorWages[i]?.loanAmount),
                        MedianYearOneWages: this.mapDatasetValueToCurrency(historicalMajorWages[i]?.wagesYear1),
                        MedianYearThreeWages: this.mapDatasetValueToCurrency(historicalMajorWages[i]?.wagesYear3),
                        MedianYearFiveWages: this.mapDatasetValueToCurrency(historicalMajorWages[i]?.wagesYear5),
                        MedianYearEightWages: this.mapDatasetValueToCurrency(historicalMajorWages[i]?.wagesYear8),
                        MedianYearTenWages: this.mapDatasetValueToCurrency(historicalMajorWages[i]?.wagesYear10),
                        LoanAsPercentOfYearOneWages: this.mapDatasetValueToPercent(historicalMajorWages[i]?.loanPercentWagesYear1 ? Math.trunc(historicalMajorWages[i].loanPercentWagesYear1 * 100) : null)
                    }));

                    dataset = {
                        table: {
                            columns,
                            tableData
                        }
                    };
                }
                else {
                    // Build table dataset for recent year
                    const columns = [
                        { field: 'School', title: columnTitles.schools },
                        { field: 'NumberOfGrads', title: columnTitles.numberOfGrads },
                        { field: 'AverageTuition', title: columnTitles.averageTuition },
                        { field: 'AverageTimeToDegree', title: columnTitles.averageTimeToDegree },
                        { field: 'PercentGradsWithLoans', title: columnTitles.percentGradsWithLoans },
                        { field: 'AverageLoanAtGraduation', title: columnTitles.averageLoanAtGraduation },
                        { field: 'MedianYearOneWages', title: (parseInt(this.majorYear) + 1) + ' ' + columnTitles.medianWages },
                        { field: 'LoanAsPercentOfYearOneWages', title: columnTitles.loanAsPercentOfYearOneWages }
                    ];
                    const tableData = new Array(recentDegreeLevelData.length).fill({}).map((_, i) => ({
                        School: `<a style='text-decoration: underline; color: #4c6ce3;' href='/school-dashboard/${recentDegreeLevelData[i]?.schoolProfileId}'>${recentDegreeLevelData[i]?.instituteLegalName}</a>`,
                        NumberOfGrads: this.mapDatasetValue(recentDegreeLevelData[i]?.numberOfGraduates),
                        AverageTuition: this.mapDatasetValueToCurrency(recentDegreeLevelData[i]?.tuitionFee),
                        AverageTimeToDegree: this.mapDatasetValue(recentDegreeLevelData[i]?.degreeTimeFinish),
                        PercentGradsWithLoans: this.mapDatasetValueToPercent(recentDegreeLevelData[i]?.loanPercent),
                        AverageLoanAtGraduation: this.mapDatasetValueToCurrency(recentDegreeLevelData[i]?.loanAmount),
                        MedianYearOneWages: this.mapDatasetValueToCurrency(recentDegreeLevelData[i]?.wagesYear1),
                        LoanAsPercentOfYearOneWages: this.mapDatasetValueToPercent(recentDegreeLevelData[i]?.loanPercentWagesYear1 ? Math.trunc(recentDegreeLevelData[i].loanPercentWagesYear1 * 100) : null)
                    }));

                    dataset = {
                        table: {
                            columns,
                            tableData
                        }
                    };
                }

                this.dashboardData.degreeLevelData = dataset;
            },
            setDashboardSelectedMajorAndSelectedYear(majorId, year) {
                const major = this.$store.getters.majorDashboardMajorNameById(parseInt(majorId));

                // Major and major year dropdown selected values
                this.selectedMajor = {
                    label: major,
                    value: majorId
                };
                this.selectedMajorYear = year;

                // Major and major year used across dashboard and widget headers
                this.major = major;
                this.majorYear = year;
            },
            resetWidgetFilterDropdown() {
                this.widgetSixSelectedDegreeLevelFilter = {
                    label: this.$store.getters.majorDashboardLocale.degreeLevelFilterDropdown.allDegreeLevels,
                    value: 9 // all degree levels
                };
            }
        }
    }
</script>

<style scoped>
    /* Banner */
    .banner-dropdown-container {
        display: flex;
        max-width: 420px;
    }
    .dashboard-dropdown-group {
        display: flex;
        flex-direction: column;
        padding-right: 12px;
    }
    .dashboard-dropdown-label {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 16px;
        color: rgba(51, 51, 51, 1);
    }
    .dashboard-dropdown {
        margin-top: 4px;
        width: 240px;
    }
    .major-years {
        width: 140px;
    }            
    /* Dashboard - Widgets */
    .dashboard-widgets-container {
        display: grid;
        grid-template-columns: repeat(6, 1fr);        
        grid-column-gap: 30px;
        grid-row-gap: 46px;
        margin-top: 42px;
    }
    .dashboard-widgets-container > :nth-child(1) {
        grid-column: 1 / span 2;
    }
    .dashboard-widgets-container > :nth-child(2) {
        grid-column: 3 / span 2;
    }
    .dashboard-widgets-container > :nth-child(3) {
        grid-column: 5 / span 2;
    }
    .dashboard-widgets-container > :nth-child(4) {
        grid-column: 1 / span 3;
    }
    .dashboard-widgets-container > :nth-child(5) {
        grid-column: 4 / span 3;
    }
    .dashboard-widgets-container > :nth-child(6) {
        grid-column: 1 / span 6;
    }

    /* Media Queries */

    /* Tablets and Under */
    @media (max-width: 768px) {
        .dashboard-widgets-container * {
            grid-column: 1 / span 6 !important;
        }
    }

    @media (max-width: 490px) {
        .dashboard-dropdown {
            width: 200px;
        }
        .major-years {
            width: 90px;
        }
    }
</style>