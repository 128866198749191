const state = () => {
    return {
        previousSelectPage: ''
    }
};

const getters = {
    previousSelectPage: (state) => {
        return state.previousSelectPage;
    },
};

const actions = {};

const mutations = {
    SET_PREVIOUS_SELECT_PAGE: (state, previousSelectPage) => state.previousSelectPage = previousSelectPage,
};

export default {
    state,
    getters,
    actions,
    mutations,
};
