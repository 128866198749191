import httpClient from "../../../../service/httpClient";

const state = () => {
    return {
        englishLocale: {
            dashboardBanner: {
                careerSchoolDropdownLabel: "Career School:",
                updateReportButton: {
                    text: "Update Report",
                    ariaLabel: "Update Report"
                }
            },
            dashboardHeader: {
                buttons: {
                    downloadButton: {
                        text: "Download",
                        ariaLabel: "Download Dashboard"
                    }
                }
            },
            widgetHeaders: {
                schoolProfile: "School Profile -",
                enrollmentAndCompletion: "Enrollment and Completion -",
                completionRateAndEmploymentRate: "Completion Rate and Employment Rate -",
                completionAndEmploymentOutcomes: "Completion and Employment Outcomes - Last Five Years",
                careerSchoolData: "Data"
            },
            widgetTableHeaders: {
                typeOfInstitution: "Type of Institution",
                multipleLocations: "Multiple Locations",
                numberOfEnrollees: "Number of Enrollees",
                numberOfCompleters: "Number of Completers",
                completionRate: "Completion Rate",
                employmentRateAst: "Employment Rate*",
                employmentRateTwoAst: "Employment Rate**",
                employmentRate: "Employment Rate",
                eligibleTrainingProvider: "Eligible Training Provider***",
                year: "Year",
                numOfEnrollees: "# of Enrollees",
                numOfCompleters: "# of Completers"
            },
            widgetAsterisks: {
                careerSchool: "* Career Schools are private for-profit institutions that provide degree, non-degree and vocational training.",
                employmentRateOne: "* Defined as percentage of completers employed within their field.",
                employmentRateTwo: "** Defined as percentage of completers employed within their field.",
                eligibleTrainingProvider: "*** Eligible Training Providers offer job training programs approved by the Texas Workforce Commission to provide Workforce Innovation & Opportunity Act-funded training services.",
                eligibleTrainingProviderMoreInfo: "More information about Eligible Training Providers",
                careerSchoolsMoreInfo: "More information about Career Schools"
            },
            schoolProfile: {
                yesOption: "Yes",
                noOption: "No"
            }
        },
        spanishLocale: {
            dashboardBanner: {
                careerSchoolDropdownLabel: "Escuela Profesional:",
                updateReportButton: {
                    text: "Actualizar Informe",
                    ariaLabel: "Actualizar Informe"
                }
            },
            dashboardHeader: {
                buttons: {
                    downloadButton: {
                        text: "Descargar",
                        ariaLabel: "Descargar Tablero"
                    }
                }
            },
            widgetHeaders: {
                schoolProfile: "Perfil Escolar -",
                enrollmentAndCompletion: "Inscripci\u00F3n y Finalizaci\u00F3n -",
                completionRateAndEmploymentRate: "Tasa de Finalizaci\u00F3n y Tasa de Empleo -",
                completionAndEmploymentOutcomes: "Resultados de Finalizaci\u00F3n y Empleo - \u00DAltimos Cinco A\u00F1os",
                careerSchoolData: "Datos"
            },
            widgetTableHeaders: {
                typeOfInstitution: "Tipo de Instituci\u00F3n",
                multipleLocations: "M\u00FAltiples Localidades",
                numberOfEnrollees: "N\u00FAmero de Inscritos",
                numberOfCompleters: "N\u00FAmero de Personas que Finalizaron",
                completionRate: "Tasa de Finalizaci\u00F3n",
                employmentRateAst: "Tasa de Empleo",
                employmentRateTwoAst: "Tasa de Empleo**",
                employmentRate: "Tasa de Empleo",
                eligibleTrainingProvider: "Proveedor de Entrenamiento Elegible***",
                year: "A\u00F1o",
                numOfEnrollees: "N\u00FAmero de Inscritos",
                numOfCompleters: "N\u00FAmero de Personas que Finalizaron"
            },
            widgetAsterisks: {
                careerSchool: "* Las Escuelas Profesionales son instituciones privadas con fines de lucro que proveen entrenamiento profesional, no profesional y vocacional.",
                employmentRateOne: "* Definido como el porcentage de finalistas empleados en su campo.",
                employmentRateTwo: "** Definido como el porcentage de finalistas empleados en su campo.",
                eligibleTrainingProvider: `*** Los proveedores de entrenamiento elegibles ofrecen programas de entrenamiento laboral aprobados 
                        por la Comisi\u00F3n de la Fuerza Laboral de Texas para proveer innovaci\u00F3n a la fuerza laboral y servicios de
                        entrenamiento financiados por la Ley de Oportunidades.`,
                eligibleTrainingProviderMoreInfo: "M\u00E1s informaci\u00F3n sobre Proveedores de Entrenamiento Elegibles",
                careerSchoolsMoreInfo: "M\u00E1s informaci\u00F3n sobre Escuelas Profesionales"
            },
            schoolProfile: {
                yesOption: "S\u00ED",
                noOption: "No"
            }
        },
        dashboardData: []
    }
};

const getters = {
    careerSchoolDashboardLocale: (state, rootGetters) => {
        return rootGetters.useSpanishLocale
            ? state.spanishLocale
            : state.englishLocale;
    },
    // Career school dropdown
    careerSchooolDashboardCareerSchools: (state, rootGetters) => {
        return rootGetters.schoolNamesCareerLocale;
    },
    // Dashboard career school name by school id
    careerSchooolDashboardCareerSchoolNameById: (state, rootGetters) => (schoolId) => {
        return rootGetters.useSpanishLocale
            ? rootGetters.schools.find(s => s.schoolProfileId === schoolId)?.instituteLegalNameSpanish
            : rootGetters.schools.find(s => s.schoolProfileId === schoolId)?.instituteLegalName;
    },
    // Dashboard data by career school id
    careerSchooolDashboardDataBySchoolId: (state) => (schoolId) => {
        return state.dashboardData.find(s => s.schoolId === schoolId);
    },
    // School profile
    careerSchoolDashboardSchoolProfile: (state) => (schoolId) => {
        return state.dashboardData.find(s => s.schoolId === schoolId)?.dataset[0];
    },
    // School profile - institution type
    careerSchoolDashboardSchoolProfileInstitutionType: (state, rootGetters) => (schoolId) => {
        return rootGetters.useSpanishLocale
            ? rootGetters.schools.find(s => s.schoolProfileId === schoolId)?.instituteTier.instituteTierNameSpanish
            : rootGetters.schools.find(s => s.schoolProfileId === schoolId)?.instituteTier.instituteTierName;
    },
    // School profile - multiple locations
    careerSchoolDashboardSchoolProfileMultipleLocations: (state, rootGetters) => (schoolId) => {
        return rootGetters.schools.find(s => s.schoolProfileId === schoolId)?.multipleRecordsLinked;
    },
    // School profile - eligible training provider
    careerSchoolDashboardSchoolProfileEligibleTrainingProvider: (state, rootGetters) => (schoolId) => {
        return rootGetters.schools.find(s => s.schoolProfileId === schoolId)?.etpLinked;
    },
    // Enrollment and completion
    careerSchoolDashboardEnrollmentAndCompletion: (state) => (schoolId) => {
        return state.dashboardData.find(s => s.schoolId === schoolId)?.dataset[0];
    },
    // Completion rate and employment rate
    careerSchoolDashboardCompletionRateAndEmploymentRate: (state) => (schoolId) => {
        return state.dashboardData.find(s => s.schoolId === schoolId)?.dataset[0];
    },
    // Completion and employment outcomes
    careerSchoolDashboardCompletionAndEmploymentOutcomes: (state) => (schoolId) => {
        return state.dashboardData.find(s => s.schoolId === schoolId)?.dataset.slice(0, 5);
    },
    // Career school data
    careerSchoolDashboardCareerSchoolData: (state) => (schoolId) => {
        return state.dashboardData.find(s => s.schoolId === schoolId)?.dataset;
    }
};

const actions = {
    async getCareerSchoolDashboardDataAsync({ commit }, schoolId) {
        // Get dashboard data
        const response = await httpClient.getAsync(`/CareerSchoolTrans/${schoolId}`);

        const commitData = { schoolId, response: response.data };

        commit("SET_CAREER_SCHOOL_DASHBOARD_DATA", commitData)
    }
};

const mutations = {
    SET_CAREER_SCHOOL_DASHBOARD_DATA: (state, { schoolId, response }) => {
        state.dashboardData.push({
            schoolId,
            dataset: response
        });
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
