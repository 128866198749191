<template>
    <div class="tc-select-page-banner">
        <div class="tc-container">
            <div class="banner-container">
                <h1 class="banner-header" v-html="headerText" />
                <p class="banner-description" v-html="descriptionText" />
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'SelectPageBanner',
        props: {
            headerText: { type: String, required: true },
            descriptionText: { type: String, required: true },
        },
    }
</script>

<style scoped>
    .tc-select-page-banner {
        width: 100%;
        background-color: #F7F7F8;
        text-align: left;
    }
    .banner-container {
        max-width: 730px;
        padding-top: 25px;
        padding-bottom: 25px;
    }
    .banner-header {
        font-family: 'Source Sans Pro SemiBold', 'Source Sans Pro', sans-serif;
        font-weight: 700;
        font-size: 34px;
        color: #0c154a;
        line-height: 44px;
    }
    .banner-description {
        font-family: 'Source Sans Pro SemiBold', 'Source Sans Pro', sans-serif;
        font-size: 16px;
        color: #141417;
        line-height: 26px;
        margin-top: 12px;
    }

    /* Media Queries */

    /* Tablets and Mobile */
    @media (max-width: 600px) {
        .banner-header {
            font-size: 28px;
            line-height: 32px;
        }
        .banner-description {
            font-size: 16px;
            line-height: 22px;
        }
    }
</style>