<template>
    <WidgetTemplate>
        <!-- Header -->
        <h2 slot="widget-header" class="header" v-html="headerText" />

        <!-- Content -->
        <div v-if="hasResults" slot="widget-content" class="links-container">
            <ul v-if="externalLinks" class="links">
                <li v-for="link in links" :key="link.id">
                    <div>
                        <a :href="link.url"
                           target="_blank"
                           rel="noopener noreferrer">
                            {{ link.name }} <span class="off-left">{{externalLinkScreenReaderText}}</span>
                        </a>
                        <img class="external-link-icon"
                             src="../../assets/images/icons/external-link-blue.svg"
                             :alt="linksWidget.linkIconAltText"
                             aria-hidden="true"/>
                    </div>
                </li>
            </ul>
            <ul v-else class="links">
                <li v-for="link in links" :key="link.id">
                    <router-link :to="link.url">
                        <span v-html="link.name" />
                    </router-link>
                </li>
            </ul>
        </div>
        <div v-else slot="widget-content">
            <NoResults />
        </div>
    </WidgetTemplate>
</template>

<script>
    import WidgetTemplate from '@/components/Widgets/WidgetTemplate.vue';
    import NoResults from '@/components/NoResults.vue';

    export default {
        name: 'LinksWidget',
        components: {
            WidgetTemplate,
            NoResults
        },
        props: {
            headerText: { type: String, required: true },
            links: { type: Array, required: true },
            externalLinks: { type: Boolean, required: true }
        },
        computed: {
            linksWidget() {
                return this.$store.getters.linkWidgetLocale;
            },
            externalLinkScreenReaderText() {
                return this.$store.getters.useSpanishLocale ? 'enlace externo' : 'external link';
            },
            hasResults() {
                return this.links.length > 0;
            }
        }
    }
</script>

<style scoped>
    .header {
        font-family: 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif;
        font-weight: 700;
        font-size: 18px;
        color: #0C154A;
        text-align: left;
        line-height: normal;
        max-width: 75%;
    }
    .links-container {
        max-height: 265px;
        overflow-x: hidden;
        overflow-y: auto;
    }
    .links {
        font-family: 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif;
        text-align: left;
        line-height: normal;
        list-style: none;
    }
    .links li {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #d7d7d7;
        padding: 16px;
    }
    .links li:last-child {
        border-bottom: none;
    }
    .links a {
        text-decoration: none;
        color: #4c6ce3;
    }
    .links a:hover {
        text-decoration: underline;
    }
    .external-link-icon {
        width: 14px;
        height: 14px;
    }
    .off-left {
        position: absolute;
        left: -1000px;
    }
</style>