<template>
    <div class="card">
        <div class="card-content-container">
            <img class="card-image"
                 :src="imageSrc"
                 :alt="imageAltText">
            <h2 class="card-text" v-html="cardText" />
        </div>        
        <div class="card-button-container">
            <div class="card-button">
                <span v-html="buttonText" />
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'BaseCard',
        props: {
            imageSrc: { type: String, required: true },
            imageAltText: { type: String, required: true },
            cardText: { type: String, required: true },
            buttonText: { type: String, required: true },
        },
    }
</script>

<style scoped>
    .card {
        max-width: 360px;                
        border-radius: 8px;
        border: 1px solid #dcdce2;
        font-family: 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif;
        font-weight: 700;
        color: #333333;
    }
    .card-content-container {
        padding: 15px 22px;
    }
    .card-image {
        width: 54px;
        height: 54px;
        padding-bottom: 12px;
    }
    .card-text {
        max-width: 310px;
        font-family: 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif;
        font-weight: 700;
        font-size: 20px;
        color: #141417;
        line-height: 26px;
    }
    .card-button-container {
        padding: 15px 22px;
        border-radius: 0px 0px 8px 8px;
        background-color: #4c6ce3;
        max-width: 100%;
    }
    .card-button {
        background: url('../assets/images/icons/right-caret.svg') no-repeat;
        background-position: right;
        background-size: 8px;
        font-family: 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif;
        font-weight: 600;
        font-size: 16px;
        color: #FFFFFF;
        line-height: normal;
        width: fit-content;
        padding-right: 18px;
    }    
    .card:hover .card-button-container {
        background-color: #2D4DC4;
    }

    /* Media Queries */

    /* Tablets and Mobile */
    @media (max-width: 600px) {
        .card-content-container {
            padding: 15px;
        }
        .card-button-container {
            padding: 15px;
        }
        .card-button {
            font-size: 14px;
        }
        .card-image {
            width: 50px;
            height: 50px;
        }
    }
</style>