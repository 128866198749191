import httpClient from '../../../service/httpClient';

const state = () => {
    return {
        config: []
    }
};

const getters = {
    contactUsEmail: (state) => {
        return state.config.find(c => c.configKey === 'CONTACT_US_EMAIL')?.configVal;
    },
    careerLinkBaseUrl: (state) => {
        return state.config.find(c => c.configKey === 'CAREER_LINK_BASE_URL')?.configVal;
    },
    moreInfoEtpUrl: (state) => {
        return state.config.find(c => c.configKey === 'MORE_INFO_ETP')?.configVal;
    },
    moreInfoCareerSchoolUrl: (state) => {
        return state.config.find(c => c.configKey === 'MORE_INFO_CAREER_SCHOOL')?.configVal;
    }
};

const actions = {
    async getConfigAsync({ commit }) {
        const response = await httpClient.getAsync('/Config');
        commit("SET_CONFIG", response.data);
    }
};

const mutations = {
    SET_CONFIG: (state, response) => {
        for (var i = 0; i < response.length; i++) {
            state.config.push(response[i]);
        }
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
