const state = () => {
    return {
        englishLocale: {
            heroImage: {
                altText: "Texas CREWS Hero Image with students looking at their laptops, tablets and text books",
                text: "Make an informed decision about your education.",
                textDescription: `Texas CREWS interactive dashboards help you compare Texas public universities,
                    public colleges, majors and career schools based on graduate wages, student
                    loan levels, graduation rates and more.`
            },
            cards: {
                searchSchoolsCard: {
                    imgAltText: "Search by Schools Card",
                    cardText: "Explore universities, colleges and career schools.",
                    buttonText: "Search by School",
                },
                searchMajorsCard: {
                    imgAltText: "Search by Majors Card",
                    cardText: "Explore majors and degree programs.",
                    buttonText: "Search by Major",
                },
                searchCareersCard: {
                    imgAltText: "Search by Careers Card",
                    cardText: "Explore majors that match your career interests.",
                    buttonText: "Search by Career",
                },
            },         
        },
        spanishLocale: {
            heroImage: {
                altText: "La Imagen del H\u00E9roe de Texas CREWS con estudiantes mirando sus computadoras port\u00E1tiles, tabletas y libros de texto.",
                text: "Tome una buena decisi\u00F3n sobre su educaci\u00F3n.",
                textDescription: `Los tableros interactivos de Texas CREWS le ayudar\u00E1n a comparar las diferentes fuentes 
                    de educaci\u00F3n de Texas. La informaci\u00F3n cubre universidades p\u00FAblicas, estudios superiores y escuelas 
                    profesionales. Los tableros le informar\u00E1n sobre los salarios obtenidos en los diferentes programas 
                    escolares, los pr\u00E9stamos disponibles, el porcentaje de estudiantes graduados, etc.`
            },
            cards: {
                searchSchoolsCard: {
                    imgAltText: "Buscar determinadas escuelas tarjeta",
                    cardText: "Explorar universidades y escuelas profesionales.",
                    buttonText: "Buscar escuelas espec\u00EDficas para su educaci\u00F3n",
                },
                searchMajorsCard: {
                    imgAltText: "Buscar \u00E1rea principal de estudio tarjeta",
                    cardText: "Explorar carreras y programas de grado.",
                    buttonText: "Buscar por \u00E1rea principal de estudio",
                },
                searchCareersCard: {
                    imgAltText: "Buscar carerras educativas tarjeta",
                    cardText: "Explorar \u00E1reas principales seg\u00FAn tus intereses profesionales.",
                    buttonText: "Buscar por intereses profesionales",
                },
            },     
        }
    }
}

const getters = {
    homePageLocale: (state, rootGetters) => {
        return rootGetters.useSpanishLocale
            ? state.spanishLocale
            : state.englishLocale;
    },
};

const actions = {};

const mutations = {};

export default {
    state,
    getters,
    actions,
    mutations,
};
