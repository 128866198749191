<template>
    <div>
        <div v-if="!isLoading">
            <!-- Banner -->
            <div class="tc-compare-page-banner">
                <div class="tc-container">
                    <div class="banner-container">
                        <h1 class="banner-header" v-html="compareSchoolPage.header" />

                        <p v-if="isMaximumComparableSchools" class="banner-maximum-schools-label" v-html="compareSchoolPage.maximumComparableSchoolsLabel" />

                        <div v-if="!isMaximumComparableSchools" class="banner-dropdown-container">
                            <div class="banner-dropdown-group">
                                <label for="school-dropdown"
                                       class="compare-school-dropdown-label"
                                       v-html="compareSchoolPage.bannerDropdownLabel" />

                                <BaseDropDown ref="schoolDropdown"
                                              id="school-dropdown"
                                              class="compare-school-dropdown"
                                              :ariaLabel="compareSchoolPage.bannerDropdownLabel"
                                              :options="dropdownValues"
                                              :noOptionsText="dropdownNoOptions"
                                              :clearable=false                                              
                                              @input="handleAddSchool($event)" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Content -->
            <div class="tc-container compare-school-container">
                <div class="compare-school-table-container">
                    <table class="compare-school-table">
                        <!-- Column Headers -->
                        <thead>
                            <tr>
                                <th class="comparing-label row-header sticky" v-html="comparingText" />
                                <th v-for="(schoolName, index) in schoolNames" :key="index" scope="col" class="column-header" v-html="schoolName" />
                                <th v-if="!isMaximumComparableSchools" class="button-cell add-item-cell">
                                    <img class="add-icon" src="../../assets/images/icons/add-icon.svg" :alt="compareSchoolPage.addSchoolButtonAriaLabel">
                                    <BaseButton class="add-item-button" :ariaLabel="compareSchoolPage.addSchoolButtonAriaLabel" :text="compareSchoolPage.addSchoolButtonText" @execute="handleDropdownFocus()" />
                                </th>
                            </tr>
                        </thead>
                        <!-- Data -->
                        <tbody>
                            <tr>
                                <th scope="row" class="row-header sticky" v-html="compareSchoolPage.tableHeaders[0]" />
                                <td v-for="(school, index) in comparableSchoolsDataset" :key="index" v-html="school[0]" />
                            </tr>
                            <tr>
                                <th scope="row" class="row-header sticky" v-html="compareSchoolPage.tableHeaders[1]" />
                                <td v-for="(school, index) in comparableSchoolsDataset" :key="index" v-html="school[1]" />
                            </tr>
                            <tr>
                                <th scope="row" class="row-header sticky" v-html="compareSchoolPage.tableHeaders[2]" />
                                <td v-for="(school, index) in comparableSchoolsDataset" :key="index" v-html="school[2]" />
                            </tr>
                            <tr>
                                <th scope="row" class="row-header sticky" v-html="compareSchoolPage.tableHeaders[3]" />
                                <td v-for="(school, index) in comparableSchoolsDataset" :key="index" v-html="school[3]" />
                            </tr>
                            <tr>
                                <th scope="row" class="row-header sticky" v-html="compareSchoolPage.tableHeaders[4]" />
                                <td v-for="(school, index) in comparableSchoolsDataset" :key="index" v-html="school[4]" />
                            </tr>
                            <tr>
                                <th scope="row" class="row-header sticky" v-html="compareSchoolPage.tableHeaders[5]" />
                                <td v-for="(school, index) in comparableSchoolsDataset" :key="index" v-html="school[5]" />
                            </tr>
                            <tr>
                                <th scope="row" class="row-header sticky" v-html="compareSchoolPage.tableHeaders[6]" />
                                <td v-for="(school, index) in comparableSchoolsDataset" :key="index" v-html="school[6]" />
                            </tr>
                            <tr>
                                <th scope="row" class="row-header sticky" v-html="compareSchoolPage.tableHeaders[7]" />
                                <td v-for="(school, index) in comparableSchoolsDataset" :key="index" v-html="school[7]" />
                            </tr>
                            <tr>
                                <th scope="row" class="row-header sticky" v-html="compareSchoolPage.tableHeaders[8]" />
                                <td v-for="(school, index) in comparableSchoolsDataset" :key="index" v-html="school[8]" />
                            </tr>
                            <tr>
                                <th scope="row" class="row-header sticky" v-html="compareSchoolPage.tableHeaders[9]" />
                                <td v-for="(school, index) in comparableSchoolsDataset" :key="index" v-html="school[9]" />
                            </tr>
                            <tr>
                                <th scope="row" class="row-header sticky" v-html="compareSchoolPage.tableHeaders[10]" />
                                <td v-for="(school, index) in comparableSchoolsDataset" :key="index" v-html="school[10]" />
                            </tr>
                        </tbody>
                        <!-- Footer Cells -->
                        <tfoot>
                            <tr>
                                <td class="row-header sticky" />
                                <td v-for="(link, index) in schoolDashboardLinks" :key="index">
                                    <div class="button-cell">
                                        <router-link class="router-link view-dashboard-button" :to="link" v-html="compareSchoolPage.viewDashboardButton" />
                                        <BaseButton class="remove-button" :aria-label="compareSchoolPage.removeButtonAriaLabel" :text="compareSchoolPage.removeButtonText" @execute="handleRemoveSchool(index)" />
                                    </div>
                                </td>
                            </tr>
                        </tfoot>
                    </table>

                </div>
                <div class="footer-asterisk">
                    <p v-for="asterisk in compareSchoolPage.footerAsterisks" :key="asterisk" class="asterisk" v-html="asterisk" />
                </div>
            </div>
        </div>

        <LoadingIndicator v-if="isLoading" :text="loadingText" />
    </div>        
</template>

<script>
    import BaseDropDown from '@/components/BaseDropDown.vue';
    import BaseButton from '@/components/BaseButton.vue';
    import LoadingIndicator from '@/components/LoadingIndicator.vue';
    import datasetMapperMixin from '@/mixins/datasetMapperMixin';

    export default {
        name: 'CompareSchoolPage',
        props: ['id'],
        mixins: [datasetMapperMixin],
        components: {
            BaseDropDown,
            BaseButton,
            LoadingIndicator
        },
        computed: {
            compareSchoolPage() {
                return this.$store.getters.compareSchoolPageLocale;
            },
            dropdownValues() {
                return this.$store.getters.compareSchoolNamesLocale;
            },
            dropdownNoOptions() {
                return this.$store.getters.dropdownLocale.noOptions;
            },
            isMaximumComparableSchools() {
                return this.schoolNames.length === this.maximumComparableSchools;
            },
            comparingText() {
                return this.compareSchoolPage.comparing + ' ' + this.schoolNames.length + ' ' + this.compareSchoolPage.of + ' ' + this.maximumComparableSchools + ' ' + this.compareSchoolPage.schools;
            },
            loadingText() {
                return this.$store.getters.loadingIndicatorLocale.loadingSchools;
            }
        },
        data() {
            return {
                isLoading: false,
                schoolId: 0,
                schools: [],
                schoolIds: [],
                schoolNames: [],
                comparableSchoolsDataset: [],
                schoolDashboardLinks: [],
                maximumComparableSchools: 4
            }
        },
        async created() {
            try {
                this.schoolId = parseInt(this.id);
                const schools = this.$store.getters.compareSchools;
                let comparableSchools = [];
                comparableSchools = this.$store.getters.comparableSchools;

                if (schools.length > 0 && comparableSchools.length === 0) {
                    this.schools = schools;
                    this.addSchoolData(this.schoolId);
                }
                else if (schools.length > 0 && comparableSchools.length > 0) {
                    this.schools = schools;
                    for (var i = 0; i < comparableSchools.length; i++) {
                        this.schoolNames.push(comparableSchools[i].schoolName);
                        this.comparableSchoolsDataset.push(comparableSchools[i].schoolData);
                        this.schoolDashboardLinks.push(comparableSchools[i].schoolLink);
                    }
                }
                else {
                    this.isLoading = true;
                    await this.$store.dispatch('getCompareSchoolsAsync');
                    this.schools = this.$store.getters.compareSchools;
                    this.addSchoolData(this.schoolId);
                    this.isLoading = false;
                }
            } catch (error) {
                this.isLoading = false;
                this.$router.push('/error');
            }
        },
        watch: {
            compareSchoolPage() {
                if (this.selectedSchool) {
                    this.selectedSchool = {
                        label: this.$store.getters.selectedCompareSchoolLocale(this.selectedSchool.value),
                        value: this.selectedSchool.value
                    }
                }

                this.updateSchoolData();
            }
        },
        methods: {
            handleDropdownFocus() {
                this.$refs.schoolDropdown.$refs.dropdown.$refs.search.focus();
            },
            handleRemoveSchool(index) {
                this.schoolIds.splice(index, 1);
                this.schoolNames.splice(index, 1);
                this.comparableSchoolsDataset.splice(index, 1);
                this.schoolDashboardLinks.splice(index, 1);
                this.$store.commit('REMOVE_COMPARABLE_SCHOOL', index);

                if (this.schoolIds.length === 0) {
                    this.$store.commit('SET_HAS_COMPARABLE_SCHOOLS', false);
                }
            },
            handleAddSchool(event) {                
                this.addSchoolData(event.value);
                this.$store.commit('SET_HAS_COMPARABLE_SCHOOLS', true);
            },
            addSchoolData(schoolId) {
                const compareSchoolData = this.$store.getters.compareSchoolDataById(schoolId);
                this.schoolIds.push(schoolId);

                // Add column header
                this.schoolNames.push(compareSchoolData.instituteLegalName || compareSchoolData.instituteLegalNameSpanish);

                // Add footer link
                this.schoolDashboardLinks.push(`/school-dashboard/${compareSchoolData.schoolProfileId}`);

                const dataset = [];
                const institutionType = this.$store.getters.compareSchoolInstitutionType(schoolId);
                const institutionTypeId = this.$store.getters.compareSchoolInstitutionTypeId(schoolId);
                let satScore = null;
                if (institutionTypeId === 2) {
                    satScore = compareSchoolData.satScore === null
                        ? this.$store.getters.datasetMapperLocale.noData
                        : compareSchoolData.satScore;
                }

                // Map dataset values
                dataset.push(institutionType); // institution type
                dataset.push(compareSchoolData.city ? compareSchoolData.city + ', ' + compareSchoolData.state : compareSchoolData.state); // location (city, state)
                dataset.push(compareSchoolData.tuitionFee ? this.mapDatasetValueToCurrency(compareSchoolData.tuitionFee) + ' / ' + this.compareSchoolPage.time.year : this.mapToNoData()); // avg tuition (undergrad)
                dataset.push(compareSchoolData.degreeTimeFinish ? this.mapDatasetValue(compareSchoolData.degreeTimeFinish) + ' ' + this.compareSchoolPage.time.years : this.mapToNoData()); // avg time to degree (undergrad)
                dataset.push(satScore !== null ? satScore : this.compareSchoolPage.notApplicable); // SAT score range
                dataset.push(this.mapDatasetValue(compareSchoolData.enrollment)); // total enrollment
                dataset.push(compareSchoolData.graduationRate ? this.mapDatasetValueToPercent((compareSchoolData.graduationRate * 100).toFixed(1)) : this.mapToNoData()); // gradution rate
                dataset.push(this.mapDatasetValueToPercent(compareSchoolData.loanPercent)); // percent graduates with loans
                dataset.push(this.mapDatasetValueToCurrency(compareSchoolData.loanAmount)); // avg loan amount at graduation
                dataset.push(this.mapDatasetValueToCurrency(compareSchoolData.wagesYear1)); // median first year wages
                dataset.push(compareSchoolData.loanToFirstWageRatio ? this.mapDatasetValueToPercent(Math.trunc(compareSchoolData.loanToFirstWageRatio * 100)) : this.mapToNoData()); // loan as percent of first year wages

                // Add to dataset
                this.comparableSchoolsDataset.push(dataset);

                let comparableSchool = {
                    schoolName: compareSchoolData.instituteLegalName || compareSchoolData.instituteLegalNameSpanish,
                    schoolData: dataset,
                    schoolLink: `/school-dashboard/${compareSchoolData.schoolProfileId}`
                };
                this.$store.commit('SET_COMPARABLE_SCHOOLS', comparableSchool);
            },
            updateSchoolData() {
                const updatedComparableSchoolsDataset = [];

                for (var i = 0; i < this.comparableSchoolsDataset.length; i++) {
                    const currentSchoolId = this.schoolIds[i];
                    const compareSchoolData = this.$store.getters.compareSchoolDataById(currentSchoolId);

                    const dataset = [];
                    const institutionType = this.$store.getters.compareSchoolInstitutionType(currentSchoolId);
                    const institutionTypeId = this.$store.getters.compareSchoolInstitutionTypeId(currentSchoolId);
                    let satScore = null;
                    if (institutionTypeId === 2) {
                        satScore = compareSchoolData.satScore === null
                            ? this.$store.getters.datasetMapperLocale.noData
                            : compareSchoolData.satScore;
                    }

                    // Map dataset values
                    dataset.push(institutionType); // institution type
                    dataset.push(compareSchoolData.city ? compareSchoolData.city + ', ' + compareSchoolData.state : compareSchoolData.state); // location (city, state)
                    dataset.push(compareSchoolData.tuitionFee ? this.mapDatasetValueToCurrency(compareSchoolData.tuitionFee) + ' / ' + this.compareSchoolPage.time.year : this.mapToNoData()); // avg tuition (undergrad)
                    dataset.push(compareSchoolData.degreeTimeFinish ? this.mapDatasetValue(compareSchoolData.degreeTimeFinish) + ' ' + this.compareSchoolPage.time.years : this.mapToNoData()); // avg time to degree (undergrad)
                    dataset.push(satScore !== null ? satScore : this.compareSchoolPage.notApplicable); // SAT score range
                    dataset.push(this.mapDatasetValue(compareSchoolData.enrollment)); // total enrollment
                    dataset.push(compareSchoolData.graduationRate ? this.mapDatasetValueToPercent((compareSchoolData.graduationRate * 100).toFixed(1)) : this.mapToNoData()); // gradution rate
                    dataset.push(this.mapDatasetValueToPercent(compareSchoolData.loanPercent)); // percent graduates with loans
                    dataset.push(this.mapDatasetValueToCurrency(compareSchoolData.loanAmount)); // avg loan amount at graduation
                    dataset.push(this.mapDatasetValueToCurrency(compareSchoolData.wagesYear1)); // median first year wages
                    dataset.push(compareSchoolData.loanToFirstWageRatio ? this.mapDatasetValueToPercent(Math.trunc(compareSchoolData.loanToFirstWageRatio * 100)) : this.mapToNoData()); // loan as percent of first year wages

                    // Add to updated dataset
                    updatedComparableSchoolsDataset.push(dataset);
                }

                this.comparableSchoolsDataset = updatedComparableSchoolsDataset;
            }
        }
    }
</script>

<style scoped>
    /* Banner */
    .tc-compare-page-banner {
        width: 100%;
        background-color: #F7F7F8;
        text-align: left;
    }
    .banner-container {
        max-width: 530px;
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .banner-header {
        font-family: 'Source Sans Pro SemiBold', 'Source Sans Pro', sans-serif;
        font-weight: 700;
        font-size: 28px;
        color: #0c154a;
        line-height: 44px;
    }
    .banner-maximum-schools-label {
        font-family: 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif;
        font-weight: 700;
        font-size: 18px;
        color: #141417;
        text-align: left;
        line-height: normal;
        margin-top: 12px;
    }
    .banner-dropdown-container {
        display: flex;
        max-width: 420px;
    }
    .banner-dropdown-group {
        display: flex;
        flex-direction: column;
        padding-right: 12px;
    }
    .compare-school-dropdown-label {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 16px;
        color: rgba(51, 51, 51, 1);
        margin-top: 12px;
    }
    .compare-school-dropdown {
        margin-top: 4px;
        width: 370px;
    }
    .compare-school-container {
        padding-bottom: 50px;
    }
    /* Compare Table */
    .compare-school-table-container {
        overflow: auto;
    }
    .compare-school-table {
        margin-top: 30px;
        font-family: 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif;
        font-size: 14px;
        color: #141417;
        text-align: center;
        border-collapse: collapse;
        width: 800px;
    }
    th,
    td {
        box-sizing: border-box;
        border: 1px solid #dcdce2;
        padding: 12px;
        width: 150px;
    }
    .comparing-label {
        padding: 18px 12px;
        text-align: left;
        font-weight: 700;
    }
    .column-header {
        max-width: 130px;
        font-size: 16px;
        font-weight: 700;
        padding: 12px;
    }
    .row-header {
        background-color: #f7f7f8;
        width: 210px;
        font-size: 14px;
        font-weight: 700;
        text-align: left;
        line-height: 16px;
    }
    .sticky {
        left: 0;
        position: sticky;
        z-index: 100;
    }
    .button-cell {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .add-item-cell {
        border: 2px dashed #dcdce2;
        margin-left: 2px;
        padding: 20px;
        font-family: 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif;
        color: #4c6ce3;
        text-align: center;
        line-height: 18px;
    }
    .add-item-cell:hover {
        cursor: pointer;
    }
    .add-icon {
        width: 14px;
        height: 14px;
        padding-bottom: 4px;
        display: inline;
    }
    .add-item-button {
        font-family: 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif;
        font-size: 14px;
        background-color: #FFFFFF;
        color: #4C6CE3;
        cursor: pointer;
        border: none;
        text-align: center;
        line-height: normal;
        display: inline;
    }
    .view-dashboard-button {
        font-family: 'Source Sans Pro SemiBold', 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif;
        font-weight: 700;
        font-size: 16px;
        background-color: #4C6CE3;
        color: #FFFFFF;
        cursor: pointer;
        border-radius: 8px;
        border: none;
        padding: 6px 10px;
        margin-bottom: 10px;
        text-align: center;
        line-height: 20px;
        display: inline;
    }
    .view-dashboard-button:hover {
        background-color: #2D4DC4;
    }
    .remove-button {
        font-family: 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif;
        font-size: 14px;
        background-color: #FFFFFF;
        color: #4C6CE3;
        cursor: pointer;
        border: none;
        text-align: center;
        line-height: normal;
        display: inline;
    }
    .remove-button:hover {
        text-decoration: underline;
    }
    .footer-asterisk {
        margin-top: 26px;
    }
    .asterisk {
        font-family: 'Source Sans Pro Italic', 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif;
        font-style: italic;
        font-size: 12px;
        color: #333333;
        line-height: 18px;
    }

    /* Media Queries */

    /* Tablets and Mobile */
    @media (max-width: 600px) {
        .banner-header {
            font-size: 28px;
            line-height: 32px;
        }
        .row-header {
            width: 116px;
        }
    }
    @media (max-width: 400px) {
        .compare-school-dropdown {
            width: 320px;
        }        
    }
    @media (max-width: 350px) {
        .compare-school-dropdown {
            width: 280px;
        }
    }
</style>