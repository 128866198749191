import httpClient from '../../../../service/httpClient';

const state = () => {
    return {
        englishLocale: {
            header: "Glossary",
            description: "This glossary documents definitions for data elements and terms used on the Texas CREWS dashboard reports."
        },
        spanishLocale: {
            header: "Glosario",
            description: "Este glosario documenta las definiciones de los elementos de datos y los t\u00E9rminos utilizados en los informes del tablero de Texas CREWS."
        },
        glossaries: []
    }
};

const getters = {
    glossaryPageLocale: (state, rootGetters) => {
        return rootGetters.useSpanishLocale
            ? state.spanishLocale
            : state.englishLocale;
    },
    glossaries: (state, rootGetters) => {
        return rootGetters.useSpanishLocale
            ? state.glossaries.map(({ glossaryTermSpanish, glossaryDefinitionSpanish }) => ({ header: glossaryTermSpanish, content: glossaryDefinitionSpanish }))
            : state.glossaries.map(({ glossaryTerm, glossaryDefinition }) => ({ header: glossaryTerm, content: glossaryDefinition })); 
    }
};

const actions = {
    async getGlossariesAsync({ commit }) {
        const response = await httpClient.getAsync('/Glossaries');
        commit("SET_GLOSSARIES", response.data);
    }
};

const mutations = {
    SET_GLOSSARIES: (state, response) => {
        for (var i = 0; i < response.length; i++) {
            state.glossaries.push(response[i]);
        }
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
