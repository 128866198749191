<template>
    <input class="text-input" 
           type="text" 
           name="text-input"             
           :placeholder="placeholder"
           @input="handleInput($event)" />
</template>

<script>
    export default {
        name: 'BaseTextInput',
        props: {
            placeholder: { type: String, required: true },
        },
        methods: {
            handleInput(event) {
                this.$emit("input", event);
            }
        }
    }
</script>

<style scoped>
    .text-input {
        font-family: 'Segoe UI Regular', 'Segoe UI', sans-serif;
        font-size: 16px;
        color: #999999;
        border-radius: 8px;
        border: 1px solid #d7d7d7;        
        padding: 6px 6px 6px 32px;
    }
    .text-input:hover {
        box-shadow: 0 0 0 1pt #4c6ce3;
    }
    .text-input:focus {
        outline: 1px auto #4c6ce3;
    }
    input[type="text"] {
        background-color: #FFFFFF;
    }
</style>