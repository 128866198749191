import httpClient from '../../../../service/httpClient';

const state = () => {
    return {
        englishLocale: {
            header: "Explore majors that match your career interests.",
            description: "First select a career area, followed by a specific career within that area, and then select a major.",
            dropdownLabels: {
                careerArea: "Select a Career Area:",
                career: "Select a Career:",
                major: "Select a Major:",
            },
            viewDashboardButton: "View Dashboard",
        },
        spanishLocale: {
            header: "Explorar campos principales de estudio de acuerdo a sus intereses profesionales.",
            description: `Primero seleccione una \u00E1rea de carrera, seguido por una carrera
                espec\u00EDfica en la misma \u00E1rea y despu\u00E9s un campo principal de estudio.`,
            dropdownLabels: {
                careerArea: "Seleccione una \u00E1rea de carrera:",
                career: "Seleccione una carrera:",
                major: "Seleccione un campo principal de estudio:",
            },
            viewDashboardButton: "Ver Tablero",
        },
        careers: [],
        careerAreaNames: []
    }
};

const getters = {
    selectCareerPageLocale: (state, rootGetters) => {
        return rootGetters.useSpanishLocale
            ? state.spanishLocale
            : state.englishLocale;
    },
    careers: (state) => {
        return state.careers
    },
    careerAreaNamesLocale: (state, rootGetters) => {
        return rootGetters.useSpanishLocale
            ? state.careerAreaNames.map(c => ({ label: c.nameSpanish, value: c.id }))
            : state.careerAreaNames.map(c => ({ label: c.nameEnglish, value: c.id }));
    },
    careerNamesLocale: (state, rootGetters) => (careerAreaId) => {
        const careerNames = [...new Set(state.careers.filter(c => c.careerAreaId === careerAreaId)
                                                     .map(c => ({
                                                         nameEnglish: c.career.careerName,
                                                         nameSpanish: c.career.careerNameSpanish,
                                                         id: c.career.careerId
                                                     })))];
        careerNames.sort((a, b) => (a.nameEnglish > b.nameEnglish) ? 1 : -1);

        return rootGetters.useSpanishLocale
            ? careerNames.map(c => ({ label: c.nameSpanish, value: c.id }))
            : careerNames.map(c => ({ label: c.nameEnglish, value: c.id }));
    },
    careerProgramNamesLocale: (state, rootGetters) => (careerId) => {
        const programNames = state.careers.find(c => c.career.careerId === careerId)?.career?.careersPrograms
                                           .map(p => ({
                                               nameEnglish: p.program.programLongName,
                                               nameSpanish: p.program.programLongNameSpanish,
                                               id: p.program.programId
                                           }));
        programNames.sort((a, b) => (a.nameEnglish > b.nameEnglish) ? 1 : -1);

        return rootGetters.useSpanishLocale
            ? programNames.map(p => ({ label: p.nameSpanish, value: p.id }))
            : programNames.map(p => ({ label: p.nameEnglish, value: p.id }));
    },
    selectedCareerAreaLocale: (state, rootGetters) => (careerAreaId) => {
        return rootGetters.useSpanishLocale
            ? state.careerAreaNames.find(c => c.id === careerAreaId)?.nameSpanish
            : state.careerAreaNames.find(c => c.id === careerAreaId)?.nameEnglish;
    },
    selectedCareerLocale: (state, rootGetters) => (careerId) => {
        return rootGetters.useSpanishLocale
            ? state.careers.find(c => c.career.careerId === careerId)?.career?.careerNameSpanish
            : state.careers.find(c => c.career.careerId === careerId)?.career?.careerName;
    },
    selectedCareerProgramLocale: (state, rootGetters) => (programId) => {
        return rootGetters.useSpanishLocale
            ? rootGetters.majors.find(p => p.programId === programId)?.programLongNameSpanish
            : rootGetters.majors.find(p => p.programId === programId)?.programLongName;
    }
};

const actions = {
    async getCareersAsync({ commit }) {
        const response = await httpClient.getAsync('/Careers');
        commit("SET_CAREERS", response.data);
        commit("SET_CAREER_AREA_NAMES", response.data);
    },
};

const mutations = {
    SET_CAREERS: (state, careers) => state.careers = careers,
    SET_CAREER_AREA_NAMES: (state, careers) => {
        const careerAreaNames = [];
        const map = new Map();

        for (const career of careers) {
            if (!map.has(career.careerArea.careerAreaId)) {
                map.set(career.careerArea.careerAreaId, true);
                careerAreaNames.push({
                    nameEnglish: career.careerArea.careerAreaName,
                    nameSpanish: career.careerArea.careerAreaNameSpanish,
                    id: career.careerArea.careerAreaId
                });
            }
        }

        state.careerAreaNames = careerAreaNames.sort((a, b) => (a.nameEnglish > b.nameEnglish) ? 1 : -1);
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
};
