<template>
    <div>
        <div v-if="!isLoading">
            <div class="tc-container footer-page-container">
                <!-- Header -->
                <h1 class="footer-page-header" v-html="header" />
                <slot name="footer-page-description"></slot>

                <!-- Content -->
                <ul class="footer-page-content-list-items">
                    <li v-for="contentElement in contentElements" :key="contentElement.header" class="footer-page-content-list-item">
                        <h2 class="footer-page-content-list-item-header" v-html="contentElement.header" />
                        <p class="footer-page-content-list-item-content" v-html="contentElement.content" />
                    </li>
                </ul>
            </div>
        </div>

        <LoadingIndicator v-if="isLoading" :text="loadingText" />
    </div>
</template>

<script>
    import LoadingIndicator from '@/components/LoadingIndicator.vue';

    export default {
        name: 'FooterPageTemplate',
        components: {
            LoadingIndicator
        },
        props: {
            header: { type: String, required: true },
            contentElements: { type: Array, required: true },
            isLoading: { type: Boolean, required: true },
            loadingText: { type: String, required: true }
        }
    }
</script>

<style scoped>
    .footer-page-header {
        font-family: 'Source Sans Pro SemiBold', 'Source Sans Pro', sans-serif;
        font-size: 32px;
        font-weight: 700;
        color: rgba(20, 20, 23, 0.99);
        line-height: 48px;
        margin-top: 50px;
        margin-bottom: 22px;
    }
    .footer-page-container {
        padding-bottom: 50px;
    }
    .footer-page-content-list-items {
        list-style: none;
    }
    .footer-page-content-list-item {
        max-width: 800px;
        margin-bottom: 40px;
    }
    .footer-page-content-list-item-header {
        font-family: 'Source Sans Pro SemiBold', 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif;
        font-size: 16px;
        font-weight: 600;
        color: rgba(20, 20, 23, 0.99);
        line-height: normal;
        margin-bottom: 10px;
    }
    .footer-page-content-list-item-content {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 16px;
        color: rgba(20, 20, 23, 0.99);
        text-align: left;
        line-height: normal;
        white-space: pre-wrap;
    }

    /* Media Queries */

    /* Tablets and Mobile */
    @media (max-width: 700px) {
        .footer-page-header {
            font-size: 24px;
            line-height: normal;
            margin-top: 30px;
        }
        .footer-page-content-list-item-content {
            font-size: 14px;
        }
    }
</style>