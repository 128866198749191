import Vue from 'vue';
import App from './App.vue';
import router from '@/router';
import store from '@/store';
import VueGtm from 'vue-gtm';

// Kendo Bootstrap Theme
import '@progress/kendo-theme-bootstrap/dist/all.css';

// IE browser check
if (!/MSIE \d|Trident.*rv:/.test(navigator.userAgent)) {
    require('dotenv').config();

    Vue.config.productionTip = true;

    if (process.env.NODE_ENV === 'production') {
        Vue.use(VueGtm, {
            id: process.env.VUE_APP_GTM,
            defer: false,
            enabled: true,
            debug: false,
            loadScript: true,
            vueRouter: router,
            trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
        });
    }
}
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
