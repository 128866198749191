import httpClient from '../../../../service/httpClient';

const state = () => {
    return {
        englishLocale: {
            header: "CREWS Accessibility Tips"
        },
        spanishLocale: {
            header: "Consejos de Accesibilidad de CREWS"
        },
        accessibilityTips: []
    }
};

const getters = {
    accessibilityTipsPageLocale: (state, rootGetters) => {
        return rootGetters.useSpanishLocale
            ? state.spanishLocale
            : state.englishLocale;
    },
    accessibilityTips: (state, rootGetters) => {
        return rootGetters.useSpanishLocale
            ? state.accessibilityTips.map(({ questionSpanish, answerSpanish }) => ({ header: questionSpanish, content: answerSpanish }))
            : state.accessibilityTips.map(({ question, answer }) => ({ header: question, content: answer }));
    }
};

const actions = {
    async getAccessibilityTipsAsync({ commit }) {
        const response = await httpClient.getAsync('/Faqs');
        commit("SET_ACCESSIBILITY_TIPS", response.data);
    }
};

const mutations = {
    SET_ACCESSIBILITY_TIPS: (state, response) => {
        response = response.filter(a => a.accessibilityTip !== null);

        for (var i = 0; i < response.length; i++) {
            state.accessibilityTips.push(response[i]);
        }
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
