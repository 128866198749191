<template>
    <WidgetTemplate>
        <!-- Header -->
        <h2 slot="widget-header" class="header" v-html="headerText" /> 

        <!-- Content -->
        <div v-if="hasResults" slot="widget-content" class="description-container">
            <p class="description" v-html="content" />
        </div>
        <div v-else slot="widget-content">
            <NoResults />
        </div>
    </WidgetTemplate>
</template>

<script>
    import WidgetTemplate from '@/components/Widgets/WidgetTemplate.vue';
    import NoResults from '@/components/NoResults.vue';

    export default {
        name: 'DescriptionWidget',
        components: {
            WidgetTemplate,
            NoResults
        },
        props: {
            headerText: { type: String, required: true },
            content: { type: String, required: true }
        },
        computed: {
            hasResults() {
                return this.content
                    ? true
                    : false;
            }
        }
    }
</script>

<style scoped>
    .header {
        font-family: 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif;
        font-weight: 700;
        font-size: 18px;
        color: #0C154A;
        text-align: left;
        line-height: normal;
        max-width: 75%;
    }
    .description-container {
        max-height: 265px;
        overflow-x: hidden;
        overflow-y: auto;
    }
    .description {
        font-family: 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif;
        font-size: 16px;
        color: #333333;
        text-align: left;
        line-height: normal;
        padding: 16px;
    }
</style>