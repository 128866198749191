<template>
    <div class="tc-container">
        <!-- Header -->
        <div class="tc-header-container">
            <div class="tc-header">
                <img class="header-logo"
                        src="../assets/images/logos/logo-full-name.svg"
                        :alt="header.appLogo.altText" />
                <div class="header-buttons-container">
                    <BaseButton class="language-switch-button"
                                :aria-label="header.languageSwitchButton.ariaLabel"
                                :text="header.languageSwitchButton.text"
                                @execute="handleLanguageSwitch()" />
                </div>
            </div>
        </div>
        <!-- Content -->
        <div class="browser-not-supported-container">
            <div class="browser-not-supported-nav-container">
                <h1 class="browser-not-supported-header" v-html="browserNotSupported.header" />
                <p class="browser-not-supported-description" v-html="browserNotSupported.description" />

                <BaseButton class="browser-not-supported-edge-button"
                            :aria-label="browserNotSupported.edgeButtonAriaLabel"
                            :text="browserNotSupported.edgeButtonText"
                            @execute="handleNavToEdge()" />
            </div>
            <img class="browser-not-supported-image"
                 src="../assets/images/error-page-binoculars.png"
                 :alt="browserNotSupported.imageAltText" />
        </div>
    </div>    
</template>

<script>
    import BaseButton from '@/components/BaseButton.vue';

    export default {
        name: 'BrowserNotSupported',
        components: {
            BaseButton
        },
        computed: {
            header() {
                return this.$store.getters.headerLocale;
            },
            browserNotSupported() {
                return this.$store.getters.browserNotSupportedLocale;
            }
        },
        created() {
            document.title = 'Texas CREWS | No Support';
        },
        methods: {
            handleLanguageSwitch() {
                const useSpanishLocale = this.$store.getters.useSpanishLocale;
                this.$store.commit('SET_USE_SPANISH_LOCALE', !useSpanishLocale);
            },
            handleNavToEdge() {
                try {                    
                    // Open home page
                    window.open(`microsoft-edge:${window.location.protocol}//${window.location.hostname}`);
                } catch (error) {
                    alert(this.browserNotSupported.alertText);
                }
            }
        }
    }
</script>

<style scoped>
    .language-switch-button {
        background-color: rgba(255, 255, 255, 0);
        font-family: 'Source Sans Pro SemiBold', 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif;
        font-weight: 600;
        font-size: 16px;
        color: #0C154A;
        cursor: pointer;
        border: hidden;
        margin-right: 20px;
        vertical-align: middle;
        display: block;
        float: right;
    }
    .browser-not-supported-container {
        display: block;
        margin-top: 50px;
    }
    .browser-not-supported-nav-container {
        display: block;
        float: left;
        max-width: 636px;
    }
    .browser-not-supported-image {
        position: absolute;
        height: 380px;
    }
    .browser-not-supported-header {
        font-family: 'Source Sans Pro Bold', sans-serif;
        font-weight: 700;
        font-size: 48px;
        line-height: 52px;
        color: #0C154A;
        margin-bottom: 20px;
    }
    .browser-not-supported-description {
        font-family: 'Source Sans Pro Regular', sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #141417;
        margin-bottom: 20px;
    }
    .browser-not-supported-edge-button {
        font-family: 'Source Sans Pro SemiBold', 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif;
        font-size: 16px;
        background-color: #4C6CE3;
        color: #FFFFFF;
        cursor: pointer;
        border-radius: 8px;
        border: none;
        margin-top: 25px;
        padding: 12px 16px;
        text-align: left;
    }
    .browser-not-supported-edge-button:hover {
        background-color: #2D4DC4;
    }
</style>