const state = () => {
    return {
        useSpanishLocale: false,
    }
}

const getters = {
    useSpanishLocale: state => {
        return state.useSpanishLocale;
    },
};

const actions = {};

const mutations = {
    SET_USE_SPANISH_LOCALE: (state, useSpanishLocale) => {
        useSpanishLocale ? document.documentElement.lang = 'es' : document.documentElement.lang = 'en';
        state.useSpanishLocale = useSpanishLocale;
    },
};

export default {
    state,
    getters,    
    actions,
    mutations,
};
