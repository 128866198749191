<template>
    <DashboardTemplate :dashboardHeaderText="school"
                       :comparePage="`/compare-school/${schoolId}`"
                       :isComparable=true
                       :isLoading="isLoading"
                       @updateReport="handleUpdateReportAsync()"
                       @download="handleDownload()">
        <!-- Banner Dropdowns -->
        <div slot="dashboard-banner-dropdowns" class="banner-dropdown-container">
            <div class="dashboard-dropdown-group">
                <label for="school-dropdown"
                       class="dashboard-dropdown-label"
                       v-html="schoolDashboard.dashboardBanner.schoolDropdownLabel" />

                <BaseDropDown id="school-dropdown"
                              class="dashboard-dropdown"
                              :ariaLabel="schoolDashboard.dashboardBanner.schoolDropdownLabel"
                              :options="schoolDropdownValues"
                              :noOptionsText="dropdownNoOptions"
                              :clearable=false
                              v-model="selectedSchool" />
            </div>
            <div class="dashboard-dropdown-group">
                <label for="school-year-dropdown"
                       class="dashboard-dropdown-label"
                       v-html="schoolDashboard.dashboardBanner.schoolYearDropdownLabel" />

                <BaseDropDown id="school-year-dropdown"
                              class="dashboard-dropdown school-years"
                              :ariaLabel="schoolDashboard.dashboardBanner.schoolYearDropdownLabel"
                              :options="schoolYearDropdownValues"
                              :noOptionsText="dropdownNoOptions"
                              :optionsHeader="dropdownOptionsHeader"
                              :clearable=false
                              v-model="selectedSchoolYear" />
            </div>
        </div>
        <!-- Widgets -->
        <div ref="dashboardContent" slot="dashboard-content" class="dashboard-widgets-container">
            <DatasetWidget :selectedDashboardItem="school"
                           :headerText="widgetOneHeader"
                           tableId="school-dashboard-widget-1"
                           :hasTableHeaders=false
                           :footerAsterisks="[schoolDashboard.widgetAsterisks.graduationRate, schoolDashboard.widgetAsterisks.medianFirstYearWages]"
                           :dataset=dashboardData.schoolProfile />            
            
            <DatasetWidget :selectedDashboardItem="school"
                           :headerText="widgetTwoHeader"
                           :isToggleable=true
                           tableId="school-dashboard-widget-2"
                           :dataset=dashboardData.medianFirstYearWagesDegreeLevel>

                <!-- Major Filter -->
                <div class="widget-filter-dropdown-container" slot="widget-filter-dropdowns">
                    <label for="school-dashboard-widget-2-dropdown"
                           class="widget-filter-dropdown-label"
                           v-html="schoolDashboard.majorFilterDropdown.label" />

                    <BaseDropDown id="school-dashboard-widget-2-dropdown"
                                  class="widget-filter-dropdown"
                                  :aria-label="schoolDashboard.majorFilterDropdown.label"
                                  :options="widgetTwoDropdownValues"
                                  :noOptionsText="dropdownNoOptions"
                                  :clearable=false
                                  v-model="widgetTwoSelectedFilter" 
                                  @input="handleDatasetFilterChange(2)" />
                </div>

            </DatasetWidget>

            <DatasetWidget :selectedDashboardItem="school"
                           :headerText="widgetThreeHeader"
                           :isToggleable=true
                           tableId="school-dashboard-widget-3"
                           :dataset=dashboardData.threeProgramsHighestFirstYearWages>

                <!-- Degree Level Filter -->
                <div class="widget-filter-dropdown-container" slot="widget-filter-dropdowns">
                    <label for="school-dashboard-widget-3-dropdown"
                           class="widget-filter-dropdown-label"
                           v-html="schoolDashboard.degreeLevelFilterDropdown.otherLabel" />

                    <BaseDropDown id="school-dashboard-widget-3-dropdown"
                                  class="widget-filter-dropdown"
                                  :ariaLabel="schoolDashboard.degreeLevelFilterDropdown.otherLabel"
                                  :options="widgetThreeDropdownValues"
                                  :noOptionsText="dropdownNoOptions"
                                  :clearable=false
                                  v-model="widgetThreeSelectedFilter"
                                  @input="handleDatasetFilterChange(3)" />
                </div>

            </DatasetWidget>

            <DatasetWidget :selectedDashboardItem="school"
                           :headerText="widgetFourHeader"
                           :isToggleable=true
                           tableId="school-dashboard-widget-4"
                           :dataset=dashboardData.compareStatewideWages>
                                
                <div slot="widget-filter-dropdowns">
                    <!-- Degree Level Filter -->
                    <div class="widget-filter-dropdown-container">
                        <label for="school-dashboard-widget-4-dropdown-1"
                               class="widget-filter-dropdown-label"
                               v-html="schoolDashboard.degreeLevelFilterDropdown.otherLabel" />

                        <BaseDropDown id="school-dashboard-widget-4-dropdown-1"
                                      class="widget-filter-dropdown"
                                      :ariaLabel="schoolDashboard.degreeLevelFilterDropdown.otherLabel"
                                      :options="widgetFourDropdownOneValues"
                                      :noOptionsText="dropdownNoOptions"
                                      :clearable=false
                                      v-model="widgetFourSelectedFilterOne"
                                      @input="handleDatasetFilterChange(4)" />
                    </div>

                    <!-- Major Filter -->
                    <div class="widget-filter-dropdown-container">
                        <label for="school-dashboard-widget-4-dropdown-2"
                               class="widget-filter-dropdown-label"
                               v-html="schoolDashboard.majorFilterDropdown.label" />

                        <BaseDropDown id="school-dashboard-widget-4-dropdown-2"
                                      class="widget-filter-dropdown"
                                      :ariaLabel="schoolDashboard.majorFilterDropdown.label"
                                      :options="widgetFourDropdownTwoValues"
                                      :noOptionsText="dropdownNoOptions"
                                      :clearable=false
                                      v-model="widgetFourSelectedFilterTwo"
                                      @input="handleDatasetFilterChange(4)" />
                    </div>
                </div>
            
            </DatasetWidget>

            <DatasetWidget :selectedDashboardItem="school"
                           :headerText="widgetFiveHeader"
                           :isFilterable=true
                           tableId="school-dashboard-widget-5"
                           :footerAsterisks="[schoolDashboard.widgetAsterisks.graduateWork]"
                           :filterSearchInputPlaceholder="filterSearchInputPlaceholder"
                           :filterDropdownLabel="widgetFiveFilterLabel"
                           :filterDropdownAriaLabel="widgetFiveFilterLabel"
                           :filterDropdownValues="widgetFiveFilterDropdown.options"
                           :filterDropdownSelectedValue="widgetFiveSelectedDegreeLevelFilter"
                           :dataset="majorAndDegreeLevelFilteredData ? majorAndDegreeLevelFilteredData : dashboardData.majorAndDegreeLevelData"
                           @filterChange="handleFilterChange($event)"
                           @inputChange="handleInputChange($event)" />
        </div>
    </DashboardTemplate>
</template>

<script>
    /* eslint-disable */
    import DashboardTemplate from '@/components/PageTemplates/DashboardTemplate.vue'; 
    import BaseDropDown from '@/components/BaseDropDown.vue';
    import DatasetWidget from '@/components/Widgets/DatasetWidget.vue';
    import datasetMapperMixin from '@/mixins/datasetMapperMixin';
    import pdfGeneratorMixin from '@/mixins/pdfGeneratorMixin';

    export default {
        name: 'SchoolDashboard',
        props: ['id'],
        mixins: [datasetMapperMixin, pdfGeneratorMixin],
        components: {
            DashboardTemplate,
            BaseDropDown,
            DatasetWidget
        },
        computed: {
            schoolDashboard() {
                return this.$store.getters.schoolDashboardLocale;
            },
            schoolDropdownValues() {
                return this.$store.getters.schoolDashboardSchools(parseInt(this.schoolId));
            },
            schoolYearDropdownValues() {
                return this.years;
            },
            dropdownNoOptions() {
                return this.$store.getters.dropdownLocale.noOptions;
            },
            dropdownOptionsHeader() {
                return this.$store.getters.dropdownLocale.historicalDataLabel
            },
            widgetOneHeader() {
                return this.schoolDashboard.widgetHeaders.schoolProfile + ' - ' + this.schoolYear;
            },
            widgetTwoHeader() {
                return this.schoolYear < this.years[0]
                    ? this.schoolDashboard.widgetHeaders.medianHistoricalWagesDegreeLevel + ' - ' + this.schoolYear + ' ' + this.schoolDashboard.widgetHeaders.graduates
                    : this.schoolDashboard.widgetHeaders.medianFirstYearWagesDegreeLevel + ' - ' + this.years[0] + ' ' + this.schoolDashboard.widgetHeaders.graduates;
            },
            widgetThreeHeader() {
                return this.schoolDashboard.widgetHeaders.threeProgramsHighestFirstYearWages + ' - ' + this.years[0] + ' ' + this.schoolDashboard.widgetHeaders.graduates;
            },
            widgetFourHeader() {
                return this.schoolDashboard.widgetHeaders.compareStatewideWages + ' - ' + this.years[0] + ' ' + this.schoolDashboard.widgetHeaders.graduates;
            },
            widgetFiveHeader() {
                return this.school + ', ' + this.schoolYear + ' ' + this.schoolDashboard.widgetHeaders.graduates + ' - ' + this.widgetFiveSelectedDegreeLevelFilter.label;
            },
            filterSearchInputPlaceholder() {
                return this.$store.getters.schoolDashboardLocale.filterSearchInput.placeholder;
            },
            widgetFiveFilterDropdown() {
                return {
                    label: this.$store.getters.schoolDashboardLocale.degreeLevelFilterDropdown.allDegreeLevels,
                    options: this.widgetFiveDropdownValues
                }
            },
            widgetFiveFilterLabel() {
                return this.$store.getters.schoolDashboardLocale.degreeLevelFilterDropdown.label
            }
        },
        data() {
            return {
                isLoading: false,
                selectedSchool: {
                    label: null,
                    value: null
                },
                selectedSchoolYear: null,
                schoolId: 0,
                school: '',
                schoolYear: null,
                years: [],
                widgetTwoSelectedFilter: {
                    label: this.$store.getters.schoolDashboardLocale.majorFilterDropdown.allMajors,
                    value: 390 // all majors
                },                
                widgetThreeSelectedFilter: {
                    label: this.$store.getters.schoolDashboardLocale.degreeLevelFilterDropdown.allDegreeLevels,
                    value: 9 // all degree levels
                },
                widgetFourSelectedFilterOne: {
                    label: this.$store.getters.schoolDashboardLocale.degreeLevelFilterDropdown.allDegreeLevels,
                    value: 9 // all degree levels
                },
                widgetFourSelectedFilterTwo: {
                    label: this.$store.getters.schoolDashboardLocale.majorFilterDropdown.allMajors,
                    value: 390 // all majors
                },              
                widgetFiveSelectedDegreeLevelFilter: {
                    label: this.$store.getters.schoolDashboardLocale.degreeLevelFilterDropdown.allDegreeLevels,
                    value: 9 // all degree levels
                },
                widgetTwoDropdownValues: [],
                widgetThreeDropdownValues: [],
                widgetFourDropdownOneValues: [],
                widgetFourDropdownTwoValues: [],
                widgetFiveDropdownValues: [],
                dashboardData: {
                    schoolProfile: {},
                    medianFirstYearWagesDegreeLevel: {},
                    threeProgramsHighestFirstYearWages: {},
                    compareStatewideWages: {},
                    majorAndDegreeLevelData: {}
                },
                majorAndDegreeLevelFilteredData: null,
                windowWidth: window.innerWidth
            }
        },
        async created() {
            try {
                this.schoolId = parseInt(this.id);
                const localSchool = this.$store.getters.schoolDashboardDataBySchoolId(this.schoolId);

                // Check if the selected school exists already in state; if not, 
                // get the dashboard data and build the widgets
                if (localSchool) {
                    this.years = this.$store.getters.schoolDashboardSchoolYears;
                    this.setDashboardSelectedSchoolAndSelectedYear(this.schoolId, this.years[0]);
                    this.buildDashboardWidgets();
                }
                else {
                    this.isLoading = true;

                    const schools = this.$store.getters.schools;
                    if (schools.length < 1) {
                        await this.$store.dispatch('getSchoolsAsync');
                    }
                    await this.getDashboardDataAsync(this.schoolId);
                    this.years = this.$store.getters.schoolDashboardSchoolYears;
                    this.setDashboardSelectedSchoolAndSelectedYear(this.schoolId, this.years[0]);
                    this.buildDashboardWidgets();

                    this.isLoading = false;
                }                
            } catch (error) {
                this.isLoading = false;
                this.$router.push('/error');
            }            
        },
        mounted() {
            this.$nextTick(() => {
                // Add resize event listener for dynamic table styling
                window.addEventListener('resize', () => this.windowWidth = window.innerWidth);
            });
        },
        beforeDestroy() {
            window.removeEventListener('resize', () => this.windowWidth = window.innerWidth);
        },
        watch: {
            schoolDashboard() {
                this.selectedSchool = {
                    label: this.$store.getters.schoolDashboardSchoolNameById(this.selectedSchool.value),
                    value: this.selectedSchool.value
                };
                this.school = this.$store.getters.schoolDashboardSchoolNameById(this.schoolId);

                const selectedWidgetTwoFilter = this.$store.getters.schoolDashboardDegreeLevelWagesFilters(this.schoolId, parseInt(this.selectedSchoolYear)).find(m => m.value === this.widgetTwoSelectedFilter.value);
                this.widgetTwoSelectedFilter = {
                    label: selectedWidgetTwoFilter.value === 390 ? this.schoolDashboard.majorFilterDropdown.allMajors : selectedWidgetTwoFilter.label,
                    value: selectedWidgetTwoFilter.value
                };

                const selectedWidgetThreeFilter = this.$store.getters.schoolDashboardThreeHighestProgramMedianFirstYearWagesFilters(this.schoolId, parseInt(this.years[0])).find(d => d.value === this.widgetThreeSelectedFilter.value);
                this.widgetThreeSelectedFilter = {
                    label: selectedWidgetThreeFilter.value === 9 ? this.schoolDashboard.degreeLevelFilterDropdown.allDegreeLevels : selectedWidgetThreeFilter.label,
                    value: selectedWidgetThreeFilter.value
                };

                const selectedWidgetFourFilterOne = this.$store.getters.schoolDashboardCompareMedianWagesStatewideDegreeLevelFilters(this.schoolId, parseInt(this.years[0])).find(d => d.value === this.widgetFourSelectedFilterOne.value);
                this.widgetFourSelectedFilterOne = {
                    label: selectedWidgetFourFilterOne.value === 9 ? this.schoolDashboard.degreeLevelFilterDropdown.allDegreeLevels : selectedWidgetFourFilterOne.label,
                    value: selectedWidgetFourFilterOne.value
                };
                const selectedWidgetFourFilterTwo = this.$store.getters.schoolDashboardCompareMedianWagesStatewideMajorFilters(this.schoolId, parseInt(this.years[0])).find(m => m.value === this.widgetFourSelectedFilterTwo.value);
                this.widgetFourSelectedFilterTwo = {
                    label: selectedWidgetFourFilterTwo.value === 390 ? this.schoolDashboard.majorFilterDropdown.allMajors : selectedWidgetFourFilterTwo.label,
                    value: selectedWidgetFourFilterTwo.value
                };

                const selectedWidgetFiveFilter = this.$store.getters.schoolDashboardMajorAndDegreeLevelDataFilters(this.schoolId, parseInt(this.selectedSchoolYear)).find(d => d.value === this.widgetFiveSelectedDegreeLevelFilter.value);
                this.widgetFiveSelectedDegreeLevelFilter = {
                    label: selectedWidgetFiveFilter.value === 9 ? this.schoolDashboard.degreeLevelFilterDropdown.allDegreeLevels : selectedWidgetFiveFilter.label,
                    value: selectedWidgetFiveFilter.value
                };

                this.buildDashboardWidgets();
            },
            async id() {
                this.resetWidgetFilterDropdowns();
                this.schoolId = parseInt(this.selectedSchool.value);
                const localSchool = this.$store.getters.schoolDashboardDataBySchoolId(this.schoolId);

                // Check if the selected school exists already in state; if not,
                // get the dashboard data and build the widgets
                if (localSchool) {
                    this.setDashboardSelectedSchoolAndSelectedYear(this.schoolId, this.selectedSchoolYear);
                    this.buildDashboardWidgets();
                }
                else {
                    this.isLoading = true;

                    await this.getDashboardDataAsync(this.schoolId);
                    this.setDashboardSelectedSchoolAndSelectedYear(this.schoolId, this.selectedSchoolYear);
                    this.buildDashboardWidgets();

                    this.isLoading = false;
                }
            },
            windowWidth() {
                this.buildDegreeLevelWagesWidget();
                this.buildThreeHighestProgramMedianFirstYearWagesWidget();
                this.buildMajorAndDegreeLevelDataWidget();
            }
        },
        methods: {
            async getDashboardDataAsync(schoolId) {
                await this.$store.dispatch('getSchoolDashboardDataAsync', schoolId);
            },
            async handleUpdateReportAsync() {
                if (this.selectedSchool.label !== this.school) {
                    this.$store.commit('SET_HAS_COMPARABLE_SCHOOLS', false);
                    this.$store.commit('RESET_COMPARABLE_SCHOOLS');
                    this.$router.push({ path: `/school-dashboard/${parseInt(this.selectedSchool.value)}` });
                    return;
                }

                this.resetWidgetFilterDropdowns();
                this.schoolId = parseInt(this.selectedSchool.value);
                const localSchool = this.$store.getters.schoolDashboardDataBySchoolId(this.schoolId);

                // Check if the selected school exists already in state; if not,
                // get the dashboard data and build the widgets
                if (localSchool) {
                    this.setDashboardSelectedSchoolAndSelectedYear(this.schoolId, this.selectedSchoolYear);
                    this.buildDashboardWidgets();
                }
                else {
                    this.isLoading = true;

                    await this.getDashboardDataAsync(this.schoolId);
                    this.setDashboardSelectedSchoolAndSelectedYear(this.schoolId, this.selectedSchoolYear);
                    this.buildDashboardWidgets();

                    this.isLoading = false;
                }
            },
            handleDownload() {
                try {
                    const fileName = this.school;                                      
                    const chartElement = this.$refs.dashboardContent;
                    const widgetTables = [];
                    widgetTables.push(this.dashboardData.schoolProfile.table);
                    widgetTables.push(this.dashboardData.medianFirstYearWagesDegreeLevel.table);
                    widgetTables.push(this.dashboardData.threeProgramsHighestFirstYearWages.table);
                    widgetTables.push(this.dashboardData.compareStatewideWages.table);
                    widgetTables.push(this.dashboardData.majorAndDegreeLevelData.table);

                    this.generateDashboardPdf(chartElement, widgetTables, fileName);
                } catch (error) {
                    const errorMessage = this.$store.getters.getters.useSpanishLocale ? 'Lo sentimos, se produjo un error al descargar este panel en PDF. Int\u00E9ntalo de nuevo.' : 'Sorry, something went wrong downloading this dashboard to PDF. Please try again.';
                    alert(errorMessage);
                }
            },
            handleDatasetFilterChange(widgetId) {
                if (widgetId === 2) {
                    this.buildDegreeLevelWagesWidget();
                    return;
                }

                if (widgetId === 3) {
                    this.buildThreeHighestProgramMedianFirstYearWagesWidget();
                    return;
                }

                this.buildCompareMedianWagesStatewideWidget();          
            },
            handleFilterChange(event) {
                this.widgetFiveSelectedDegreeLevelFilter = { label: event.label, value: event.value };
                this.buildMajorAndDegreeLevelDataWidget();
            },
            handleInputChange(event) {
                const searchText = event.target.value;
                if (searchText) {
                    const filteredData = this.dashboardData.majorAndDegreeLevelData.table.tableData.filter(d => d.Major.toLowerCase().includes(searchText.toLowerCase()));
                    if (filteredData.length > 0) {
                        this.majorAndDegreeLevelFilteredData = {
                            table: {
                                columns: this.dashboardData.majorAndDegreeLevelData.table.columns,
                                tableData: filteredData
                            }
                        };
                    }
                    else {
                        this.majorAndDegreeLevelFilteredData = {
                            table: {
                                columns: null,
                                tableData: ['No results']
                            }
                        }
                    }

                    return;
                }

                this.majorAndDegreeLevelFilteredData = null;
            },
            buildDashboardWidgets() {
                this.buildSchoolProfileWidget();
                this.buildDegreeLevelWagesWidget();
                this.buildThreeHighestProgramMedianFirstYearWagesWidget();
                this.buildCompareMedianWagesStatewideWidget();
                this.buildMajorAndDegreeLevelDataWidget();
            },
            buildSchoolProfileWidget() {
                const schoolProfile = this.$store.getters.schoolDashboardSchoolProfile(this.schoolId, parseInt(this.selectedSchoolYear));

                // Build table dataset for selected year
                const columns = [
                    { field: 'ProfileHeader', title: '' },
                    { field: 'ProfileInfo', title: '' }
                ];
                const tableHeaders = [
                    this.schoolDashboard.widgetTableHeaders.typeOfInstitution,
                    this.schoolDashboard.widgetTableHeaders.location,
                    this.schoolDashboard.widgetTableHeaders.averageTuitionUndergrad,
                    this.schoolDashboard.widgetTableHeaders.averageTimeToDegreeUndergrad,
                    this.schoolDashboard.widgetTableHeaders.satPercentileScores,
                    this.schoolDashboard.widgetTableHeaders.totalEnrollment,
                    this.schoolDashboard.widgetTableHeaders.graduationRate,
                    this.schoolDashboard.widgetTableHeaders.percentGradsWithLoans,
                    this.schoolDashboard.widgetTableHeaders.avgLoanAmountAtGraduation,
                    this.schoolDashboard.widgetTableHeaders.medianFirstYearWages,
                    this.schoolDashboard.widgetTableHeaders.loanAsPercentFirstYearWages,
                    this.schoolDashboard.widgetTableHeaders.texasUnemploymentRate,
                ];

                const profileInfo = [];
                const institutionType = this.$store.getters.schoolDashboardSchoolProfileInstitutionType(this.schoolId, parseInt(this.selectedSchoolYear));
                const institutionTypeId = this.$store.getters.schoolDashboardSchoolProfileInstitutionTypeId(this.schoolId);
                let satScore = null;
                if (institutionTypeId === 2) {
                    satScore = schoolProfile.satScore === null
                        ? this.$store.getters.datasetMapperLocale.noData
                        : schoolProfile.satScore;
                }

                // Map dataset values
                profileInfo.push(institutionType); // institution type 
                profileInfo.push(schoolProfile.city ? schoolProfile.city + ', ' + schoolProfile.state : schoolProfile.state); // location (city, state)
                profileInfo.push(schoolProfile.tuitionFee ? this.mapDatasetValueToCurrency(schoolProfile.tuitionFee) + ' / ' + this.schoolDashboard.schoolProfile.year : this.mapToNoData()); // avg tuition (undergrad)
                profileInfo.push(schoolProfile.degreeTimeFinish ? this.mapDatasetValue(schoolProfile.degreeTimeFinish) + ' ' + this.schoolDashboard.schoolProfile.years : this.mapToNoData()); // avg time to degree (undergrad)
                profileInfo.push(satScore !== null ? satScore : this.schoolDashboard.widgetTableHeaders.notApplicable); // SAT score range
                profileInfo.push(this.mapDatasetValue(schoolProfile.enrollment)); // total enrollment
                profileInfo.push(schoolProfile.graduationRate ? this.mapDatasetValueToPercent((schoolProfile.graduationRate * 100).toFixed(1)) : this.mapToNoData()); // gradution rate
                profileInfo.push(this.mapDatasetValueToPercent(schoolProfile.loanPercent)); // percent graduates with loans
                profileInfo.push(this.mapDatasetValueToCurrency(schoolProfile.loanAmount)); // avg loan amount at graduation
                profileInfo.push(this.mapDatasetValueToCurrency(schoolProfile.wagesYear1)); // median first year wages
                profileInfo.push(schoolProfile.loanToFirstWageRatio ? this.mapDatasetValueToPercent(Math.trunc(schoolProfile.loanToFirstWageRatio * 100)) : this.mapToNoData()); // loan as percent of first year wages
                profileInfo.push(this.mapDatasetValueToPercent(schoolProfile.unemploymentRate)); // Texas unemployment rate
                
                const tableData = new Array(12).fill({}).map((_, i) => ({
                    ProfileHeader: tableHeaders[i],
                    ProfileInfo: profileInfo[i]
                }));

                const dataset = {
                    table: {
                        columns,
                        tableData
                    }
                };

                this.dashboardData.schoolProfile = dataset;
            },
            buildDegreeLevelWagesWidget() {
                const degreeLevelWages = this.$store.getters.schoolDashboardDegreeLevelWages(this.schoolId, parseInt(this.selectedSchoolYear), this.widgetTwoSelectedFilter.value);
                const columnTitles = this.$store.getters.schoolDashboardLocale.widgetTableHeaders;
                
                // Get filters
                const filters = this.$store.getters.schoolDashboardDegreeLevelWagesFilters(this.schoolId, parseInt(this.selectedSchoolYear));
                let majors = [];
                const majorsMap = new Map();

                // Get distinct program names
                for (const filter of filters) {
                    if (!majorsMap.has(filter.label)) {
                        majorsMap.set(filter.label, true);
                        majors.push(filter);
                    }
                }

                majors = majors.filter(m => m.value !== 390);
                majors.unshift({ label: this.schoolDashboard.majorFilterDropdown.allMajors, value: 390 });
                this.widgetTwoDropdownValues = majors;

                let dataset = null;

                if (parseInt(this.selectedSchoolYear) < parseInt(this.years[1])) {
                    const historicalDegreeLevelWages = this.$store.getters.schoolDashboardDegreeLevelWages(this.schoolId, parseInt(this.selectedSchoolYear), this.widgetTwoSelectedFilter.value);

                    let historicalWages = [];

                    for (var i = 0; i < historicalDegreeLevelWages.length; i++) {
                        const tempArray = new Array();
                        tempArray.push(historicalDegreeLevelWages[i].wagesYear1);
                        tempArray.push(historicalDegreeLevelWages[i].wagesYear3);
                        tempArray.push(historicalDegreeLevelWages[i].wagesYear5);
                        tempArray.push(historicalDegreeLevelWages[i].wagesYear8);
                        tempArray.push(historicalDegreeLevelWages[i].wagesYear10);
                        historicalWages.push(tempArray);
                    }

                    // Build historical chart dataset
                    const categories = {
                        categories: [
                            (parseInt(this.schoolYear) + 1).toString(),
                            (parseInt(this.schoolYear) + 3).toString(),
                            (parseInt(this.schoolYear) + 5).toString(),
                            (parseInt(this.schoolYear) + 8).toString(),
                            (parseInt(this.schoolYear) + 10).toString(),
                        ],
                        labels: {
                            font: '14px Source Sans Pro, sans-serif'
                        }
                    };
                    const axis = [
                        {
                            name: 'historical degree level wages',
                            labels: {
                                format: '{0:C0}',
                                font: '14px Source Sans Pro, sans-serif'
                            },
                            min: 0
                        }
                    ];
                    let chartData = new Array(degreeLevelWages.length).fill({}).map((_, i) => ({
                        type: 'line',
                        name: degreeLevelWages[i]?.degreeLevelNameSpanish || degreeLevelWages[i]?.degreeLevelName,
                        data: new Array(1).fill().map((_, j) => (
                            historicalWages[i]
                        )).flat(),
                        axis: axis[0].name
                    }));

                    let allNull = true;
                    for (var k = 0; k < chartData.length; k++) {
                        const dataRow = chartData[k].data;
                        for (var l = 0; l < dataRow.length; l++) {
                            if (dataRow[l] !== null) {
                                allNull = false;
                            }
                        }
                    }

                    if (allNull) {
                        chartData = [];
                    }

                    // Build historical table dataset                    
                    const columns = [
                        { field: 'DegreeLevel', title: columnTitles.degreeLevel },
                        { field: 'FirstYearWages', title: (parseInt(this.schoolYear) + 1) + ' ' + columnTitles.yearWages },
                        { field: 'ThirdYearWages', title: (parseInt(this.schoolYear) + 3) + ' ' + columnTitles.yearWages },
                        { field: 'FifthYearWages', title: (parseInt(this.schoolYear) + 5) + ' ' + columnTitles.yearWages },
                        { field: 'EigthYearWages', title: (parseInt(this.schoolYear) + 8) + ' ' + columnTitles.yearWages },
                        { field: 'TenthYearWages', title: (parseInt(this.schoolYear) + 10) + ' ' + columnTitles.yearWages }
                    ];
                    const tableData = new Array(degreeLevelWages.length).fill({}).map((_, i) => ({
                        DegreeLevel: degreeLevelWages[i]?.degreeLevelNameSpanish || degreeLevelWages[i]?.degreeLevelName,
                        FirstYearWages: this.mapDatasetValueToCurrency(historicalDegreeLevelWages[i]?.wagesYear1),
                        ThirdYearWages: this.mapDatasetValueToCurrency(historicalDegreeLevelWages[i]?.wagesYear3),
                        FifthYearWages: this.mapDatasetValueToCurrency(historicalDegreeLevelWages[i]?.wagesYear5),
                        EigthYearWages: this.mapDatasetValueToCurrency(historicalDegreeLevelWages[i]?.wagesYear8),
                        TenthYearWages: this.mapDatasetValueToCurrency(historicalDegreeLevelWages[i]?.wagesYear10)
                    }));

                    dataset = {
                        chart: {
                            categories,
                            axis,
                            chartData
                        },
                        table: {
                            columns,
                            tableData
                        }
                    };
                }
                else {
                    // Build chart dataset for recent year
                    const categories = {
                        categories: [
                           parseInt(this.schoolYear) + 1
                        ],
                        labels: {
                            font: '14px Source Sans Pro, sans-serif'
                        }
                    };
                    const axis = [
                        {
                            name: 'recent degree level wages',
                            labels: {
                                format: '{0:C0}',
                                font: '14px Source Sans Pro, sans-serif'
                            },
                            min: 0
                        }
                    ];
                    let chartData = new Array(degreeLevelWages.length).fill({}).map((_, i) => ({
                        name: degreeLevelWages[i]?.degreeLevelNameSpanish || degreeLevelWages[i]?.degreeLevelName,
                        data: new Array(1).fill(degreeLevelWages[i]?.wagesYear1),
                        axis: axis[0].name
                    }));

                    if (chartData[0].data[0] === null) {
                        chartData = [];
                    }

                    // Build table dataset for recent year
                    const columns = [
                        { field: 'DegreeLevel', title: columnTitles.degreeLevel },
                        { field: 'FirstYearWages', title: (parseInt(this.schoolYear) + 1) + ' ' + columnTitles.yearWages }
                    ];
                    const tableData = new Array(degreeLevelWages.length).fill({}).map((_, i) => ({
                        DegreeLevel: degreeLevelWages[i]?.degreeLevelNameSpanish || degreeLevelWages[i]?.degreeLevelName,
                        FirstYearWages: this.mapDatasetValueToCurrency(degreeLevelWages[i]?.wagesYear1)
                    }));

                    dataset = {
                        chart: {
                            categories,
                            axis,
                            chartData
                        },
                        table: {
                            columns,
                            tableData
                        }
                    };
                }

                this.dashboardData.medianFirstYearWagesDegreeLevel = dataset;
            },
            buildThreeHighestProgramMedianFirstYearWagesWidget() {
                const threeHighestProgramWages = this.$store.getters.schoolDashboardThreeHighestProgramMedianFirstYearWages(this.schoolId, parseInt(this.years[0]), this.widgetThreeSelectedFilter.value);
                const columnTitles = this.$store.getters.schoolDashboardLocale.widgetTableHeaders;

                // Get filters
                const filters = this.$store.getters.schoolDashboardThreeHighestProgramMedianFirstYearWagesFilters(this.schoolId, parseInt(this.years[0]));
                const degreeLevels = [];
                const degreeLevelsMap = new Map();

                // Get distinct degree level names
                for (const filter of filters) {
                    if (!degreeLevelsMap.has(filter.label)) {
                        degreeLevelsMap.set(filter.label, true);
                        degreeLevels.push(filter);
                    }
                }

                this.widgetThreeDropdownValues = degreeLevels;
                this.widgetThreeDropdownValues[0].label = this.schoolDashboard.degreeLevelFilterDropdown.allDegreeLevels;

                const schools = [];
                const programs = [];
                const schoolsMap = new Map();
                const programsMap = new Map();

                // Get distinct school and program names
                for (const program of threeHighestProgramWages) {
                    if (!schoolsMap.has(program.instituteLegalName)) {
                        schoolsMap.set(program.instituteLegalName, true);
                        schools.push(program.instituteLegalName);
                    }

                    if (program.programLongName && !programsMap.has(program.programLongName)) {
                        programsMap.set(program.programLongName, true);
                        programs.push(program.programLongName);
                    }
                    else if (program.programLongNameSpanish && !programsMap.has(program.programLongNameSpanish)) {
                        programsMap.set(program.programLongNameSpanish, true);
                        programs.push(program.programLongNameSpanish);
                    }
                }

                // Build chart dataset for recent year
                const categories = {
                    categories: new Array(programs.length).fill().map((_, i) => (
                        programs[i].split(' ').join('\n')
                    )),
                    labels: {
                        font: this.windowWidth <= 425 ? '12px Source Sans Pro, sans-serif' : '14px Source Sans Pro, sans-serif'
                    }                    
                };
                const axis = [
                    {
                        name: 'program wages',
                        labels: {
                            format: '{0:C0}',
                            font: '14px Source Sans Pro, sans-serif'
                        },
                        min: 0
                    }
                ];
                const chartData = new Array(schools.length).fill({}).map((_, i) => ({
                    name: schools[i],                    
                    data: new Array(programs.length).fill().map((_, j) => (
                        threeHighestProgramWages.filter(p => (p.programLongName === programs[j] || p.programLongNameSpanish === programs[j]) && p.instituteLegalName === schools[i] && p.degreeLevelId === this.widgetThreeSelectedFilter.value).map(w => w.wagesYear1)
                    )),
                    axis: axis[0].name,
                    tooltip: {
                        visible: true,
                        format: '{0:C0}'
                    }
                }));

                // Build table dataset for recent year
                const columns = [
                    { field: 'Program', title: columnTitles.majors },
                    { field: 'School', title: schools[0] },
                    { field: 'Statewide', title: schools[1] }
                ];
                if (schools.length <= 1) columns.splice(2, 1);
                const tableData = new Array(programs.length).fill({}).map((_, i) => ({
                    Program: programs[i],
                    School: this.mapDatasetValueToCurrency(threeHighestProgramWages.find(p => (p.programLongName === programs[i] || p.programLongNameSpanish === programs[i]) && p.instituteLegalName === schools[0])?.wagesYear1),
                    Statewide: this.mapDatasetValueToCurrency(threeHighestProgramWages.find(p => (p.programLongName === programs[i] || p.programLongNameSpanish === programs[i]) && p.instituteLegalName === schools[1])?.wagesYear1),
                }));

                const dataset = {
                    chart: {
                        categories,
                        axis,
                        chartData
                    },
                    table: {
                        columns,
                        tableData
                    }
                };

                this.dashboardData.threeProgramsHighestFirstYearWages = dataset;
            },
            buildCompareMedianWagesStatewideWidget() {
                const medianWagesStatewide = this.$store.getters.schoolDashboardCompareMedianWagesStatewide(this.schoolId, parseInt(this.years[0]), this.widgetFourSelectedFilterOne.value, this.widgetFourSelectedFilterTwo.value);
                const columnTitles = this.$store.getters.schoolDashboardLocale.widgetTableHeaders;

                // Get filters
                const degreeLevelFilters = this.$store.getters.schoolDashboardCompareMedianWagesStatewideDegreeLevelFilters(this.schoolId, parseInt(this.years[0]));
                const majorFilters = this.$store.getters.schoolDashboardCompareMedianWagesStatewideMajorFilters(this.schoolId, parseInt(this.years[0]));                
                const degreeLevels = [];
                let majors = [];
                const degreeLevelsMap = new Map();
                const majorsMap = new Map();

                // Get distinct degree level and major names
                for (const filter of degreeLevelFilters) {
                    if (!degreeLevelsMap.has(filter.label)) {
                        degreeLevelsMap.set(filter.label, true);
                        degreeLevels.push(filter);
                    }
                }
                for (const filter of majorFilters) {
                    if (!majorsMap.has(filter.label)) {
                        majorsMap.set(filter.label, true);
                        majors.push(filter);
                    }
                }

                this.widgetFourDropdownOneValues = degreeLevels;
                this.widgetFourDropdownOneValues[0].label = this.$store.getters.schoolDashboardLocale.degreeLevelFilterDropdown.allDegreeLevels;
                majors = majors.filter(m => m.value !== 390);
                majors.unshift({ label: this.schoolDashboard.majorFilterDropdown.allMajors, value: 390 });
                this.widgetFourDropdownTwoValues = majors;

                const schools = [];
                const schoolsMap = new Map();

                // Get distinct school and program names
                for (const program of medianWagesStatewide) {
                    if (!schoolsMap.has(program.instituteLegalName)) {
                        schoolsMap.set(program.instituteLegalName, true);
                        schools.push(program.instituteLegalName);
                    }
                }

                // Build chart dataset for recent year
                const categories = {
                    categories: new Array(medianWagesStatewide.length).fill().map((_, i) => (
                        medianWagesStatewide[i].instituteLegalName.split(/(\w+\s*\w+)\s/i).join('\n')
                    )),
                    labels: {
                        font: '14px Source Sans Pro, sans-serif'
                    }
                };
                const axis = [
                    {
                        name: 'median first year wages',
                        labels: {
                            format: '{0:C0}',
                            font: '14px Source Sans Pro, sans-serif'
                        },
                        min: 0
                    }
                ];
                let chartData = new Array(1).fill({}).map((_, i) => ({
                    name: (parseInt(this.years[0]) + 1) + ' ' + columnTitles.yearWages,
                    data: new Array(medianWagesStatewide.length).fill().map((_, j) => medianWagesStatewide[j]?.wagesYear1),
                    axis: axis[0].name
                }));

                let allNull = true;
                for (var k = 0; k < chartData.length; k++) {
                    const dataRow = chartData[k].data;
                    for (var l = 0; l < dataRow.length; l++) {
                        if (dataRow[l] !== null) {
                            allNull = false;
                        }
                    }
                }

                if (allNull) {
                    chartData = [];
                }

                // Build table dataset for recent year
                const columns = [
                    { field: 'Institutions', title: columnTitles.institutions },
                    { field: 'FirstYearWages', title: (parseInt(this.years[0]) + 1) + ' ' + columnTitles.yearWages }
                ];
                const tableData = new Array(medianWagesStatewide.length).fill({}).map((_, i) => ({
                    Institutions: medianWagesStatewide[i]?.instituteLegalName,
                    FirstYearWages: this.mapDatasetValueToCurrency(medianWagesStatewide[i]?.wagesYear1)
                })); 

                const dataset = {
                    chart: {
                        categories,
                        axis,
                        chartData
                    },
                    table: {
                        columns,
                        tableData
                    }
                };

                this.dashboardData.compareStatewideWages = dataset;
            },
            buildMajorAndDegreeLevelDataWidget() {
                const recentMajorAndDegreeLevelData = this.$store.getters.schoolDashboardMajorAndDegreeLevelData(parseInt(this.schoolId), parseInt(this.years[0]), this.widgetFiveSelectedDegreeLevelFilter.value);
                const columnTitles = this.$store.getters.schoolDashboardLocale.widgetTableHeaders;

                // Get filters
                const filters = this.$store.getters.schoolDashboardMajorAndDegreeLevelDataFilters(this.schoolId, parseInt(this.selectedSchoolYear));
                const degreeLevels = [];
                const degreeLevelsMap = new Map();

                // Get distinct degree level names
                for (const filter of filters) {
                    if (!degreeLevelsMap.has(filter.label)) {
                        degreeLevelsMap.set(filter.label, true);
                        degreeLevels.push(filter);
                    }
                }

                this.widgetFiveDropdownValues = degreeLevels;
                this.widgetFiveDropdownValues[0].label = this.$store.getters.schoolDashboardLocale.degreeLevelFilterDropdown.allDegreeLevels;

                let dataset = null;
                let columns = [];
                let tableData = [];

                if (parseInt(this.selectedSchoolYear) < parseInt(this.years[0])) {
                    const historicalData = this.$store.getters.schoolDashboardMajorAndDegreeLevelData(parseInt(this.schoolId), parseInt(this.selectedSchoolYear), this.widgetFiveSelectedDegreeLevelFilter.value);

                    // Build historical table dataset
                    columns = [
                        { field: 'Major', title: columnTitles.majors },
                        { field: 'NumberOfGrads', title: columnTitles.numberGrads },
                        { field: 'PercentGradsWithLoans', title: columnTitles.percentageGradsWithLoans },
                        { field: 'AverageLoanAtGraduation', title: columnTitles.averageLoanAmountAtGraduation },
                        { field: 'PercentFoundWithWages', title: columnTitles.percentFoundWithWages,   },
                        { field: 'MedianYearOneWages', title: (parseInt(this.schoolYear) + 1) + ' ' + columnTitles.medianWages },
                        { field: 'MedianYearThreeWages', title: (parseInt(this.schoolYear) + 3) + ' ' + columnTitles.medianWages },
                        { field: 'MedianYearFiveWages', title: (parseInt(this.schoolYear) + 5) + ' ' + columnTitles.medianWages },
                        { field: 'MedianYearEightWages', title: (parseInt(this.schoolYear) + 8) + ' ' + columnTitles.medianWages },
                        { field: 'MedianYearTenWages', title: (parseInt(this.schoolYear) + 10) + ' ' + columnTitles.medianWages },
                        { field: 'LoanAsPercentOfYearOneWages', title: columnTitles.loanAsPercentOfYearOneWages }
                    ];
                    tableData = new Array(historicalData.length).fill({}).map((_, i) => ({
                        Major: `<a style='text-decoration: underline; color: #4c6ce3;' href='/major-dashboard/${recentMajorAndDegreeLevelData[i]?.programId}'>${historicalData[i]?.programLongNameSpanish || historicalData[i]?.programLongName}</a>`,
                        NumberOfGrads: this.mapDatasetValue(historicalData[i]?.numberOfGraduates),
                        PercentGradsWithLoans: this.mapDatasetValueToPercent(historicalData[i]?.loanPercent),
                        AverageLoanAtGraduation: this.mapDatasetValueToCurrency(historicalData[i]?.loanAmount),
                        PercentFoundWithWages: this.mapDatasetValueToPercent(historicalData[i]?.wagesPercent),
                        MedianYearOneWages: this.mapDatasetValueToCurrency(historicalData[i]?.wagesYear1),
                        MedianYearThreeWages: this.mapDatasetValueToCurrency(historicalData[i]?.wagesYear3),
                        MedianYearFiveWages: this.mapDatasetValueToCurrency(historicalData[i]?.wagesYear5),
                        MedianYearEightWages: this.mapDatasetValueToCurrency(historicalData[i]?.wagesYear8),
                        MedianYearTenWages: this.mapDatasetValueToCurrency(historicalData[i]?.wagesYear10),
                        LoanAsPercentOfYearOneWages: this.mapDatasetValueToPercent(historicalData[i]?.loanToFirstWageRatio ? Math.trunc(historicalData[i].loanToFirstWageRatio * 100) : null)
                    }));

                    dataset = {
                        table: {
                            columns,
                            tableData
                        }
                    };
                }
                else {
                    // Build table dataset for recent year
                    columns = [
                        { field: 'Major', title: columnTitles.majors },
                        { field: 'NumberOfGrads', title: columnTitles.numberGrads },
                        { field: 'PercentGradsWithLoans', title: columnTitles.percentageGradsWithLoans },
                        { field: 'AverageLoanAtGraduation', title: columnTitles.averageLoanAmountAtGraduation },
                        { field: 'PercentFoundWithWages', title: columnTitles.percentFoundWithWages },
                        { field: 'MedianYearOneWages', title: (parseInt(this.schoolYear) + 1) + ' ' + columnTitles.medianWages },
                        { field: 'LoanAsPercentOfYearOneWages', title: columnTitles.loanAsPercentOfYearOneWages }
                    ];
                    tableData = new Array(recentMajorAndDegreeLevelData.length).fill({}).map((_, i) => ({
                        Major: `<a style='text-decoration: underline; color: #4c6ce3;' href='/major-dashboard/${recentMajorAndDegreeLevelData[i]?.programId}'>${recentMajorAndDegreeLevelData[i]?.programLongNameSpanish || recentMajorAndDegreeLevelData[i]?.programLongName}</a>`,
                        NumberOfGrads: this.mapDatasetValue(recentMajorAndDegreeLevelData[i]?.numberOfGraduates),
                        PercentGradsWithLoans: this.mapDatasetValueToPercent(recentMajorAndDegreeLevelData[i]?.loanPercent),
                        AverageLoanAtGraduation: this.mapDatasetValueToCurrency(recentMajorAndDegreeLevelData[i]?.loanAmount),
                        PercentFoundWithWages: this.mapDatasetValueToPercent(recentMajorAndDegreeLevelData[i]?.wagesPercent),
                        MedianYearOneWages: this.mapDatasetValueToCurrency(recentMajorAndDegreeLevelData[i]?.wagesYear1),
                        LoanAsPercentOfYearOneWages: this.mapDatasetValueToPercent(recentMajorAndDegreeLevelData[i]?.loanToFirstWageRatio ? Math.trunc(recentMajorAndDegreeLevelData[i].loanToFirstWageRatio * 100) : null)
                    }));

                    dataset = {
                        table: {
                            columns,
                            tableData
                        }
                    };
                }

                this.dashboardData.majorAndDegreeLevelData = dataset;
            },
            setDashboardSelectedSchoolAndSelectedYear(schoolId, year) {
                const school = this.$store.getters.schoolDashboardSchoolNameById(parseInt(schoolId));

                // School and school year dropdown selected values
                this.selectedSchool = {
                    label: school,
                    value: schoolId
                };
                this.selectedSchoolYear = year;

                // School and school year used across dashboard and widget headers
                this.school = school;
                this.schoolYear = year;
            },
            resetWidgetFilterDropdowns() {                
                this.widgetTwoSelectedFilter = {
                    label: this.$store.getters.schoolDashboardLocale.majorFilterDropdown.allMajors,
                    value: 390 // all majors
                };
                this.widgetThreeSelectedFilter = {
                    label: this.$store.getters.schoolDashboardLocale.degreeLevelFilterDropdown.allDegreeLevels,
                    value: 9 // all degree levels
                };
                this.widgetFourSelectedFilterOne = {
                    label: this.$store.getters.schoolDashboardLocale.degreeLevelFilterDropdown.allDegreeLevels,
                    value: 9 // all degree levels
                };
                this.widgetFourSelectedFilterTwo = {
                    label: this.$store.getters.schoolDashboardLocale.majorFilterDropdown.allMajors,
                    value: 390 // all majors
                };
                this.widgetFiveSelectedDegreeLevelFilter = {
                    label: this.$store.getters.schoolDashboardLocale.degreeLevelFilterDropdown.allDegreeLevels,
                    value: 9 // all degree levels
                };
            }
        }
    }
</script>

<style scoped>
    /* Banner */
    .banner-dropdown-container {
        display: flex;
        max-width: 420px;
    }
    .dashboard-dropdown-group {
        display: flex;
        flex-direction: column;
        padding-right: 12px;
    }
    .dashboard-dropdown-label {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 16px;
        color: rgba(51, 51, 51, 1);
    }
    .dashboard-dropdown {
        margin-top: 4px;
        width: 240px;
    }
    .school-years {
        width: 140px;
    }

    /* Dashboard - Widgets */
    .dashboard-widgets-container {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-column-gap: 30px;
        grid-row-gap: 46px;
        margin-top: 42px;
    }
    .dashboard-widgets-container > :nth-child(1) {
        grid-column: 1 / span 3;
    }
    .dashboard-widgets-container > :nth-child(2) {
        grid-column: 4 / span 3;
    }
    .dashboard-widgets-container > :nth-child(3) {
        grid-column: 1 / span 3;
    }
    .dashboard-widgets-container > :nth-child(4) {
        grid-column: 4 / span 3;
    }
    .dashboard-widgets-container > :nth-child(5) {
        grid-column: 1 / span 6;
    }

    /* Dashboard - Widget Filter Dropdown */    
    .widget-filter-dropdown-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 12px 0px;
    }
    .widget-filter-dropdown-label {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 16px;
        color: rgba(51, 51, 51, 1);
        margin-right: 10px;
    }
    .widget-filter-dropdown {
        width: 220px;
    }

    /* Media Queries */

    /* Tablets and Under */
    @media (max-width: 768px) {
        .dashboard-widgets-container * {
            grid-column: 1 / span 6 !important;
        }
    }

    @media (max-width: 490px) {
        .dashboard-dropdown {
            width: 200px;
        }
        .school-years {
            width: 90px;
        }
    }
</style>