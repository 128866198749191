<template>
    <SelectPageTemplate ref="selectPage"
                        :bannerHeaderText="selectSchoolPage.header"
                        :bannerDescriptionText="selectSchoolPage.description"
                        :buttonText="selectSchoolPage.viewDashboardButton"
                        :dashboardPage="dashboardPage"
                        :isButtonDisabled="isButtonDisabled"
                        :isLoading="isLoading"
                        :loadingText="loadingText">

        <div slot="select-page-content">
            <label class="select-category-label" v-html="selectSchoolPage.buttonGroupLabel" />
            <div class="select-category-container">
                <BaseButton :class="selectedSchoolCategory === 1 ? 'select-category-button active' : 'select-category-button'"
                            :aria-label="selectSchoolPage.buttons.fourYearUniversity.ariaLabel"
                            :text="selectSchoolPage.buttons.fourYearUniversity.text"
                            @execute="handleChangeDropdownValues(1, true)" />
                <BaseButton :class="selectedSchoolCategory === 2 ? 'select-category-button active' : 'select-category-button'"
                            :aria-label="selectSchoolPage.buttons.twoYearCollege.ariaLabel"
                            :text="selectSchoolPage.buttons.twoYearCollege.text"
                            @execute="handleChangeDropdownValues(2, true)" />
                <BaseButton :class="selectedSchoolCategory === 3 ? 'select-category-button active' : 'select-category-button'"
                            :aria-label="selectSchoolPage.buttons.careerSchool.ariaLabel"
                            :text="selectSchoolPage.buttons.careerSchool.text"
                            @execute="handleChangeDropdownValues(3, true)" />
                <BaseButton :class="selectedSchoolCategory === 4 ? 'select-category-button active' : 'select-category-button'"
                            :aria-label="selectSchoolPage.buttons.statewideSummary.ariaLabel"
                            :text="selectSchoolPage.buttons.statewideSummary.text"
                            @execute="handleChangeDropdownValues(4, true)" />
            </div>

            <label for="select-school-dropdown"
                   :class="isDropDownDisabled ? 'select-school-dropdown-label disabled' : 'select-school-dropdown-label enabled'"
                   v-html="dropdownLabel" />

            <BaseDropDown ref="selectSchoolDropdown"
                          id="select-school-dropdown"
                          class="select-school-dropdown"
                          :ariaLabel="dropdownLabel"
                          :options="schoolsDropdownValues"
                          :noOptionsText="dropdownNoOptions"
                          :disabled="isDropDownDisabled"
                          v-model="selectedSchool" 
                          @input="handleDropdownChange()" />
        </div>

    </SelectPageTemplate>
</template>

<script>
    import SelectPageTemplate from '@/components/PageTemplates/SelectPageTemplate.vue';
    import BaseButton from '@/components/BaseButton.vue';
    import BaseDropDown from '@/components/BaseDropDown.vue';

    export default {
        name: 'SelectSchoolPage',
        components: {
            SelectPageTemplate,
            BaseButton,
            BaseDropDown
        },
        computed: {
            selectSchoolPage() {
                return this.$store.getters.selectSchoolPageLocale;
            },
            dropdownNoOptions() {
                return this.$store.getters.dropdownLocale.noOptions;
            },
            dropdownLabel() {
                if (this.selectedSchoolCategory) {
                    if (this.selectedSchoolCategory === 1) {
                        return this.selectSchoolPage.dropdownLabels.fourYearUniversity;
                    }
                    if (this.selectedSchoolCategory === 2) {
                        return this.selectSchoolPage.dropdownLabels.twoYearCollege;
                    }
                    if (this.selectedSchoolCategory === 3) {
                        return this.selectSchoolPage.dropdownLabels.careerSchool;
                    }

                    return this.selectSchoolPage.dropdownLabels.statewideSummary;
                }

                return this.selectSchoolPage.dropdownLabels.default;
            },
            dashboardPage() {
                if (this.selectedSchoolCategory && this.selectedSchool) {
                    if (this.selectedSchoolCategory === 3 && this.selectedSchool.value) {
                        return `/career-school-dashboard/${this.selectedSchool.value}`;
                    }

                    return `/school-dashboard/${this.selectedSchool.value}`;
                }

                return '';
            },
            isDropDownDisabled() {
                return !this.selectedSchoolCategory
                    ? true
                    : false;
            },
            isButtonDisabled() {
                return !this.selectedSchool
                    ? true
                    : false;
            },
            loadingText() {
                return this.$store.getters.loadingIndicatorLocale.loadingSchools;
            }
        },
        data() {
            return {
                isLoading: false,
                selectedSchoolCategory: null,
                schoolsDropdownValues: [],
                selectedSchool: null,
                schools: []
            }
        },
        async created() {
            try {
                const schools = this.$store.getters.schools;

                if (schools.length > 0) {
                    this.schools = schools;
                }
                else {
                    this.isLoading = true;
                    await this.$store.dispatch('getSchoolsAsync');
                    this.schools = this.$store.getters.schools;
                    this.isLoading = false;
                } 
            } catch (error) {
                this.isLoading = false;
                this.$router.push('/error');
            }            
        },
        watch: {
            selectSchoolPage() {
                if (this.selectedSchool) {
                    this.selectedSchool = {
                        label: this.$store.getters.selectedSchoolLocale(this.selectedSchool.value),
                        value: this.selectedSchool.value
                    };
                }
                
                this.handleChangeDropdownValues(this.selectedSchoolCategory, false);
            }
        },
        methods: {
            handleChangeDropdownValues(selectedButton, clearSelectedSchool) {
                this.selectedSchoolCategory = selectedButton;

                if (clearSelectedSchool) {
                    this.selectedSchool = null;
                }

                if (selectedButton === 1) {
                    this.schoolsDropdownValues = this.$store.getters.schoolNamesFourYearLocale;
                }
                else if (selectedButton === 2) {
                    this.schoolsDropdownValues = this.$store.getters.schoolNamesTwoYearLocale;
                }
                else if (selectedButton === 3) {
                    this.schoolsDropdownValues = this.$store.getters.schoolNamesCareerLocale;
                }
                else if (selectedButton === 4) {
                    this.schoolsDropdownValues = this.$store.getters.schoolNamesStatewideSummaryLocale;
                }

                this.$nextTick(() => this.$refs.selectSchoolDropdown.$refs.dropdown.$refs.search.focus());
            },
            handleDropdownChange() {
                this.$nextTick(() => this.$refs.selectPage.$refs.viewDashboardButton.$el.focus());
            }
        }
    }
</script>

<style scoped>
    .select-category-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-gap: 10px;
        margin-top: 10px;
        margin-bottom: 25px;
    }
    .select-category-label,
    .select-school-dropdown-label {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 16px;
        color: rgba(51, 51, 51, 1);
    }
    .select-category-button {
        background-color: #FFFFFF;
        border-radius: 8px;
        border: 1px solid #79798A;
        font-family: 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif;
        font-size: 16px;
        color: #79798a;
        cursor: pointer;
        text-align: center;
        padding: 10px 10px;
    }
    .select-category-button:hover {
        color: #141417;
    }
    .select-category-button:focus {
        border: solid 2px #4c6ce3;
        margin: -2px;
        outline: #4c6ce3;
        box-shadow: 0 0 0 3pt #b1bbf3;
    }
    .active {
        border: solid 2px #4c6ce3;
        margin: -2px;
        color: #141417;
        outline: #4c6ce3;
    }
    .select-school-dropdown {
        margin-top: 8px;
        width: 100%
    }
</style>