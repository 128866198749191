<template>    
    <LocalizationProvider :language="useSpanish ? 'es-ES' : 'en-EN'">
        <IntlProvider :locale="useSpanish ? 'es' : 'en'">
            <Grid :id="tableId"
                  :class="isFilterable ? 'table detailed-table table-container' : 'table bold-table-column table-container'"
                  :data-items="dataItems"
                  :skip="skip"
                  :take="take"
                  :total="totalItems"
                  :columns="dataset.columns"
                  :pageable="pageable"
                  :scrollable="'none'"
                  @pagechange="handlePageChange($event)">
            </Grid>
        </IntlProvider>
    </LocalizationProvider>
</template>

<script>
    import { Grid } from '@progress/kendo-vue-grid';
    import { IntlProvider, LocalizationProvider, loadMessages } from '@progress/kendo-vue-intl';

    const esMessages = {
        grid: {
            noRecords: "No hay datos disponibles.",
            pagerInfo: "{0} - {1} de {2} \u00EDtems"
        }
    };
    loadMessages(esMessages, 'es-ES');

    export default {
        name: 'BaseTable',
        components: {
            Grid,
            IntlProvider,
            LocalizationProvider
        },
        props: {
            tableId: { type: String, required: true },
            hasHeaders: { type: Boolean, required: false, default: true },
            isFilterable: { type: Boolean, requiered: false, default: false },
            isPageable: { type: Boolean, required: false, default: false },
            dataset: { type: Object, required: true }
        },
        computed: {
            useSpanish() {
                return this.$store.getters.useSpanishLocale;
            },
            dataItems() {
                return this.dataset.tableData.slice(this.skip, this.take + this.skip);
            },
            totalItems() {
                return this.dataset.tableData ? this.dataset.tableData.length : 0;
            },
            pageable() {
                if (this.isPageable) {
                    let pageable = { buttonCount: 0, previousNext: false };
                    pageable.buttonCount = this.windowWidth > 520 ? 10 : 3;

                    return pageable;
                }

                return false;
            }
        },
        data() {
            return {
                skip: 0,
                take: this.isFilterable ? 10 : 15,
                windowWidth: window.innerWidth
            }
        },
        mounted() {
            this.$nextTick(() => {
                // Add resize event listener for dynamic table styling
                window.addEventListener('resize', () => this.windowWidth = window.innerWidth);

                // When the component has been fully rendered in the DOM, get the table html element                
                const table = document.getElementById(this.tableId);                

                if (this.isPageable) {
                    const pagination = table.children[1];
                    table.parentNode.insertBefore(pagination, table.previousElementSibling); // Move pagination outside of table container so it does not scroll with table
                }

                if (this.hasHeaders) {
                    table.firstChild.style.padding = '0px'; // Remove padding from table column headers

                    const headers = table.getElementsByTagName('thead')[0].children[0].cells;
                    for (var i = 0; i < headers.length; i++) {
                        headers[i].scope = 'col'; // Add table column scope
                    }                    
                }
                else {
                    table.getElementsByTagName('thead')[0].style.display = 'none'; // Remove table column headers
                }

                const rows = table.getElementsByTagName('tbody')[0].children;
                for (var j = 0; j < rows.length; j++) {
                    const firstCell = rows[j].firstChild;
                    const row = firstCell.parentNode;
                    const rowHeader = document.createElement('th');

                    rowHeader.innerHTML = firstCell.innerHTML;
                    row.replaceChild(rowHeader, firstCell); // Make first cell in each row a <th> element
                    row.cells[0].scope = 'row'; // Add table row scope          
                }

                this.setTableInnerHtml();
            });

            if (this.windowWidth <= 520 && this.pageable) {
                this.take = 5;
            }
        },
        beforeDestroy() {
            window.removeEventListener('resize', () => this.windowWidth = window.innerWidth);
        },
        watch: {
            windowWidth() {
                if (this.isPageable) {
                    this.windowWidth <= 520
                        ? this.take = 5
                        : this.take = 10;
                }
            },
            dataItems() {
                this.$nextTick(() => {
                    const table = document.getElementById(this.tableId);

                    const rows = table.getElementsByTagName('tbody')[0].children;
                    for (var i = 0; i < rows.length; i++) {
                        const firstCell = rows[i].firstChild;
                        const row = firstCell.parentNode;
                        const rowHeader = document.createElement('th');

                        rowHeader.innerHTML = firstCell.innerHTML;
                        row.replaceChild(rowHeader, firstCell); // Make first cell in each row a <th> element
                        row.cells[0].scope = 'row'; // Add table row scope          
                    }

                    this.setTableInnerHtml();
                });
            },
            useSpanish() {
                this.$nextTick(() => this.setTableInnerHtml());
            },
        },
        methods: {
            setTableInnerHtml() {
                const table = document.getElementById(this.tableId);
                const rows = table.getElementsByTagName('tbody')[0].children;

                if (this.hasHeaders) {
                    // Get headers from the HTML table element
                    const headers = table.getElementsByTagName('thead')[0].children[0].cells;

                    for (var i = 0; i < headers.length; i++) {
                        // Loop through each header row and explicitly assign the inner HTML of each cell
                        const headerRow = Object.values(this.dataset.columns[i]);
                        headers[i].innerHTML = headerRow[1]; // title property
                    }
                }

                // Loop through each data row and get its data
                for (var j = 0; j < rows.length; j++) {
                    const dataCells = Object.values(this.dataItems[j]); // data items computed prop

                    // Loop through each cell in data row and explicitly assign its inner HTML
                    for (var k = 0; k < dataCells.length; k++) {
                        rows[j].cells[k].innerHTML = dataCells[k];
                    }
                }
            },
            handlePageChange(event) {
                this.skip = event.page.skip;
                this.take = event.page.take;                
            }
        }
    }
</script>

<style>
    table {
        font-size: 14px;
        color: #333333;
        line-height: normal;
    }
    table thead .k-header {
        background-color: #f7f7f8;
        color: #333333;
        font-family: 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif;
        font-weight: 700;
        font-size: 14px;
        line-height: normal;
        text-align: left;
        box-sizing: border-box;
        vertical-align: middle;
        white-space: normal;
        word-wrap: break-word;
    }
    table tbody {
        font-family: 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif;
    }
    table tbody tr.k-master-row.k-alt,
    table tbody tr.k-master-row:hover {
        background-color: #FFFFFF;
    }
    .table-container {
        overflow: auto;
    }

    /* Regular Table */

    /* Content */
    .table .k-grid th,
    .table th:first-child {
        width: 50%;
        text-align: left;
    }
    .table .k-grid th,
    .table th:first-child {
        white-space: normal;
    }
    .table tbody th,
    .table tbody td {
        border-bottom-width: thin;
        border-bottom-color: rgba(33, 37, 41, 0.125);
    }
    .bold-table-column th:first-child {
        font-weight: 700;
    }

    /* Detailed Table */

    /* Content */
    .detailed-table .k-header:first-child,
    .detailed-table th:first-child {
        width: 30%;
        text-align: left;
    }
    .detailed-table tbody td {
        text-align: right;
    }
    /* Pagination */
    div .k-pager-wrap.k-grid-pager.k-widget {
        background-color: #f7f7f8;
        color: #333333;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 16px;
        text-align: left;
        line-height: normal;
        border-radius: 0px 0px 8px 8px;
        padding: 12px;
    }
    div .k-pager-numbers .k-link.k-state-selected {
        border-color: #4C6CE3;
        background-color: #4C6CE3;
    }
    div .k-pager-numbers .k-link,
    div .k-pager-nav.k-link, .k-pager-refresh {
        color: #4C6CE3;
    }

    /* Media Queries */
    @media (max-width: 768px) {
        .detailed-table .k-header:first-child,
        .detailed-table th:first-child {
            min-width: 150px;
        }
    }
    @media (max-width: 425px) {
        .detailed-table .k-header:first-child,
        .detailed-table th:first-child {
            min-width: 120px;
        }
    }
    @media (max-width: 375px) {
        .table .k-header:first-child,
        .table th:first-child {
            width: 170px;
        }
    }
</style>