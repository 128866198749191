import store from '../store/index';

const numberFormatter = new Intl.NumberFormat('en-US');
const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
});

export default {
    methods: {
        mapDatasetValue(value) {
            return value !== null
                ? numberFormatter.format(value)
                : this.mapToNoData();         
        },
        mapDatasetValueToCurrency(value) {
            return value !== null
                ? currencyFormatter.format(value)
                : this.mapToNoData();
        },
        mapDatasetValueToPercent(value) {
            return value !== null
                ? value += '%'
                : this.mapToNoData();
        },
        mapToNoData() {
            return store.getters.datasetMapperLocale.noData;
        }
    }
}