<template>
    <div>
        <span style="position: absolute; left: -10000px;">{{dropdownScreenReaderText}}</span>
        <DropDown ref="dropdown"
                  :class="disabled ? 'disabled-dropdown' : 'dropdown'"
                  :options="options"
                  :clearable="clearable"
                  :disabled="disabled"
                  v-model="dropdownValue">

            <slot name="no-options"></slot>
            <div slot="no-options" v-html="noOptionsText" />

            <slot name="list-header"></slot>
            <li v-if="optionsHeader"
                slot="list-header"
                style="font-family: 'Source Sans Pro', sans-serif;
                       padding: 12px;
                       font-size: 14px;
                       color: rgba(51, 51, 51, 1);
                       text-align: left;
                       line-height: normal;"
                v-html="optionsHeader" />
        </DropDown>
    </div>
</template>

<script>
    import DropDown from 'vue-select';
    import 'vue-select/dist/vue-select.css';

    export default {
        name: 'BaseDropDown',
        components: {
            DropDown
        },
        props: {
            ariaLabel: { type: String, required: true },
            options: { type: Array, default() { return []; }, required: true },            
            noOptionsText: { type: String, required: false },
            optionsHeader: { type: String, required: false, default: null },
            clearable: { type: Boolean, required: false, default: true },
            disabled: { type: Boolean, default: false, required: false },
            value: { default: null }
        },
        computed: {
            dropdownScreenReaderText() {
                return this.$store.getters.useSpanishLocale ? 'etiqueta de cuadro combinado' : 'combo box label';                
            },
            dropdownValue: {
                get() {
                    return this.value;
                },
                set(newValue) {
                    this.$emit('input', newValue);
                },
            }
        },
        mounted() {
            this.$nextTick(() => {
                const dropdown = this.$refs.dropdown.$refs;

                // toggle div
                dropdown.toggle.removeAttribute('role');
                dropdown.toggle.removeAttribute('aria-label');
                dropdown.toggle.removeAttribute('aria-labelledby');

                // text input
                dropdown.search.setAttribute('role', 'combobox');
                dropdown.search.removeAttribute('aria-labelledby');
                dropdown.search.ariaLabel = this.ariaLabel.substring(0, this.ariaLabel.length - 1); // remove ':' from the end of the aria label
            });
        },
        watch: {
            ariaLabel() {
                const dropdown = this.$refs.dropdown.$refs;
                dropdown.toggle.ariaLabel = this.ariaLabel;
            }
        }
    }
</script>

<style>
    .dropdown .vs__dropdown-toggle {
        background: #FFFFFF;
        max-width: 380px;
        height: 40px;
        border: 1px solid #79798a;
        border-radius: 8px;
        cursor: pointer;
    }
    .disabled-dropdown .vs__dropdown-toggle {
        background: #f8f8f8;
        max-width: 380px;
        height: 40px;
        border: 1px solid #79798a;
        border-radius: 8px;
        cursor: not-allowed;
    }
    .dropdown .vs__dropdown-toggle:hover {
        box-shadow: 0 0 0 2px #4C6CE3;
    }
    .disabled-dropdown .vs__dropdown-toggle:hover {
        box-shadow: none;
    }
    .dropdown .vs__search {
        font-family: Source Sans Pro SemiBold, Source Sans Pro, sans-serif;
        font-size: 14px;
        color: #141417;
        cursor: pointer;
    }
    .disabled-dropdown .vs__search {
        cursor: not-allowed;
    }
    .dropdown .vs__dropdown-option {
        font-family: Source Sans Pro SemiBold, Source Sans Pro, sans-serif;
        font-size: 14px;
        color: #141417;
        padding: 10px 12px;
        border-bottom: 1px solid #79798a;
        white-space: normal;
    }
    .dropdown .vs__dropdown-option:last-child {
        border-bottom: none;
    }
    .dropdown .vs__dropdown-option--highlight {
        background: #FFFFFF;
        color: #141417;
        text-decoration: underline;
    }
    .dropdown .vs__selected-options {
        white-space: nowrap;
        overflow: hidden;
    }
    .dropdown .vs__selected {
        font-family: Source Sans Pro SemiBold, Source Sans Pro, sans-serif;
        font-size: 16px;
        color: #141417;
        display: block;
        max-width: 320px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .dropdown .vs__clear {
        margin-right: 12px;
    }
    .dropdown .vs__dropdown-menu {
        font-family: Source Sans Pro SemiBold, Source Sans Pro, sans-serif;
        font-size: 14px;
        border: 1px solid #79798a;
        max-height: 220px;
    }    
</style>
